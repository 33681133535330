import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import { getConfirmTransferReceivedList,ReceviedTransferAsset } from '../../../services/transfer.service';
import ReceviedConfirmBox from '../../../atoms/ReceviedConfirmBox';
import  * as CryptoJS  from "crypto-js";

function getFormatedDate(dateString) {
  if(dateString!=null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function TransferReceivedList() {
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [tinNo, setTinNo] = useState('');
  const [transferId, setTransferId] = useState('');
  const [ErrTinNo, setErrTinNo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
  };
  
  const handleConfirmOpen = async (tin_no) => {
    setTinNo(tin_no);
    setIsConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };
  const handleConfirmReceived = () => {
    // Use the S3 getObject method to get the image and initiate the download
      //console.log('flagid'+flagId);
    if (window.confirm('Are you want to proceed to confirm items?')){
      //setTinNo(tin_no);
      //getTinNoInfo(tin_no);
      //handleConfirm();
    }else{
      return false;
    }
  };
  const handleConfirm = (dateOfReceipt,confirmedByPICName,receiptRemarks,receiptStatus,selectionModel) => {
    try {
      
      if (window.confirm('Are you want to proceed to confirm items?')){
      const obj = {
                  'transfer_ids':selectionModel,
                  'date_of_receipt':getFormatedDate(dateOfReceipt),
                  'confirmed_by_pic_name':confirmedByPICName,
                  'receipt_remarks':receiptRemarks,
                  'receipt_type':receiptStatus,
                  'created_by':sessionStorage.getItem('LoggedInUserId'),
                }
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data': obj,
          'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData = {'token': EncryptData}
      
          //setLoading('..'); // Set loading before sending API request
          ReceviedTransferAsset(oData).then(function (res) {
          //setLoading(false); // Stop loading
          setSuccess(res.data.message); //alert('a');
          const updatedData = items.filter((item) => !selectionModel.includes(item.id) );
          setItems(updatedData);
        });
      }else{
        return false;
      }
    } catch (err) {
        setSuccess(false);
        setLoading(false); // Stop loading
        if (!err?.data1) {
            console.log(err);
            //setErrMsg('No Server Response');
            //setSuccess("Successfully saved Single Asset");
        } else if (err.data1?.status === 409) {
            //setErrMsg('Record already exist');
        } else {
            setErrMsg('Insert/Update Failed');
        }
        //elementRef.current.focus();
    }
  };

  const columns = [
    {"field":"sl_no","hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tin_no","hide":false,"editable":false,"headerName":"TIN No","width":230},
    {"field":"tag","hide":false,"editable":false,"headerName":"TAG","width":230},
    {"field":"receipt_type","editable":false,"hide":false ,"headerName":"Receipt Type","width":130},
    {"field":"confirmed_by_pic_name","editable":false,"hide":false ,"headerName":"Received by (Name)","width":130},
    
    {"field":"receipt_remarks","editable":false,"hide":false ,"headerName":"Remarks","width":130},
    {"field":"date_of_receipt","editable":false,"hide":false ,"headerName":"Date of Receipt","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.date_of_receipt)}</p>
      ) },
    {"field":"created_at","editable":false,"hide":false ,"headerName":"Created Date","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
      ) },
  ];
  
function CustomToolbar() {
  return (
    <GridToolbarContainer>
        <GridToolbarExport/>
    </GridToolbarContainer>
  );
}
const [items, setItems] = useState([]);
  useEffect(()=>{
    let isMounted = true;
    const fetchData = async () => {
      try {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':{'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        const res = await getConfirmTransferReceivedList(oData);
        /*getBranchTransferList(oData).then(res=>{
          if(res?.data?.data?.length) setItems(res.data.data);
        });*/
        if (isMounted && res?.data?.data?.length) {
          setItems(res.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  
    // Cleanup function to handle unmounting
    return () => {
      isMounted = false;
    };
  },[])
  
  // readAssets();
  return (
    <div className='container'><div className='col'><h5> Transfer Received List </h5></div>
      {success ? (<div className="alert alert-success" role="alert">
        <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
          <div className="alert alert-danger" role="alert">
            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
      )}
      
      <div className='filter-container1 col-12'>
      <div className='col-8'></div><div className='col-2'>
        {/* <button className='btn btn-primary btn-md text-right' onClick={handleConfirmation}>Confirm</button> &nbsp; */}
        
        </div>
      </div>
      
      <DataGrid
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={50}
        //height={500}
        autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      <ReceviedConfirmBox
        isTinNo = {tinNo}
        isOpen={isConfirmOpen}
        onClose={handleConfirmClose}
        onConfirm={handleConfirm}
      />
    </div>
  )
}

export default TransferReceivedList
;