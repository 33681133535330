import React, { useState, useRef, useEffect,useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadAssets,getCostCenterByCompany } from '../../../services/asset.service';
import { getDepartmentList } from '../../../services/user.service';
import { getInvoiceCurrencyList,getTypeOfAdditionList,getYearOfAdditionList,getUnitOfMeasureList } from '../../../services/master.service';
import './AssetInfo.css';
import  * as CryptoJS  from "crypto-js";
const parent_templatePath = "/exceltemplates/Parent_Asset_Addition_Template.xlsx";
const child_templatePath = "/exceltemplates/Child_Asset_Addition_Template.xlsx";

const year_of_addition_columns = [
  {"field":"year_of_addition","editable":false,"hide":false ,"headerName":"Year Of Addition Name","width":350},
  ];
const type_of_addition_columns = [
  {"field":"additions_type_name","editable":false,"hide":false ,"headerName":"Addition Type Name","width":350},
  ];
const invoice_currency_columns = [
  {"field":"currency_type_name","editable":false,"hide":false ,"headerName":"Invoice Currency","width":350},
  ];
const control_department_columns = [
  {"field":"department_name","editable":false,"hide":false ,"headerName":"Control Department Name","width":350},
  ];
const Cost_Center_Columns = [
  {"field":"cost_center_code","editable":false,"hide":false ,"headerName":"Cost Center Code","width":350},
  ];
const Unit_of_Measurement_Columns = [
  {"field":"tag_unit_of_measure_name","editable":false,"hide":false ,"headerName":"Unit_of_Measurement","width":350},
  ];
export default function AssetInfo() {
  const errRef = useRef();
  const [validName, setValidName] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [year_of_addition_items, setYear_of_Addition_Items] = useState([]);
  const [type_of_addition_items, setType_of_Addition_Items] = useState([]);
  const [invoice_currency_items, setInvoice_Currency_Items] = useState([]);
  const [control_department_items, setControl_Department_Items] = useState([]);
  const [Cost_Center_Items, setCost_Center_Items] = useState([]);
  const [Unit_of_Measurement, setUnit_of_Measurement] = useState([]);
  //const [uploadassetfile, setUploadAssetFile] = useState([]);
  var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }
  
  useEffect(()=>{
    //encrypted data
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        //'param_data':obj,
        'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token':EncryptData}
    getYearOfAdditionList(oData).then(res=>{
      if(res?.data?.data?.length) setYear_of_Addition_Items(res.data.data);
    });
    getTypeOfAdditionList(oData).then(res=>{
      if(res?.data?.data?.length) setType_of_Addition_Items(res.data.data);
    });
    getInvoiceCurrencyList(oData).then(res=>{
      if(res?.data?.data?.length) setInvoice_Currency_Items(res.data.data);
    });
    /*getRoleList(oData).then(res=>{
      if(res?.data?.data?.length) setControl_Department_Items(res.data.data);
    });*/
    getDepartmentList(oData).then(res=>{
      if(res?.data?.data?.length) setControl_Department_Items(res.data.data);
    });
    getCostCenterByCompany(oData).then(res=>{
      if(res?.data?.data?.length) setCost_Center_Items(res.data.data);
    });
    getUnitOfMeasureList(oData).then(res=>{
      if(res?.data?.data?.length) setUnit_of_Measurement(res.data.data);
    });
  },[])
  
  const onChangeFile = async (event) => {
    event.preventDefault(); 
    //console.log(event.target.files[0]);
    if (event.target.files[0]?.name.split('.').pop() == 'undefined' || event.target.files[0]?.name.split('.').pop() !== 'xlsx') {
      //alert('Please upload valid format. excel file only allowed.'); 
      setErrMsg("Please upload valid format. excel file only allowed."); 
      return false;
    }else{ //alert('validName');
      try {
        //submit form
        setLoading('..'); // Set loading before sending API request
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('company_id', sessionStorage.getItem('LoggedInCompanyId'));
        formData.append('division_id', sessionStorage.getItem('LoggedInDivisionId'));
        formData.append('created_by', sessionStorage.getItem('LoggedInUserId'));
        formData.append('division_code', sessionStorage.getItem('LoggedInDivisionCode'));
        formData.append('child_asset', 'no');

        /* let obj ={ //'file': event.target.files[0],
                    'company_id': sessionStorage.getItem('LoggedInCompanyId'),
                    'division_id': sessionStorage.getItem('LoggedInDivisionId'),
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                    'division_code': sessionStorage.getItem('LoggedInDivisionCode') ,
                    'child_asset': 'no' 
                  }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':{obj},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        //formData.append('token', EncryptData);
        //let oData = {'token':EncryptData,'file': formData } */
        //console.log(event.target.files[0]);
        
        uploadAssets(formData).then(res => {
          if((typeof(res.data.errors)!== "undefined") && res.data.errors.errno>0){ 
            setErrMsg(res.data.message); setLoading(false);
            setSuccess(false);
          }else{
          setSuccess(res.data.message);
          setLoading(false);  // Stop loading
          }
        })
      } catch (error) {
      setLoading(false); // Stop loading in case of error
      console.error(error);
      setErrMsg(error.data.message);
      }
    }
  };
  //upload excel for child assets
  const onChangeFile1 = async (event) => {
    event.preventDefault(); 
    console.log(event.target.files[0]);
    if (event.target.files[0]?.name.split('.').pop() == 'undefined' || event.target.files[0]?.name.split('.').pop() !== 'xlsx') {
      //alert('Please upload valid format. excel file only allowed.'); 
      setErrMsg("Please upload valid format. excel file only allowed."); 
      return false;
    }else{ //alert('validName');
    //submit form
    const data = new FormData() 
    data.append('file', event.target.files[0]);
    /*uploadAssets(data).then(res => {
      
      setSuccess(res.data.message);
    })*/
    try {
      setLoading1('..'); // Set loading before sending API request
      data.append('company_id', sessionStorage.getItem('LoggedInCompanyId'));
      data.append('division_id', sessionStorage.getItem('LoggedInDivisionId'));
      data.append('created_by', sessionStorage.getItem('LoggedInUserId'));
      data.append('division_code', sessionStorage.getItem('LoggedInDivisionCode'));
      data.append('child_asset', 'yes');
      /*let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':data,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData1 = {'token':EncryptData}*/
      uploadAssets(data).then(res => {
        if((typeof(res.data.errors)!== "undefined") && res.data.errors.errno>0){ 
          setErrMsg(res.data.message);setLoading1(false);
          setSuccess(false);
        }else{
        setSuccess(res.data.message);
        setLoading1(false);  // Stop loading
        }
      });
      
      } catch (error) {
      setLoading1(false); // Stop loading in case of error
      console.error(error);
      setErrMsg(error.data.message);
      }
    }//end else
  };
  // readAssets();
  return (
    <div className='container'>
      <div className='row single__assets'>
                <div className="col-9">
                    <h5>Bulk Addition</h5>
                </div>
                <div className="col-3"><a href={'/asset-list'} className="btn btn-link"><i className='bi bi-list'></i> Asset List</a></div>
                {success ? (
				          <div className="alert alert-success" role="alert"><i className="bi bi-check"></i>{success}</div>
			            ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-times-circle"></i>{errMsg}</div>):('')
                ) }
      </div>
      <div className="filter-container">      
      <div className="row form-fields">
        <div className='col-8'>
        <div className="row form-fields">
          <div className="label col-4 mt-2"> 
              <label>Select Parent Asset Addition Excel <span className="star error"> *</span></label>
              
          </div>
            <div className="float-start col-3">
                <Button variant='contained' component='label' startIcon={<CloudUploadIcon />}>
                    Upload
                    <input hidden type='file' onChange={onChangeFile} />
                </Button>
                {loading ? (<div className="spinner-border text-primary" role="status">
                              <span className="sr-only">{loading}</span>
                            </div>) : ('') }
            </div>
         </div>             
        </div>
        
        <div className='col-4 form-fields'>
        <a href={parent_templatePath} className="text-decoration-none btn btn-secondary btn-sm">
          Download Parent Asset Addition Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
        </svg></a>
        </div>
      </div>
      </div>
      <div className="filter-container">
      <div className="row form-fields">
        <div className='col-8'>
        <div className="row form-fields">
          <div className="label col-4 mt-2"> 
              <label>Select Child Asset Addition Excel <span className="star error"> *</span></label>
              
          </div>
            <div className="float-start col-3">
                  <Button variant='contained' component='label' startIcon={<CloudUploadIcon />}>
                      Upload
                      <input hidden type='file' onChange={onChangeFile1} />
                  </Button>
                  {loading1 ? (<div className="spinner-border text-primary" role="status">
                                <span className="sr-only">{loading1}</span>
                              </div>) : ('') }
              </div>
          </div>             
          </div>
          
          <div className='col-4'>
          <a href={child_templatePath} className="text-decoration-none btn btn-secondary btn-sm">
          Download Child Asset Addition Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
  </svg></a>
          </div>

        </div>
      </div>
      <div className="filter-container"> 
      <div className='col-12'>
        <div className='row'>
        <strong>Instruction for bulk upload:</strong>
        <p>1. For Dates, always use <b>"YYYY-MM-DD" </b>.</p>
        <p>2. Not to Leave Blank on any cell, please use <b>"NA"</b> in place of text and use <b>"0"</b> in place of "Numbers" as appropriate.</p>
        <p>3. For company name use <b>"{sessionStorage.getItem("LoggedInCompanyName")}"</b> and For branch name use <b>"{sessionStorage.getItem("LoggedInDivisionName")}"</b>. </p>
        <p>4. For TAG Branch use <b>"{sessionStorage.getItem('LoggedInDivisionShortName')}"</b>. </p>
        <p>5. Adding parent asset is prerequisites to add child asset. </p>
        <p>6. For dropdown values of FORM, please use below give values in respective places. </p>
        </div>
      </div>
        </div>
      <div className="row form-fields">
        <div className='col-4'>
          <div className='row'>
          <div className='col-md-12'><h5>For Year of Addition </h5></div>
          </div>
          <DataGrid
            rows={year_of_addition_items}
            getRowId={(row) => row.id}
            columns={year_of_addition_columns}
            pageSize={5}
            autoHeight 
            rowsPerPageOptions={[5]}
            //onCellEditStop={onCellEditStopFn}
          />
        </div>
        <div className='col-4'>
          <div className='row'>
          <div className='col-md-12'><h5>For Type of Addition </h5></div>
          </div>
          <DataGrid
            rows={type_of_addition_items}
            getRowId={(row) => row.id}
            columns={type_of_addition_columns}
            pageSize={5}
            autoHeight 
            rowsPerPageOptions={[5]}
            //onCellEditStop={onCellEditStopFn}
          />
        </div>
        <div className='col-4'>
          <div className='row'>
          <div className='col-md-12'><h5>For Invoice Currency </h5></div>
          </div>
          <DataGrid
            rows={invoice_currency_items}
            getRowId={(row) => row.id}
            columns={invoice_currency_columns}
            pageSize={5}
            autoHeight 
            rowsPerPageOptions={[5]}
            //onCellEditStop={onCellEditStopFn}
          />
        </div>
        <div className='col-4'>
          <div className='row'>
          <div className='col-md-12'><h5>For Control Department </h5></div>
          </div>
          <DataGrid
            rows={control_department_items}
            getRowId={(row) => row.id}
            columns={control_department_columns}
            pageSize={5}
            autoHeight 
            rowsPerPageOptions={[5]}
            //onCellEditStop={onCellEditStopFn}
          />
        </div>
        <div className='col-4'>
          <div className='row'>
          <div className='col-md-12'><h5>For Cost Center Code </h5></div>
          </div>
          <DataGrid
            rows={Cost_Center_Items}
            getRowId={(row) => row.id}
            columns={ Cost_Center_Columns}
            pageSize={5}
            autoHeight 
            rowsPerPageOptions={[5]}
            //onCellEditStop={onCellEditStopFn}
          />
        </div>
        <div className='col-4'>
          <div className='row'>
          <div className='col-md-12'><h5>For Unit of Measurement </h5></div>
          </div>
          <DataGrid
            rows={Unit_of_Measurement}
            getRowId={(row) => row.id}
            columns={ Unit_of_Measurement_Columns}
            pageSize={5}
            autoHeight 
            rowsPerPageOptions={[5]}
            //onCellEditStop={onCellEditStopFn}
          />
        </div>
      </div>
    </div>
  )
}

