import React,{useEffect,useState} from 'react';
import { getAllTransferData } from '../../services/asset.service';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import QRCode from 'qrcode';
import './QRGenrate.css';
import  * as CryptoJS  from "crypto-js";
const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tag","hideable":true,"hide":false ,"editable":true,"headerName":"Tag"},
    {"field":"division","hideable":true,"hide":false ,"editable":true,"headerName":"Division"},
    {"field":"premises","hideable":true,"hide":false ,"editable":true,"headerName":"Premises"},
    {"field":"floor","hideable":true,"hide":false ,"editable":true,"headerName":"Floor"},
    {"field":"site_id","hideable":true,"hide":false ,"editable":true,"headerName":"Site Id"},
    {"field":"user_type","hideable":true,"hide":false ,"editable":true,"headerName":"User Type"},
    {"field":"user_department","hideable":true,"hide":false ,"editable":true,"headerName":"User Department"},
    {"field":"employee_id","hideable":true,"hide":false ,"editable":true,"headerName":"Employee Id"},
    {"field":"employee_name","hideable":true,"hide":false ,"editable":true,"headerName":"Employee Name"},
    {"field":"financials_classification","hideable":true,"hide":false ,"editable":true,"headerName":"Financials Classification"}
];


function QRGenrate() {
    const [checkboxSelection, setCheckboxSelection] = useState(true);
    const [items,setItems] = useState({});
    //const [qrDOMitems,setQrDOMitems] = useState({});
    //const [url, setUrl] = useState('')
	//const [qr, setQr] = useState('')
    var selectedRowsData;
    useEffect(()=>{
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
           // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getAllTransferData(oData).then(res=>{
            if(res?.data?.data?.length) setItems(res.data.data);
        })
    },[]);


    const GenerateQRCode = () => {
      
    }

    const printQR = function () {
        
        var qrcodesDiv = document.createElement("div");
        qrcodesDiv.setAttribute("style","width:365px");
        qrcodesDiv.id = 'qrcode-container';
         selectedRowsData.map((elm)=>{
          console.log(elm);
          QRCode.toDataURL(elm.tag, {
            width: 800,
            margin: 2,
            color: {
              // dark: '#335383FF',
              // light: '#EEEEEEFF'
            }
          }, (err, url) => {
            if (err) return console.error(err)
      
            console.log(url)
            //setQr(url);
            var row1 = document.createElement("div");
             row1.setAttribute("class",'qrcodes-div');
             row1.setAttribute("style","display:inline-block;");
             var col1 = document.createElement("div");
             col1.setAttribute("class",'col');
             col1.setAttribute("style","width:80px;float:left");
             //col1.setAttribute("float","left");
             //col1.innerHTML = <img src={qr} />;
             var col3 = document.createElement("img");
             col3.setAttribute("src",url);
             col3.setAttribute("style","width:75px");
             
             col1.appendChild(col3);
             var col2 = document.createElement("div");
             col2.setAttribute("class",'col');
             col2.setAttribute("style","width:250px;float:left;text-align: left;");
             
             col2.innerHTML = '<label>Company : <span>ABC India Pvt Ltd</span></label><br/><label>Division : <span>Banglore</span></label><br/><label>Asset Name : <span>Laptop</span></label><br/><label>Tag : <span>'+elm.tag+'</span></label><br/>';
             row1.appendChild(col1);
            row1.appendChild(col2);
            qrcodesDiv.appendChild(row1);
            document.getElementById('print_qr').appendChild(qrcodesDiv);
          });

        });
        
        let printContents = document.getElementById('print_qr').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;


      //startLoad();
      var docprint=window.open();
      //popup.document.write('<html><head></head><body>'+printContents+"</body></html>");
      docprint.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"');
   docprint.document.write('"http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">');
   docprint.document.write('<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en">');
   docprint.document.write('<head><title>My Title</title>');
   docprint.document.write('<style type="text/css">@media screen, print {@page {size: 70mm 20mm; margin: 0mm;} html, body { margin: 0px;padding: 0px; } #qrcode-container { width: 384px;}.qrcodes-div { width: 350px; height: 19mm;margin: 1mm auto;page-break-after: always;} .col:first-child { display: inline-block; width: 70px; padding-top: 10px; padding-left: 1px; }.col:last-child { display: inline-block; width: 250px; padding-top: 9px; float: right; } }</style>');
   docprint.document.write('</head><body onLoad="self.print()"><center>');
   docprint.document.write(printContents);
   docprint.document.write('</center></body></html>');
   //docprint.document.write(printContents);
    docprint.document.close();
    docprint.focus();
    docprint.print();
    docprint.close();
      //endLoad();

      // window.print();
      //document.window
      // document.body.innerHTML = "<html><head></head><body>"+originalContents+"</body></html>"; 
        
    }
    const onRowsSelectionHandler = (ids) => {
        selectedRowsData = ids.map((id) => items.find((row) => (row.sl_no === id)));
        ///console.log(selectedRowsData);
    };
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Print QR COde</h5>
                </div>
            </div>
            <div className='row'>
                <button onClick={printQR} className='btn btn-info btn-sm'> Print </button>
                <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                    checkboxSelection={checkboxSelection} 
                    rows={items}
                    getRowId={(row) => row.sl_no}
                    columns={columns}
                    pageSize={5}
                    autoHeight 
                    rowsPerPageOptions={[5]}
                    onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                /></Box>
            </div>
            <div id='print_qr'>
                
            </div>
        </div>
    )
}

export default QRGenrate