import React,{useState, useEffect, useRef} from 'react';
import { useLocation } from "react-router-dom";
import FromFieldNumber from '../../../atoms/FromFieldNumber';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromFieldSelect from '../../../atoms/FromFieldSelect';
import FromSpFieldText from '../../../atoms/FromSpFieldText';
import "./SingleAsset";
import { saveCapitalize,getSTPI, getSingleCapitalize } from '../../../services/asset.service';
import {getClasificationsList,getCompanyProfileData} from '../../../services/user.service';
import  * as CryptoJS  from "crypto-js";

Date.prototype.dbFormatedDate = function () {
  let year = this.getFullYear();
  let month = this.getMonth() + 1;
  let day = this.getDate();
  if (month < 10) {
    month = '0' + month;
  }if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;
}
function getFormatedDate(dateString) {
  if(dateString!==null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;
const NUMBER_REGEX = /^[0-9]{1,15}$/;

function SACapForm() {
  const [tag, setTag] = useState('');
  const [parentTag, setParentTag] = useState('');
  const [assetInfo, setAssetInfo] = useState([]);

  const [ErrTAG, setErrTag] = useState(false);
  const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
  
  const [classOfAsset,setClassOfAsset] = useState('');
  const [ErrClassOfAsset, setErrClassOfAsset] = useState(false);

  const [puttousedate, setPutToUseDate] = useState('');
  const [ErrPutToUseDate, setErrPutToUseDate] = useState('');

  const [assetinusedate, setAssetInuseDate] = useState('');
  const [ErrAssetInuseDate, setErrAssetInuseDate] = useState('');

  const [ErrDepreciationType, setErrDepreciationType] = useState(false);
  const [depreciationtype, setTypeOfDepreciation] = useState('');
  const [depreciationrate, setDepreciationRate] = useState('');
  const [ErrDepreciationRate, setErrDepreciationRate] = useState(false);
  const [otherdepreciationrate, setOtherDepreciationRate] = useState('');
  const [ErrOtherDepreciationRate, setErrOtherDepreciationRate] = useState(false);
  const [assettype, setTypeOfAsset] = useState('UseParent');
  const [ErrTypeOfAsset, setErrTypeOfAsset] = useState(false);
  const [assetcode, setassetcode] = useState('');

  const [assetac, setassetac] = useState('');
  const [ErrAssetAc, setErrAssetAc] = useState(false);

  const [fnclassification, setfnclassification] = useState('');
  const [ErrFnClassification, setErrFnClassification] = useState(false);

  const [recordstartdate, setRecordStartDate] = useState('');
  const [ErrRecordStartDate, setErrRecordStartDate] = useState('');
  const [otherrecordstartdate, setOtherRecordStartDate] = useState('');
  const [ErrOtherRecordStartDate, setErrOtherRecordStartDate] = useState('');
  const [finalAsset,setFinalAsset] = useState('');
  const [ErrFinalAsset, setErrFinalAsset] = useState(false);

  const [isTaxCapitalized,setIsTaxCapitalized] = useState('');
  const [ErrIsTaxCap, setErrIsTaxCap] = useState(false);
  const [invoicecurrency, setInvoiceCurrency] = useState('');
  const [exchangerate, setexchangerate] = useState('');
    const [ErrExchangeRate, setErrExchangeRate] = useState(false);

  const [is100PercDepreciable,setIs100PercDepreciable] = useState('');
  const [ErrIs100PercDep, setErrIs100PercDep] = useState(false);
  const [is100PercOtherDepreciable,setIs100PercOtherDepreciable] = useState('');
  const [ErrIs100PercOthDep, setErrIs100PercOthDep] = useState(false);

  const [preCapitalizedAssetValue,setPreCapitalizedAssetValue] = useState('');
  const [preCapitalizedAssetValue_tmp,setPreCapitalizedAssetValue_tmp] = useState('');
  const [ErrPreCapAsVal, setErrPreCapAsVal] = useState(false);
  
  const [isAnyOtherAccgGaapApplicable,setIsAnyOtherAccgGaapApplicable] = useState();
  const [ErrIsAnyOtAcGaApp, setErrIsAnyOtAcGaApp] = useState(false);

  const [igaapLimitAmount,setIgaapLimitAmount] = useState('');
  const [ErrIgaapLimitAmount, setErrIgaapLimitAmount] = useState(false);
  
  const [othergaapLimitAmount,setOthergaapLimitAmount] = useState('');
  const [ErrOthergaapLimitAmount, setErrOthergaapLimitAmount] = useState(false);

  const [salvageValue,setSalvageValue] = useState('');
  const [ErrSalvageValue, setErrSalvageValue] = useState(false);

  const [iGaapCapitalizedValue,setIGaapCapitalizedValue] = useState('');
  const [ErrIGaCapValue, setErrIGaCapValue] = useState(false);
  const [totalcostwithouttax,setTotalCostWithoutTax] = useState('');
  const [totalcostwithouttax_tmp,setTotalCostWithoutTax_tmp] = useState('');
  //const [compRefNo,setCompRefNo] = useState('');
  //const [ErrCompRefNo, setErrCompRefNo] = useState(false);

  const [othergaapCapitalizedValue,setOthergaapCapitalizedValue] = useState('');
  const [ErrOthGaCaptValue, setErrOthGaCaptValue] = useState(false);
  const [typeofDepShow, setTypeofDepShow] = useState(false);
  const [assetenddate, setAssetEndDate] = useState();
  const [assetotherenddate, setAssetOtherEndDate] = useState();
  const [preDepType, setPreDepType] = useState('');
  //const [valuewithtax, setValueWithTax] = useState('');
  const [ capitalization_status ,setCapitalizationStatus]= useState('');
  //const errRef = useRef();
  const [valueofasset, setValueofAsset] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showOtherVal, setShowOtherVal] = useState('');
  const [allreadOnly, setAllReadOnly] = useState(false);
  /*const setCustomValue= function(test){
    alert(test);
  }*/
  const [classifications, setClasifications] = useState([]);
  //const [classification, setClasification] = useState([]);
  const [auditInfo, setAuditInfo] = useState([]);

  const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const Mode = query.get('mode'); 
    //const elementRef = useRef();
    useEffect(() => { //console.log('allreadOnly'+allreadOnly);
        if(Tag!='' && Tag!=null){
          const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTagId = decrypt_data.tag;
          getTagCapInfo(decryptedTagId);
            //alert(decryptedTagId);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data': {'company_id': sessionStorage.getItem('company_id')},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getClasificationsList(oData).then(res=>{
          if(res?.data?.data?.length) setClasifications(res.data.data);
        });
        /*getCompanyProfileData(oData).then(res=>{
          if(res?.data?.data?.length){ setShowOtherVal(res.data.data[0].other_gaap_applicable); } 
        });*/


    }, [allreadOnly]);
    const getTagCapInfo = function(decryptedTagId) {
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':{'tag':decryptedTagId},
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData1 = {'token':EncryptData}
      getSTPI(oData1).then(function(res){ 
        var {assetInfo,assetInfo} ={};
        if(res?.data?.data) { 
          var {assetInfo,assetInfo} = res.data.data;
          setOldAssetauditInfo(assetInfo);
          if(assetInfo.tag) { 
            //var useInDate = new Date(assetInfo.asset_in_use_date);
            setTypeofDepShow(true);
            setTag(assetInfo.tag);
            setAssetInfo(assetInfo);
            //Check for child element
            if(assetInfo.parent_tag){ 
              setParentTag(assetInfo.parent_tag);
              let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':{'tag':assetInfo.parent_tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
              }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
              let oData2 = {'token':EncryptData}
              getSTPI(oData2).then(function(res2){
                if(res2?.data?.data) {
                  var assetInfo2 = res2.data.data.assetInfo;
                  //console.log("Specify "+assetInfo2.asset_in_use_date);
                   //alert(assetInfo2.type_of_depreciation);
                   //setTypeOfAsset(assetInfo2.cap_type_of_asset);
                  if(assetInfo.cap_type_of_asset=="UseParent"){
                    setAssetInuseDate(assetInfo2.asset_in_use_date?(new Date(new Date(assetInfo2.asset_in_use_date))) :'');
                    setRecordStartDate(assetInfo2.record_start_date?(new Date(new Date(assetInfo2.record_start_date))) :'');
                    setOtherRecordStartDate(assetInfo2.other_record_start_date?(new Date(new Date(assetInfo2.other_record_start_date))) :'');
                    setPutToUseDate(assetInfo2.put_to_use_date?(new Date(new Date(assetInfo2.put_to_use_date))) :'');
                  }else{ 
                    setAssetInuseDate(assetInfo.asset_in_use_date?(new Date(new Date(assetInfo.asset_in_use_date))) :'');
                    setRecordStartDate(assetInfo.record_start_date?(new Date(new Date(assetInfo.record_start_date))) :'');
                    setOtherRecordStartDate(assetInfo.other_record_start_date?(new Date(new Date(assetInfo.other_record_start_date))) :'');
                    setPutToUseDate(assetInfo.put_to_use_date?(new Date(new Date(assetInfo.put_to_use_date))) :'');
                  }
                  var asset_end_date = new Date(assetInfo2.asset_in_use_date);
                  var assetend_date = new Date(asset_end_date.setFullYear(asset_end_date.getFullYear()+assetInfo2.depreciation_rate));
                  var assetenddate = new Date(assetend_date.setDate(assetend_date.getDate()-1));
                  //console.log(assetenddate);
                  setAssetEndDate(getFormatedDate(assetenddate));
                  setAssetOtherEndDate(getFormatedDate(assetotherenddate));
                  setDepreciationRate(assetInfo2.depreciation_rate);
                  setOtherDepreciationRate(assetInfo2.other_depreciation_rate);
                  setPreDepType(assetInfo2.type_of_depreciation);
                  setTypeOfDepreciation(assetInfo2.type_of_depreciation);
                  setClassOfAsset(assetInfo.class_of_asset?assetInfo2.class_of_asset:'');
                  setassetcode(assetInfo.accounting_code?assetInfo2.accounting_code:'');
                  setassetac(assetInfo.accounting_name?assetInfo2.accounting_name:'');
                  setfnclassification(assetInfo2.financials_classification);
                  //parent value
                  setPreCapitalizedAssetValue(assetInfo.value_of_asset?assetInfo.value_of_asset:assetInfo2.value_of_asset);
                  setPreCapitalizedAssetValue_tmp(assetInfo.value_of_asset?assetInfo.value_of_asset:assetInfo2.value_of_asset);
                  setTotalCostWithoutTax(assetInfo.total_cost_without_tax?assetInfo.total_cost_without_tax:assetInfo2.total_cost_without_tax);
                  setTotalCostWithoutTax_tmp(assetInfo.total_cost_without_tax?assetInfo.total_cost_without_tax:assetInfo2.total_cost_without_tax);  
                  if(assetInfo2.is_tax_capitalized=='No'){
                    setIGaapCapitalizedValue(assetInfo.total_cost_without_tax);
                  }else{
                    //setIGaapCapitalizedValue(assetInfo.igaap_capitalized_value);
                    setIGaapCapitalizedValue(assetInfo.value_of_asset);
                  }
                }
              });
            }else{
                    //console.log('asset_in_use_date='+(getFormatedDate(assetInfo.asset_in_use_date)));
                    //console.log('depreciation_end_date='+getFormatedDate(assetInfo.depreciation_end_date));
                    setAssetInuseDate(assetInfo.asset_in_use_date?(new Date(new Date(assetInfo.asset_in_use_date))) :'');
                    setRecordStartDate(assetInfo.record_start_date?(new Date(new Date(assetInfo.record_start_date))) :'');
                    setOtherRecordStartDate(assetInfo.other_record_start_date?(new Date(new Date(assetInfo.other_record_start_date))) :'');
                    setPutToUseDate(assetInfo.put_to_use_date?(new Date(new Date(assetInfo.put_to_use_date))) :'');
                    setAssetEndDate(assetInfo.depreciation_end_date?(getFormatedDate(new Date(assetInfo.depreciation_end_date))) :'');
                    setAssetOtherEndDate(assetInfo.depreciation_other_end_date?(getFormatedDate(new Date(assetInfo.depreciation_other_end_date))) :'');
                    setDepreciationRate(assetInfo.depreciation_rate);
                    setOtherDepreciationRate(assetInfo.other_depreciation_rate);
                    setPreDepType(assetInfo.type_of_depreciation);
                    setTypeOfDepreciation(assetInfo.type_of_depreciation);
                    setClassOfAsset(assetInfo.class_of_asset);
                    setassetcode(assetInfo.accounting_code);
                    setassetac(assetInfo.accounting_name);
                    setfnclassification(assetInfo.financials_classification);

                    setPreCapitalizedAssetValue(assetInfo.value_of_asset);
                    setPreCapitalizedAssetValue_tmp(assetInfo.value_of_asset);
                    setTotalCostWithoutTax(assetInfo.total_cost_without_tax); 
                    setTotalCostWithoutTax_tmp(assetInfo.total_cost_without_tax); 
                    if(assetInfo.is_tax_capitalized=='No'){
                      setIGaapCapitalizedValue(assetInfo.total_cost_without_tax);
                    }else{
                      //setIGaapCapitalizedValue(assetInfo.igaap_capitalized_value);
                      setIGaapCapitalizedValue(assetInfo.value_of_asset);
                    }
                    
            }
            //setAssetInuseDate(assetinusedate?(new Date(assetInfo.asset_in_use_date)):'');
            setTypeOfAsset(assetInfo.cap_type_of_asset);
            setFinalAsset(assetInfo.capitalization_flag);
            
            setIsTaxCapitalized(assetInfo.is_tax_capitalized);
            setInvoiceCurrency(assetInfo.invoice_currency);
            setexchangerate(assetInfo.cap_exchange_rate?assetInfo.cap_exchange_rate:assetInfo.exchange_rate);
            setIs100PercDepreciable(assetInfo.is_100_perc_depreciable);
            setIs100PercOtherDepreciable(assetInfo.is_100_perc_other_depreciable);
            setIsAnyOtherAccgGaapApplicable(assetInfo.is_any_other_accg_gaap_applicable);
            setShowOtherVal(assetInfo.is_any_other_accg_gaap_applicable);
            setIgaapLimitAmount(assetInfo.igaap_limit_amount);
            setOthergaapLimitAmount(assetInfo.othergaap_limit_amount);
            setSalvageValue(assetInfo.salvage_value);
            setOthergaapCapitalizedValue(assetInfo.othergaap_capitalized_value);
            setCapitalizationStatus(assetInfo.capitalization_status);
            setValueofAsset(assetInfo.value_of_asset);
            //console.log(valueofasset);
            //setValueWithTax(assetInfo.value_with_tax);
            //setDepreciationRate(assetInfo.depreciation_rate);
            //setTypeOfDepreciation(assetInfo.type_of_depreciation);
            
          }
        }
  
      });
  }
  function reset(){ setAssetInfo([]); setTag(''); setAssetEndDate('');setAssetOtherEndDate('');setPutToUseDate('');
  setParentTag(''); setAssetInuseDate(); setTypeOfDepreciation(''); setRecordStartDate('');
  setDepreciationRate('');setOtherDepreciationRate(''); setTypeOfAsset(''); setassetcode(''); setassetac('');
  setfnclassification(''); setClassOfAsset(''); setFinalAsset(''); setIsTaxCapitalized('');
  setIs100PercDepreciable(''); setPreCapitalizedAssetValue(''); setIsAnyOtherAccgGaapApplicable('');
  setIgaapLimitAmount(''); setOthergaapLimitAmount('');setSalvageValue('');setIGaapCapitalizedValue('');
  setTotalCostWithoutTax('');setTotalCostWithoutTax_tmp(''); setOthergaapCapitalizedValue(''); setTypeofDepShow(false);
  setPreDepType(''); setCapitalizationStatus(''); setValueofAsset('');
  }
  const getTagInfo = function() {
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      'param_data':{'tag':tag},
      'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData3 = {'token':EncryptData}
    getSTPI(oData3).then(function(res){ 
    //getSingleCapitalize(tag).then(function(res){
      if(res?.data?.data) { setErrMsg('');
        var {assetInfo,assetInfo} = res.data.data;
        setCapitalizationStatus(assetInfo.capitalization_status);
      //console.log(assetInfo.parent_tag); 
      //  setAssetInfo(assetInfo);
        if(assetInfo.tag) { 
          setTag(assetInfo.tag);
          setAssetInfo(assetInfo);
          //console.log("assetInfo");
          //console.log(assetInfo);
          //check id not Capitalized the show all disabled
          if(assetInfo.class_of_asset!=='Capitalized'){
            setAllReadOnly(true);
          }

          //Check if parent Tag is exist then 
          if(assetInfo.parent_tag){
            setParentTag(assetInfo.parent_tag);
              let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':{'tag':assetInfo.parent_tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
              }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
              let oData4 = {'token':EncryptData}
            getSTPI(oData4).then(function(res2){
              
              if(res2?.data?.data) {
                var assetInfo2 = res2.data.data.assetInfo;
                //console.log(assetInfo2);
                //alert(assetInfo2.type_of_depreciation);
                /*var asset_end_date = new Date(assetInfo2.asset_in_use_date);
                var assetend_date = new Date(asset_end_date.setFullYear(asset_end_date.getFullYear()+assetInfo2.depreciation_rate));
                var assetenddate = new Date(assetend_date.setDate(assetend_date.getDate()-1));
                console.log(assetenddate);*/
                setAssetEndDate(getFormatedDate(assetInfo2.depreciation_end_date));
                setAssetOtherEndDate(getFormatedDate(assetInfo2.depreciation_other_end_date));
                setDepreciationRate(assetInfo2.depreciation_rate);
                setOtherDepreciationRate(assetInfo2.other_depreciation_rate);
                setPreDepType(assetInfo2.type_of_depreciation);
                setTypeOfDepreciation(assetInfo2.type_of_depreciation);
                
                //setTypeOfAsset(assetInfo2.cap_type_of_asset);
                if(assetInfo2.cap_type_of_asset=="Specify"){
                  if(assetInfo2.class_of_asset==='Capitalized'){ 
                    //console.log(assetInfo2.record_start_date);
                    //console.log(assetInfo2.put_to_use_date);
                    setAssetInuseDate(assetInfo2.asset_in_use_date?(new Date(new Date(assetInfo2.asset_in_use_date))):'');
                    setRecordStartDate(assetInfo2.record_start_date?(new Date(new Date(assetInfo2.record_start_date))):'');
                    setOtherRecordStartDate(assetInfo2.other_record_start_date?(new Date(new Date(assetInfo2.other_record_start_date))):'');
                    setPutToUseDate(assetInfo2.put_to_use_date?(new Date(new Date(assetInfo2.put_to_use_date))) :'');
                  }
                  //setAssetInuseDate(assetInfo2.asset_in_use_date?getFormatedDate( assetInfo2.asset_in_use_date):'');
                  //alert(assetinusedate);
                }else{ 
                  if(assetInfo.class_of_asset==='Capitalized'){
                    //console.log(assetInfo.record_start_date);
                    //console.log(assetInfo.put_to_use_date);
                  setAssetInuseDate(assetInfo.asset_in_use_date?(new Date(new Date(assetInfo.asset_in_use_date))) :'');
                  setRecordStartDate(assetInfo.record_start_date?(new Date(new Date(assetInfo.record_start_date))) :'');
                  setOtherRecordStartDate(assetInfo.other_record_start_date?(new Date(new Date(assetInfo.other_record_start_date))) :'');
                  setPutToUseDate(assetInfo.put_to_use_date?(new Date(new Date(assetInfo.put_to_use_date))) :'');
                  }
                  //alert(assetinusedate); 
                }
                //assetInfo.asset_in_use_date = assetInfo2.asset_in_use_date?(new Date(assetInfo2.asset_in_use_date)):'';
                
                setClassOfAsset(assetInfo2.class_of_asset);

                setassetcode(assetInfo.accounting_code?assetInfo.accounting_code:assetInfo2.accounting_code);
                setassetac(assetInfo.accounting_name?assetInfo.accounting_name:assetInfo2.accounting_name);
                setfnclassification(assetInfo2.financials_classification);
                //parent value
              }
              });
            
            
          }else{ 
            //console.log("assetInfo1");
            //console.log(assetInfo);
            if(assetInfo.asset_in_use_date && (assetInfo.class_of_asset==='Capitalized')){
              let asset_end_date = new Date(assetInfo.asset_in_use_date);
              let assetend_date = new Date(asset_end_date.setFullYear(asset_end_date.getFullYear()+assetInfo.depreciation_rate));
              let assetenddate = new Date(assetend_date.setDate(assetend_date.getDate()-1));
              //console.log(assetenddate);
              setAssetEndDate(getFormatedDate(assetenddate));
              setAssetOtherEndDate(getFormatedDate(assetotherenddate));
            }
              setTypeofDepShow(true);
              setDepreciationRate(assetInfo.depreciation_rate);
              setOtherDepreciationRate(assetInfo.other_depreciation_rate);
              setTypeOfDepreciation(assetInfo.type_of_depreciation);
              //setAssetInuseDate(assetinusedate?(new Date(assetInfo.asset_in_use_date)):'');
            if(assetInfo.asset_in_use_date && (assetInfo.class_of_asset==='Capitalized')){
              setAssetInuseDate(assetInfo.asset_in_use_date?(new Date(new Date(assetInfo.asset_in_use_date))) :'');
              setRecordStartDate(assetInfo.record_start_date?(new Date(new Date(assetInfo.record_start_date))) :'');
              setOtherRecordStartDate(assetInfo.other_record_start_date?(new Date(new Date(assetInfo.other_record_start_date))) :'');
              setPutToUseDate(assetInfo.put_to_use_date?(new Date(new Date(assetInfo.put_to_use_date))) :'');
            }
            // setAssetEndDate(assetInfo.depreciation_end_date);
            setClassOfAsset(assetInfo.class_of_asset);
            setfnclassification(assetInfo.financials_classification);
            setassetcode(assetInfo.accounting_code);
            setassetac(assetInfo.accounting_name);
            //console.log("astinfo3");
          }
          
          setTypeOfAsset(assetInfo.cap_type_of_asset);
          setFinalAsset(assetInfo.capitalization_flag);
          setIsTaxCapitalized(assetInfo.is_tax_capitalized);
          setInvoiceCurrency(assetInfo.invoice_currency);
          setexchangerate(assetInfo.cap_exchange_rate?assetInfo.cap_exchange_rate:assetInfo.exchange_rate);
          setIs100PercDepreciable(assetInfo.is_100_perc_depreciable);
          setIs100PercOtherDepreciable(assetInfo.is_100_perc_other_depreciable);
          //setPreCapitalizedAssetValue(assetInfo.pre_capitalized_asset_value);
          //setPreCapitalizedAssetValue(assetInfo.value_of_asset);
          setPreCapitalizedAssetValue((parseFloat(assetInfo.value_of_asset)) * (parseFloat(assetInfo.cap_exchange_rate?assetInfo.cap_exchange_rate:assetInfo.exchange_rate)));
          
          setPreCapitalizedAssetValue_tmp(assetInfo.value_of_asset);
          setTotalCostWithoutTax(assetInfo.total_cost_without_tax); 
          setTotalCostWithoutTax_tmp(assetInfo.total_cost_without_tax);
          /*if(assetInfo.is_tax_capitalized=='No'){
            setIGaapCapitalizedValue(assetInfo.total_cost_without_tax);
          }else{
            //setIGaapCapitalizedValue(assetInfo.igaap_capitalized_value);
            setIGaapCapitalizedValue(assetInfo.value_of_asset);
          }*/
          if(assetInfo.is_tax_capitalized=='No'){ 
            setIGaapCapitalizedValue( (parseFloat(assetInfo.total_cost_without_tax)) * (parseFloat(assetInfo.cap_exchange_rate?assetInfo.cap_exchange_rate:assetInfo.exchange_rate)) );
          }else{ 
            setIGaapCapitalizedValue((parseFloat(assetInfo.value_of_asset)) * (parseFloat(assetInfo.cap_exchange_rate?assetInfo.cap_exchange_rate:assetInfo.exchange_rate)));
          }
          setIsAnyOtherAccgGaapApplicable(assetInfo.is_any_other_accg_gaap_applicable);
          setShowOtherVal(assetInfo.is_any_other_accg_gaap_applicable);
          setIgaapLimitAmount(assetInfo.igaap_limit_amount);
          setOthergaapLimitAmount(assetInfo.othergaap_limit_amount);
          setSalvageValue(assetInfo.salvage_value);
          //setIGaapCapitalizedValue(assetInfo.igaap_capitalized_value);
          setOthergaapCapitalizedValue(assetInfo.othergaap_capitalized_value);
          //setCompRefNo(assetInfo.comp_ref_no);
          setValueofAsset(assetInfo.value_of_asset);
          //setValueWithTax(assetInfo.value_with_tax);
          //console.log("astinfo5");
          //console.log(assetInfo.othergaapLimitAmount);
        }

      }else{ setErrMsg('Assets Not Found'); }
    });
  }

  const setOldAssetauditInfo = function (auditInfo) {
    var AuditOldData = {
      'tag': auditInfo.tag, 
      'asset_in_use_date':(auditInfo.asset_in_use_date?getFormatedDate(auditInfo.asset_in_use_date):''), 
      'type_of_depreciation':auditInfo.type_of_depreciation, 
      'depreciation_rate':auditInfo.depreciation_rate, 
      'other_depreciation_rate':auditInfo.other_depreciation_rate, 
      'class_of_asset':auditInfo.class_of_asset,
      'accounting_code':auditInfo.accounting_code,
      'accounting_name':auditInfo.accounting_name, 
      'financials_classification':auditInfo.financials_classification, 
      'record_start_date': (auditInfo.record_start_date?getFormatedDate(auditInfo.record_start_date):''),
      'put_to_use_date': (auditInfo.put_to_use_date?getFormatedDate(auditInfo.put_to_use_date):''),
      //'final_asset':finalAsset,
      'cap_exchange_rate':auditInfo.cap_exchange_rate,
      'capitalization_flag':auditInfo.capitalization_flag, 
      'is_tax_capitalized':auditInfo.is_tax_capitalized, 
      'is_100_perc_depreciable':auditInfo.is_100_perc_depreciable, 
      'is_100_perc_other_depreciable':auditInfo.is_100_perc_other_depreciable, 
      'pre_capitalized_asset_value':auditInfo.pre_capitalized_asset_value, 
      'is_any_other_accg_gaap_applicable':auditInfo.is_any_other_accg_gaap_applicable, 
      'igaap_limit_amount':auditInfo.igaap_limit_amount, 
      'othergaap_limit_amount':auditInfo.othergaap_limit_amount, 
      'salvage_value':auditInfo.salvage_value,
      'igaap_capitalized_value':auditInfo.igaap_capitalized_value,
      'othergaap_capitalized_value':auditInfo.othergaap_capitalized_value,
      'deletion_status':auditInfo.deletion_status,
      'cap_type_of_asset':auditInfo.cap_type_of_asset,
      'depreciation_end_date':auditInfo.depreciation_end_date,
      'depreciation_other_end_date':auditInfo.depreciation_other_end_date,
      'capitalization_status':auditInfo.capitalization_status,
      //'comp_ref_no':compRefNo,
      'value_of_asset':auditInfo.value_of_asset,
      //'value_with_tax':valuewithtax
      'other_record_start_date': ((auditInfo?.other_record_start_date)?getFormatedDate(auditInfo.other_record_start_date):''),
      'company_id':auditInfo.company_id,
      'division_id':auditInfo.division_id,
      'created_by':auditInfo.created_by,
    };
    setAuditInfo(AuditOldData);
  }
  const igaapLimitAmount_handler = (e) => {
    let name= e.target.name;
    let value= e.target.value;
    setIgaapLimitAmount(parseFloat(e.target.value));
    calIgaapLimitAmount(parseFloat(e.target.value));
  }
  const calIgaapLimitAmount =function(value){ 
    if( preCapitalizedAssetValue <= value ){
      //setFinalAsset("No");
      setIGaapCapitalizedValue(parseFloat(igaapLimitAmount)-parseFloat(salvageValue));
    }else{
      setIGaapCapitalizedValue(totalcostwithouttax);
    }
  }
  const handleTypeAsset = (e) => {
    let name= e.target.name;
    let value= e.target.value;
    //alert(value);
    setTypeOfAsset(e.target.value);
    //if(assettype="Specify"){}
    
    //calIGaapCapitalizedValue(value);
  }
  const handleTypeDepreciation = (e) => {
    let name= e.target.name;
    let value= e.target.value;
    setTypeOfDepreciation(e.target.value);
    //calIGaapCapitalizedValue(value);
  } 
  const handleClassOfAsset = (e) => {
    let name= e.target.name;
    let value= e.target.value;
    setClassOfAsset(e.target.value);
    if(value!="Capitalized"){
      setFinalAsset("NO");
      setAllReadOnly(true);
    }else{ 
      setFinalAsset("YES");
      setAllReadOnly(false);
    }
    //calIGaapCapitalizedValue(value);
  }   
  const handle_enddate = (e) => {
    let name= e.target.name;
    let value= e.target.value;
    setDepreciationRate(value);
    if(value>=0 ){ 
      if(parentTag!=='' && assettype==="Specify"){
        //end date will be same
      }else{
        //for child
        let asset_end_date = new Date(assetinusedate);  //console.log(asset_end_date.getFullYear()+parseInt(value));
        let assetend_date = new Date(asset_end_date.setFullYear((asset_end_date.getFullYear()+parseInt(value))));
        //console.log(assetend_date);
        let assetenddate = new Date(assetend_date.setDate(assetend_date.getDate()-parseInt(1)));
        //console.log(assetenddate);
        setAssetEndDate(getFormatedDate(assetenddate));
      }
    }else{setAssetEndDate('');}
  }   
  const handle_other_enddate = (e) => {
    let name= e.target.name;
    let value= e.target.value;
    setOtherDepreciationRate(value);
    if(value>=0 ){ 
      if(parentTag!=='' && assettype==="Specify"){
        //end date will be same
      }else{
        //for child
        let other_end_date = new Date(assetinusedate);  //console.log(asset_end_date.getFullYear()+parseInt(value));
        let asset_other_end_date = new Date(other_end_date.setFullYear((other_end_date.getFullYear()+parseInt(value))));
        //console.log(assetend_date);
        let assetotherenddate = new Date(asset_other_end_date.setDate(asset_other_end_date.getDate()-parseInt(1)));
        //console.log(assetenddate);
        setAssetOtherEndDate(getFormatedDate(assetotherenddate));
      }
    }else{setAssetOtherEndDate('');}
  }   
  const handle_fnclassification = (e) => {
    let name= e.target.name;
    let value= e.target.value;
    setfnclassification(e.target.value);
  }
  const exchangerate_handler = (e) => {
    setexchangerate(e.target.value);
    calInvValInrExRate(); 
  }
  const calInvValInrExRate = () => { //alert((parseFloat(invoicevaluefx)));
    setPreCapitalizedAssetValue( (parseFloat(preCapitalizedAssetValue_tmp)) * (parseFloat(exchangerate)) );
    if(isTaxCapitalized =="Yes"){
      setIGaapCapitalizedValue((parseFloat(preCapitalizedAssetValue_tmp)) * (parseFloat(exchangerate)) );
      setTotalCostWithoutTax(parseFloat(totalcostwithouttax_tmp)* parseFloat(exchangerate));
    }else{
      setIGaapCapitalizedValue(parseFloat(totalcostwithouttax_tmp)* parseFloat(exchangerate));
      setTotalCostWithoutTax(parseFloat(totalcostwithouttax_tmp)* parseFloat(exchangerate));
      //alert(totalcostwithouttax);
    }
    //setIGaapCapitalizedValue( (parseFloat(iGaapCapitalizedValue)) * (parseFloat(exchangerate))  );
  }
  const calIGaapCapitalizedValue = (e) => {
    let value= e.target.value; //alert(value);
      //if(value!="Capitalized"){
      if(value=="Yes"){
        //setIsTaxCapitalized("NO"); 
        setIsTaxCapitalized("Yes");
        setIGaapCapitalizedValue(preCapitalizedAssetValue);
      }else{ setIsTaxCapitalized("No");
        //setIsTaxCapitalized("YES");
        setIGaapCapitalizedValue(totalcostwithouttax);
      }
  }
  const calIsAnyOtherAccgGaapApplicable = (e) => {  
    let value= e.target.value; //alert(value);
    setShowOtherVal(value);
    setIsAnyOtherAccgGaapApplicable(value);
  }
  const handleSubmit = async (e) => {
		e.preventDefault(); var valid_Name=true;
    
    if (!classOfAsset || !TEXT_REGEX.test(classOfAsset)) {
			setErrClassOfAsset('Class Of Asset is required'); valid_Name=false;
			//return;
		}else{ setErrClassOfAsset(''); //alert(classOfAsset); 
    }
    if (!allreadOnly){ //check validation if not capitalized 
    if (!puttousedate || puttousedate ==='') { 
      setErrPutToUseDate('Put To Use Date is required'); valid_Name=false;
      //return;
    }else{ setErrPutToUseDate(''); //alert('1'); 
    }
    if (!assettype || assettype ==='') { 
      //Type of asset
			setErrTypeOfAsset('Depreciation mode is required'); valid_Name=false;
			//return;
		}else{ setErrTypeOfAsset(''); //alert('1'); 
    }
    if (!depreciationtype || depreciationtype ==='') { 
			setErrDepreciationType('Type of depreciation is required'); valid_Name=false;
			//return;
		}else{ setErrDepreciationType(''); //alert('1'); 
    }
    if (!assetinusedate || assetinusedate ==='') { 
			setErrAssetInuseDate('Asset in use date is required'); valid_Name=false;
			//return;
		}else{ setErrAssetInuseDate(''); //alert('1'); 
    }
    if(!recordstartdate || recordstartdate ==='') { 
			setErrRecordStartDate('Record start date is required'); valid_Name=false; //alert('1');
			//return;
		}else{ setErrRecordStartDate(''); //alert(recordstartdate);
    }
    if ((showOtherVal!=='' && showOtherVal=='Yes') && (!otherrecordstartdate || otherrecordstartdate ==='')) { 
			setErrOtherRecordStartDate('Record start date[Other-GAAP] is required'); valid_Name=false; //alert('1');
			//return;
		}else{ setErrOtherRecordStartDate(''); //alert(recordstartdate);
    }
    if (!assetac || !TEXT_SPCL_REGEX.test(assetac)) {
			setErrAssetAc('Accounting Name of the Asset is required'); valid_Name=false;
			//return;
		}else{ setErrAssetAc('');  //alert('2');
    }
    if (!fnclassification || !TEXT_REGEX.test(fnclassification)) {
			setErrFnClassification('Financials Classification is required'); valid_Name=false;
			//return;
		}else{ setErrFnClassification('');  //alert('3');
    }
    
    if (!depreciationtype || !TEXT_REGEX.test(depreciationtype)) {
			setErrDepreciationType('Depreciation type is required'); valid_Name=false;
			//return;
		}else{ setErrDepreciationType('');  //alert('4'); 
    }

    if (!depreciationrate || !NUMBER_REGEX.test(depreciationrate)) {
			setErrDepreciationRate('Depreciation Rate(No. of years) [I-GAAP] is required'); valid_Name=false;
			//return;
		}else{ setErrDepreciationRate(''); //alert('5');
    }
    if((showOtherVal!=='' && showOtherVal=='Yes') && (!otherdepreciationrate || !NUMBER_REGEX.test(otherdepreciationrate))) {
			setErrOtherDepreciationRate('Depreciation Rate(No. of years) [Other-GAAP] is required'); valid_Name=false;
			//return;
		}else{ setErrOtherDepreciationRate(''); //alert('5');
    }
    if (!finalAsset || !TEXT_REGEX.test(finalAsset)) {
			setErrFinalAsset('Final Asset is required'); valid_Name=false;
			//return;
		}else{ setErrFinalAsset(''); //alert('7'+valid_Name); 
    }
    if (!isTaxCapitalized || !TEXT_REGEX.test(isTaxCapitalized)) {
			setErrIsTaxCap('Is Tax Capitalized is required'); valid_Name=false; //alert('8'+isTaxCapitalized);
			//return;
		}else{ setErrIsTaxCap(''); //alert('8'+valid_Name); 
    }
    if (!TEXT_REGEX.test(exchangerate)) { 
			setErrExchangeRate('Exchange rate is required'); valid_Name=false; //alert('11');
			//return;
		}else{ setErrExchangeRate('');  } 
    if(!is100PercDepreciable || !TEXT_REGEX.test(is100PercDepreciable)) {
			setErrIs100PercDep ('Is 100% Depreciable Asset [I-GAAP] is required'); valid_Name=false;
			//return;
		}else{ setErrIs100PercDep(''); //alert('9'+valid_Name); 
    }
    if((showOtherVal!=='' && showOtherVal=='Yes') && (!is100PercOtherDepreciable || !TEXT_REGEX.test(is100PercOtherDepreciable))) {
			setErrIs100PercOthDep ('Is 100% Depreciable Asset [Other-GAAP] is required'); valid_Name=false;
			//return;
		}else{ setErrIs100PercOthDep(''); //alert('9'+valid_Name); 
    }
    if (!preCapitalizedAssetValue || !TEXT_REGEX.test(preCapitalizedAssetValue)) {
			setErrPreCapAsVal('Pre Capitalized Asset Value is required'); valid_Name=false;
			//return;
		}else{ setErrPreCapAsVal(''); //alert('10'+valid_Name); 
    }
    /*if (!isAnyOtherAccgGaapApplicable && igaapLimitAmount=== null && !TEXT_REGEX.test(isAnyOtherAccgGaapApplicable)) {
			setErrIsAnyOtAcGaApp('Is any other Accg GAAP Applicable is required'); valid_Name=false;
			//return;
		}else{ setErrIsAnyOtAcGaApp(''); //alert('11'+valid_Name); 
    }*/
    if (igaapLimitAmount=== null || !TEXT_REGEX.test(igaapLimitAmount)) {
			setErrIgaapLimitAmount('I-GAAP - Limit for 100% Depreciable Asset (Rs) is required'); valid_Name=false;
			//return;
		}else{ setErrIgaapLimitAmount(''); //alert('12'+igaapLimitAmount); 
    }
    if ((showOtherVal!=='' && showOtherVal=='Yes') && (othergaapLimitAmount=== null || !TEXT_REGEX.test(othergaapLimitAmount))) { //alert(othergaapLimitAmount);
			setErrOthergaapLimitAmount('Other-GAAP - Limit for 100% Depreciable Asset (Rs) is required'); valid_Name=false;
			//return;
		}else{ setErrOthergaapLimitAmount(''); //alert('13'+valid_Name); 
    }
    if (salvageValue === null || !TEXT_REGEX.test(salvageValue)) { 
			setErrSalvageValue('Salvage Value is required'); valid_Name=false;
			//return;
		}else{ setErrSalvageValue('');  //alert('14'+salvageValue); 
    }
    if (iGaapCapitalizedValue === null || !TEXT_REGEX.test(iGaapCapitalizedValue)) {
			setErrIGaCapValue('Capitalized Value (I-GAAP) is required'); valid_Name=false;
			//return;
		}else{ 
      setErrIGaCapValue(''); //alert('15'+iGaapCapitalizedValue); 
    }
    if ((showOtherVal!=='' && showOtherVal=='Yes') && (othergaapCapitalizedValue === null || !TEXT_REGEX.test(othergaapCapitalizedValue))) { 
			setErrOthGaCaptValue('Capitalized Value (Other GAAP) is required'); valid_Name=false;
			//return;
		}else{ setErrOthGaCaptValue(''); }
    }
    if (valid_Name) { //alert('valid');
      saveData(); //submit form
    }else{ 
          //alert(valid_Name);
          return false;
    }
  }
	
  const saveData = function() { //alert(isAnyOtherAccgGaapApplicable);
    var data = {
      'tag': tag, 
      'asset_in_use_date':(assetinusedate?getFormatedDate(assetinusedate):''), 
      'type_of_depreciation':depreciationtype, 
      'depreciation_rate':depreciationrate, 
      'class_of_asset':classOfAsset,
      'accounting_code':assetcode,
      'accounting_name':assetac, 
      'financials_classification':fnclassification, 
      'record_start_date': (recordstartdate?getFormatedDate(recordstartdate):''),
      'put_to_use_date': (puttousedate?getFormatedDate(puttousedate):''),
      //'final_asset':finalAsset,
      'cap_exchange_rate':exchangerate,
      'capitalization_flag':finalAsset, 
      'is_tax_capitalized':isTaxCapitalized, 
      'is_100_perc_depreciable':is100PercDepreciable,
      'pre_capitalized_asset_value':preCapitalizedAssetValue, 
      'is_any_other_accg_gaap_applicable':isAnyOtherAccgGaapApplicable, 
      'igaap_limit_amount':igaapLimitAmount, 
      'salvage_value':salvageValue,
      'igaap_capitalized_value':iGaapCapitalizedValue,
      'deletion_status':'No',
      'cap_type_of_asset':assettype,
      'depreciation_end_date':assetenddate,
      'capitalization_status':'Y',
      //'comp_ref_no':compRefNo,
      'value_of_asset':valueofasset,
      //'value_with_tax':valuewithtax
      'company_id':sessionStorage.getItem('LoggedInCompanyId'),
      'division_id':sessionStorage.getItem('LoggedInDivisionId'),
      'created_by':sessionStorage.getItem('LoggedInUserId'),
       
    };
    if(isAnyOtherAccgGaapApplicable=='Yes'){
      data.other_record_start_date = ((otherrecordstartdate!='')?getFormatedDate(otherrecordstartdate):'');
      data.other_depreciation_rate = otherdepreciationrate;
      data.depreciation_other_end_date=assetotherenddate;
      data.is_100_perc_other_depreciable=is100PercOtherDepreciable;
      data.othergaap_capitalized_value=othergaapCapitalizedValue;
      data.othergaap_limit_amount=othergaapLimitAmount;
    }
    //console.log('depreciationtype');console.log(data);
    if (assetInfo.type_of_depreciation !== '' && assetInfo.type_of_depreciation !== null) {
      var objAudit = {'audit_module':'Finance Operation','audit_method':'updated','audit_tag':tag,'old_values':auditInfo,'new_values':data}
    }else{
      var objAudit = {'audit_module':'Finance Operation','audit_method':'created','audit_tag':tag,'old_values':'','new_values':data}
    }
    try {  //alert('abc');
			//const response = saveData();
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':data,'audit_params':objAudit,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData5 = {'token':EncryptData}
      saveCapitalize(oData5).then(function(res){
        /*getAssets().then(res=>{
          if(res?.data?.data?.length) setItems(res.data.data);
        })*/
        if((typeof(res.data.errors)!== "undefined") && res.data.errors.errno>0){ 
          setErrMsg(res.data.message);setSuccess(false);
        }else{ setSuccess(res.data.message); setDisabled(true); setErrMsg(false); reset();
          setErrTag(''); setErrAssetInuseDate(''); setDepreciationRate('');setOtherDepreciationRate('');setErrAssetAc(''); setErrFnClassification(''); 
          setErrClassOfAsset(''); setErrFinalAsset(''); setErrIsTaxCap(''); setIs100PercDepreciable('');
          setErrPreCapAsVal(''); setErrIsAnyOtAcGaApp('');setErrIgaapLimitAmount(''); setErrOthergaapLimitAmount('');
          setErrSalvageValue('');setErrIGaCapValue(''); setErrOthGaCaptValue(''); 
        }
        });
        //elementRef.current.focus();
      } catch (err) { setSuccess(false); setErrMsg(err.data.message);
        if (!err?.data) {
                  console.log(err);
          setErrMsg('No Server Response');
        } else if (err.data?.status === 409) {
          setErrMsg('Username Taken');
        } else {
          setErrMsg('Insert/Update Failed');
        }
        //elementRef.current.focus();
      }
  }

  return (
    <div className="container" id='single-asset-capitalization'>
      <div className="row">
        <div className="col">
          <h5>Single Capitalization</h5>
        </div>
        <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>
        {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
      </div>
      <div className='row '>
            <div className='col-12'>&nbsp;</div>
            
            {
            (Tag!=='' && Tag!==null) ? <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div><div className='col'><input value={assetInfo.tag} type="text" readOnly="readonly" className="form-control" /></div> <div className='col'></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' /><p></p></div>
            <div className='col'><button onClick={getTagInfo} className='btn btn-info btn-md'><i className="bi bi-search"></i> Search </button>&nbsp;<a href={'/single-asset-capitalization' }><button  className='btn btn-secondary btn-md'>Reset</button></a>&nbsp;</div></div>
            }
      <div className='col-12'>&nbsp;</div>
      </div>
      <form onSubmit={handleSubmit} >
      <div className='row single__asset__form'>
                
                <div className='col-6'>
                <div className="row form-fields"><div className="label col-6"><label> Class of Asset </label><span className="star error"> *</span></div>
                  <div className="col-6">
                    <select onChange={handleClassOfAsset} className="form-select form-select-sm mb-3" >
                    <option value="">Select One</option>
                      <option value="Capitalized" selected={(classOfAsset=="Capitalized")? "selected" : ''}>Capitalized</option>
                      <option value="FOC Aset" selected={(classOfAsset=="FOC Aset")? "selected" : ''}>FOC Aset</option>
                      <option value="WIP Asset" selected={(classOfAsset=="WIP Asset")? "selected" : ''}>WIP Asset</option>
                      <option value="Leased Asset" selected={(classOfAsset=="Leased Asset")? "selected" : ''}>Leased Asset</option>
                      <option value="Opex Asset" selected={(classOfAsset=="Opex Asset")? "selected" : ''}>Opex Asset</option>
                  </select><span className="invalid-feedback"> {ErrClassOfAsset} </span>
                  </div></div>
                </div>
                <div className='col-6'> 
                <FromFieldDate star="*"
                    label="Record Start Date"
                    inputValue={recordstartdate}
                    setInputValue={setRecordStartDate} 
                    setErrorMsg={ErrRecordStartDate}
                    readOnly={allreadOnly?"readonly":""}
                  />
                  </div>
            </div>
      <div className="row">
      <div className="col-6">
      <FromFieldText  //star="*"
        label="Parent TAG" 
        inputValue={assetInfo.parent_tag} 
        readOnly={(assetInfo.parent_tag=="undefine")?'':"readonly"} 
      />
      </div>
      <div className="col-6">
      <div className="row form-fields">
      <div className="col-6"><label>Depreciation mode <span className="star error"> * </span></label></div>
      <div className="col-6">
      <select onChange={handleTypeAsset} className="form-select form-select-sm mb-3" disabled={allreadOnly?"disabled":""}>
        <option value="">Select One</option>
          <option value="UseParent" selected={(assettype=="UseParent")? "selected" : ''}>Use Parent</option>
          <option value="Specify" selected={(assettype=="Specify")? "selected" : ''}>Specify</option>
      </select><span className="invalid-feedback"> {ErrTypeOfAsset} </span></div>
      </div>
      </div>
      </div>
      
      <div className="row">
      <div className="col-6">
      {(assetInfo.parent_tag!="undefined") ? ((assettype!="UseParent")? <FromFieldDate star="*"
            label="Date of Capitalization / Asset Ready To Use"
            inputValue={assetinusedate}
            setInputValue={setAssetInuseDate} 
            setErrorMsg={ErrAssetInuseDate}
            readOnly={allreadOnly?"readonly":""}
          /> :<FromFieldDate
            label="Date of Capitalization / Asset Ready To Use"
            inputValue={assetinusedate}
            setInputValue={setAssetInuseDate} 
            setErrorMsg={ErrAssetInuseDate}
            readOnly="readonly"
          />) : ( 
          <FromFieldDate star="*"
            label="Date of Capitalization / Asset Ready To Use"
            inputValue={assetinusedate}
            setInputValue={setAssetInuseDate} 
            setErrorMsg={ErrAssetInuseDate}
            readOnly={allreadOnly?"readonly":""}
          />
          )} 
        </div>
        <div className='col-6'>
                  {typeofDepShow ? (<div className="row form-fields"><div className="label col-6"><label> Type of Depreciation <span className="star error"> *</span></label></div>
                  <div className="col-6"><select onChange={handleTypeDepreciation} className="form-select form-select-sm mb-3" disabled={ (parentTag!=='')?"true":(allreadOnly)?"disabled":""}>
                    <option value="">Select One</option>
                      <option value="SLMYMW" selected={(depreciationtype=="SLMYMW")? "selected" : ''}>SLMY MW</option>
                      <option value="SLMYDW" selected={(depreciationtype=="SLMYDW")? "selected" : ''}>SLMY DW</option>
                      <option value="SLMPMW" selected={(depreciationtype=="SLMPMW")? "selected" : ''}>SLMP MW</option>
                      <option value="SLMPDW" selected={(depreciationtype=="SLMPDW")? "selected" : ''}>SLMP DW</option>
                  </select><span className="invalid-feedback"> {ErrDepreciationType} </span></div></div>
                ) : ( <FromFieldText star="*"
                label="Type of Depreciation" 
                inputValue={preDepType} 
                readOnly="readonly" 
                setErrorMsg={ErrDepreciationType}
              /> )}
                  </div>
        <div className="col-6">
          <div className="row"><div className="col-md-6">
            <label> Depreciation Rate(No. of years) [I-GAAP] <span className="star error"> * </span></label>
            </div>
            <div className="col-md-6">
              <input type="text" onChange={handle_enddate} value={depreciationrate} className="form-control" placeholder="Depreciation Rate[I-GAAP]" readOnly={(parentTag!=='')?"readonly":(allreadOnly)?"readonly":""} />
                <span className="invalid-feedback">{ErrOtherDepreciationRate}</span>
            </div>
            </div>
        </div>
        <div className="col-6">
          <FromFieldText  //star="*"
            label="End Date [I-GAAP]" 
            placeholder="End Date [I-GAAP]" 
            inputValue={assetenddate} 
            setInputValue={setAssetEndDate} 
            //setErrorMsg={ErrDepreciationRate}
            readOnly="readOnly"
          />
        </div>
        
        
      </div>
      <div className='row single__asset__form'>
                <div className='col-6'>
                <FromSpFieldText label="Accounting Code of Asset" //star="*"
                        inputValue={assetcode}
                        setInputValue={setassetcode}
                        readOnly={allreadOnly?"readonly":""}
                    />
                </div>
                <div className='col-6'>
                <FromSpFieldText label="Accounting Name of the Asset" star="*"
                        inputValue={assetac}
                        setInputValue={setassetac}
                        setErrorMsg={ErrAssetAc}
                        readOnly={allreadOnly?"readonly":""}
                    />
                </div>
            </div>

            <div className='row single__asset__form'>
                <div className='col-6'>
                <div className="row form-fields"><div className="label col-6"><label> Financials Classification </label><span className="star error"> * </span></div>
                  <div className="col-6">
                <select onChange={handle_fnclassification} className="form-select form-select-sm" disabled={ (parentTag!=='')?"true":(allreadOnly)?"disabled":""}>
                    <option value="">Select Financials Classification</option>
                        { classifications && classifications.length > 0 ? (
                            classifications.map((option) => ( 
                            <option value={option.id} selected={(option.id==fnclassification)? "selected" : ''}>{option.category_name}</option>
                            ))
                        ) : ( <option value="">No Data</option> ) }
                    </select><span className="invalid-feedback"> {ErrFnClassification} </span>
                    </div>
                    </div>
                  </div>
                <div className='col-6'>
                  <FromFieldDate star="*"
                    label="Put to Use Date [IT]"
                    inputValue={puttousedate}
                    setInputValue={setPutToUseDate} 
                    setErrorMsg={ErrPutToUseDate}
                    readOnly={allreadOnly?"readonly":""}
                  />
                </div>
            </div>

      <div className="row">
        <div className="col-6">
        
        <div className="row form-fields">
          <div className="label col-6">
          <label> Final Asset Capitalization Flag </label>
          <span className="star error"> *</span>
          </div>
          <div className="item col-6">
            <input type="text" name="capitalization_flag" readOnly value={finalAsset} className="form-control" placeholder="Capitalization Flag" />
              <span className="invalid-feedback"> {ErrFinalAsset} </span>
          </div>
        </div>
        
        </div>
        <div className="col-6">
        <div className="row form-fields">
          <div className="col-6"><label>Is Tax Capitalized <span className="star error"> * </span></label></div>
          <div className="col-6">
          <select onChange={calIGaapCapitalizedValue} className="form-select form-select-sm mb-3" disabled={allreadOnly?"disabled":""}> 
            <option value="">Select One</option>
              <option value="Yes" selected={(isTaxCapitalized=="Yes")? "selected" : ''}>Yes</option>
              <option value="No" selected={(isTaxCapitalized=="No")? "selected" : ''}>No</option>
          </select><span className="invalid-feedback"> {ErrIsTaxCap} </span></div>
        </div>
        </div>
      
      </div>
      <div className="row">
        <div className="col-6">
          <FromFieldText star="*"
              label="Invoice Currency"
              placeholder=""
              inputValue={invoicecurrency} 
              setInputValue={setInvoiceCurrency} 
              readOnly="readonly"
          />
          </div>
          <div className="col-6">
          <div className="row">
              <div className="col-md-6" > 
                  <label> Exchange Rate </label>
                  <span className="star error">* </span> 
              </div>
              <div className="col-md-6" >
              <input type='number' onChange={exchangerate_handler}  name="exchange_rate" value={exchangerate} 
              onBlur ={exchangerate_handler} className="form-control"  placeholder='Enter the exchange rate' readOnly={allreadOnly?"readonly":""}/>
              <span className="invalid-feedback"> {ErrExchangeRate} </span>
              </div>
           </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <FromFieldSelect star="*"
              label="Is 100% Depreciable Asset [I-GAAP]"
              placeholder="Select Is 100% Depreciable Asset [I-GAAP]"
              selectedValue={is100PercDepreciable}
              setSelectedValue={setIs100PercDepreciable}
              values={["Yes","No"]}
              setErrorMsg={ErrIs100PercDep}
              disabled={allreadOnly?"disabled":""}
          />
        </div>
        <div className="col-6">
          <FromFieldNumber star="*"
              label="Pre Capitalized Asset Value"
              placeholder="Pre Capitalized Asset Value"
              inputValue={preCapitalizedAssetValue} 
              setInputValue={setPreCapitalizedAssetValue} 
              setErrorMsg={ErrPreCapAsVal}
              readOnly={allreadOnly?"readonly":""}
          />
        </div>
      
      </div>
      <div className="row">
        <div className="col-6">
        <div className="row form-fields">
          <div className="col-6"><label>Is any other Accg GAAP Applicable</label></div>
          <div className="col-6">
          <select onChange={calIsAnyOtherAccgGaapApplicable} className="form-select form-select-sm mb-3" disabled={allreadOnly?"disabled":""}> 
            <option value="">Select One</option>
              <option value="Yes" selected={(isAnyOtherAccgGaapApplicable=="Yes")? "selected" : ''}>Yes</option>
              <option value="No" selected={(isAnyOtherAccgGaapApplicable=="No")? "selected" : ''}>No</option>
          </select><span className="invalid-feedback"> {ErrIs100PercDep} </span></div>
        </div>
        {/*<FromFieldSelect 
            label="Is any other Accg GAAP Applicable"
            placeholder="Select Is any other Accg GAAP Applicable"
            selectedValue={isAnyOtherAccgGaapApplicable}
            setSelectedValue={setIsAnyOtherAccgGaapApplicable}
            values={["Yes","No"]}
            disabled={allreadOnly?"disabled":""}
         />*/}
          
        </div>
        <div className="col-6">
        <FromFieldNumber star="*"
            label="I-GAAP - Limit for 100% Depreciable Asset (Rs)"
            placeholder="I-GAAP - Limit for 100% Depreciable Asset (Rs)" 
            inputValue={igaapLimitAmount} 
            setInputValue={setIgaapLimitAmount} 
            setErrorMsg={ErrIgaapLimitAmount}
            readOnly={allreadOnly?"readonly":""}
        /> 
         
       
        </div>
      
      </div>

      <div className="row ">
        <div className="col-6">
        <FromFieldNumber star="*"
            label="Salvage Value"
            placeholder="Salvage Value" 
            inputValue={salvageValue} 
            setInputValue={setSalvageValue} 
            setErrorMsg={ErrSalvageValue}
            readOnly={allreadOnly?"readonly":""}
          />
          
        </div>
        <div className="col-6">
        <FromFieldNumber   star="*"
            label="Capitalized Value (I-GAAP)"
            placeholder="Capitalized Value (I-GAAP)" 
            inputValue={iGaapCapitalizedValue} 
            setInputValue={setIGaapCapitalizedValue}
            setErrorMsg={ErrIGaCapValue}
            readOnly={allreadOnly?"readonly":""}
          />  
         
        </div>
      </div>
      
      { ((showOtherVal!=='' && showOtherVal=='Yes')||(isAnyOtherAccgGaapApplicable!=='' && isAnyOtherAccgGaapApplicable=='Yes')) ? <div>
      <div className="row">
        <h5>&nbsp;</h5>
        <hr></hr>
        <h5>Other-GAAP</h5>
        </div>
        <div className="row">
        <div className="col-6">
        <FromFieldDate star="*"
                    label="Record Start Date [Other-GAAP]"
                    inputValue={otherrecordstartdate}
                    setInputValue={setOtherRecordStartDate} 
                    setErrorMsg={ErrOtherRecordStartDate}
                    readOnly={allreadOnly?"readonly":""}
                  />
         
        </div>
        <div className="col-6">
        <FromFieldSelect star="*"
                    label="Is 100% Depreciable Asset [Other-GAAP]"
                    placeholder="Select Is 100% Depreciable Asset [Other-GAAP]"
                    selectedValue={is100PercOtherDepreciable}
                    setSelectedValue={setIs100PercOtherDepreciable}
                    values={["Yes","No"]}
                    setErrorMsg={ErrIs100PercOthDep}
                    disabled={allreadOnly?"disabled":""}
                />
         
        </div>
        </div>
        <div className="row">
        <div className="col-6">
            <div className="row"><div className="col-md-6">
              <label> Depreciation Rate(No. of years) [Other-GAAP] <span className="star error"> * </span></label>
              </div>
              <div className="col-md-6">
                <input type="text" onChange={handle_other_enddate} value={otherdepreciationrate} className="form-control" placeholder="Depreciation Rate[Other-GAAP]" readOnly={(parentTag!=='')?"readonly":(allreadOnly)?"readonly":""} />
                  <span className="invalid-feedback"> {ErrOtherDepreciationRate} </span>
              </div>
              </div>
          </div>
          <div className="col-6">
          <FromFieldText  //star="*"
              label="End Date [Other-GAAP]" 
              placeholder="End Date [Other-GAAP]" 
              inputValue={assetotherenddate} 
              setInputValue={setAssetOtherEndDate} 
              setErrorMsg={ErrDepreciationRate}
              readOnly="readOnly"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <FromFieldNumber   star="*"
              label="Other-GAAP - Limit for 100% Depreciable Asset (Rs)"
              placeholder="Other-GAAP - Limit for 100% Depreciable Asset (Rs)" 
              inputValue={othergaapLimitAmount} 
              setInputValue={setOthergaapLimitAmount} 
              setErrorMsg={ErrOthergaapLimitAmount}
              readOnly={allreadOnly?"readonly":""}
            />
            </div>
          <div className="col-6">
            <FromFieldNumber star="*"
              label="Capitalized Value (Other GAAP)" 
              placeholder="Capitalized Value (Other GAAP)" 
              inputValue={othergaapCapitalizedValue} 
              setInputValue={setOthergaapCapitalizedValue}
              setErrorMsg={ErrOthGaCaptValue}
              readOnly={allreadOnly?"readonly":""}
            />        
          </div>
        </div>
      </div>:('') }
      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
        { (Tag!=='' && Tag!==null) ? <div><button className="btn btn-primary btn-md"  disabled={disabled}>Update</button>&nbsp;&nbsp;<a href={'/assets-capitalization-view?tag='+ Tag } className="btn btn-secondary btn-md">Cancel</a></div>
                 : (capitalization_status=='N') ?<button className="btn btn-primary btn-md"  disabled={disabled}> SAVE </button>:''
                 } 
          {/*<button onClick={saveData} className='btn btn-primary btn-md'> SAVE </button>*/}
        </div>
      </div>
    </form>
    </div>
  )
}

export default SACapForm;

