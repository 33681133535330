import React from "react";
import { Link } from "react-router-dom";
const baseURL = process.env.REACT_APP_BASE_URL;
export default function SidebarItem({ item, isOpen, onToggle, selectedSub, onSelect }) {
     
    let aSubModuleId = [];
    const sSubModuleId = sessionStorage.getItem("LoggedInSubModuleId");

    if (sSubModuleId != null) {
        aSubModuleId = sSubModuleId.split(",");
    }

    const handleSubClick = (subId) => {
        onSelect(subId); // Notify parent about the selected submenu
    };

    if (item.childrens) {
        return (
            <div className={isOpen ? "sidebar-item open" : "sidebar-item"}>
                <div className="sidebar-title" onClick={onToggle}>
          <span className="css-1pq52q7">
          {(item.img_icon!='')?(<img className="img_sidebar_icon" src={baseURL+item.img_icon}/>): item.icon && <i className={item.icon}></i>}
          &nbsp;{item.title}
          </span>
                    <i className="bi-chevron-down toggle-btn"></i>
                </div>
                <div className="sidebar-content">
                    {item.childrens.map((child, index) => {
                        if (aSubModuleId?.indexOf(child.sub_id) > -1) {
                            return (
                                <SidebarItem
                                    key={index}
                                    item={child}
                                    selectedSub={selectedSub}
                                    onSelect={handleSubClick}
                                    isHighlighted={selectedSub === child.sub_id}
                                />
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        );
    } else {
        return (
            <Link
                to={item.path ? item.path : "#"}
                className={`sidebar-item plain ${selectedSub === item.sub_id ? "highlighted" : ""}`}
                onClick={() => handleSubClick(item.sub_id)}
            >
                {(item.img_icon!='')?(<img className="img_sidebar_icon" src={baseURL+item.img_icon}/>): item.icon && <i className={item.icon}></i>}
                &nbsp;{item.title}
            </Link>
        );
    }
}
