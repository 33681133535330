import React, {useEffect, useState} from 'react'
//import Chart from "chart.js/auto";
//import { CategoryScale } from "chart.js";
import {
    Bar,
    BarChart,
    Cell,
    LabelList,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {getDashboardDetails} from '../../services/asset.service';
import './Dashboard.css';
import * as CryptoJS from "crypto-js";
import CanvasJSReact from "@canvasjs/react-charts";
 
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
//import { Bar, Pie } from 'react-chartjs-2';
function getNumberFormat(number) {
    const formattedNumber = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
      return formattedNumber
    }

function Dashboard1() {
    const [barChartData, setBarChartData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [assetInfo, setAssetInfo] = useState([]);
    const [BangaloreInfo, setBangaloreDataInfo] = useState([]);
    const [ChennaiInfo, setChennaiDataInfo] = useState([]);
    const [HyderabadInfo, setHyderabadDataInfo] = useState([]);
    const [allocatedInfo, setAllocatedDataInfo] = useState([]);
    const [notAllocatedInfo, setNotAllocatedDataInfo] = useState([]);
    const [yearOfAdditionInfo, setYearOfAdditionDataInfo] = useState([]);
    const [financialsClassificationInfo, setFinancialsClassificationDataInfo] = useState([]);
    const [allocableInfo, setallocableDataInfo] = useState([]);
    const [nonAllocableInfo, setNonAllocableDataInfo] = useState([]);
    const [newFreeInfo, setNewFreeDataInfo] = useState([]);
    const [capexInfo, setCapexDataInfo] = useState([]);
    const [opexInfo, setOpexDataInfo] = useState([]);
    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        setLoading(true); // Show loader before fetching data
        getDashboardDetails(oData).then(res => {

            if (res?.data?.data?.assetInfo) { //res.data.data
                setAssetInfo(res.data.data.assetInfo);
                setBangaloreDataInfo(res.data.data.bangaloreData);
                setChennaiDataInfo(res.data.data.chennaiData);
                setHyderabadDataInfo(res.data.data.hyderabadData);
                setAllocatedDataInfo(res.data.data.allocatedCountData);
                setNotAllocatedDataInfo(res.data.data.notAllocatedCountData);
                setYearOfAdditionDataInfo(res.data.data.yearOfAdditionData);
                setFinancialsClassificationDataInfo(res.data.data.financialsClassificationData);
                setallocableDataInfo(res.data.data.allocableData);
                setNonAllocableDataInfo(res.data.data.nonAllocableData);
                setNewFreeDataInfo(res.data.data.newFreeData);
                setCapexDataInfo(res.data.data.capexData);
                setOpexDataInfo(res.data.data.opexData);
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
            // Handle error if needed
        }).finally(() => {
            setLoading(false); // Hide loader when data fetching is done (success or failure)
        });
    }, []);
    const sSub_Module_Id = sessionStorage.getItem('LoggedInSubModuleId');
    const aSub_Module_Id = sSub_Module_Id.split(',');
    //console.log('aSub_Module_Id'); console.log(aSub_Module_Id);
    const data = [
        {name: 'Category 1', value: 30},
        {name: 'Category 2', value: 50},
        {name: 'Category 3', value: 20},
    ];

    const IdentifyData = [
        { label: 'Identifiable', y: assetInfo?.count_assets - allocatedInfo?.allocated_tag},
        {label: 'Non Identifiable', y: notAllocatedInfo.not_allocated_tag},

    ];

    const fcData = financialsClassificationInfo.map(item => ({
        label: item.financial_classification_name,
        y: item.count_asset,
        //x: parseFloat(item.asset_value).toFixed(2) ,
    }));

    const yearAdditionData = yearOfAdditionInfo.map(item => ({
        label: item?.year_short_name?item.year_short_name:'0',
        y: item.count_asset
    }));
    const yearAdditionValue = yearOfAdditionInfo.map(item => ({
        label: item?.year_short_name?item.year_short_name:'0',
        y: item.total_cost
    }));
    
    const yearAdditionCostValue1 = [
            {   type: "column",
            //name: "China",
            //color: "#FFD166",
            showInLegend: true,
            //yValueFormatString: "$##,###K",
            dataPoints:  yearOfAdditionInfo.map(item => (
                { label: item?.year_short_name?item.year_short_name:'0', y: item.count_asset }))
            
        },{   type: "column",
            //name: "China",
            //color: "#FFD166",
            showInLegend: true,
            yValueFormatString: "₹##,###",
            dataPoints: yearOfAdditionInfo.map(item => (
                { label: item?.year_short_name?item.year_short_name:'0', y: item.total_cost }))
            
        }
    ]
    const allocableData = [
        {label: 'Allocable', y: allocableInfo?.count_allocable},
        {label: 'Non-Allocable', y: (allocatedInfo?.allocated_tag > 0) ? allocatedInfo?.allocated_tag : 0},
    ];

    const allocableData2 = [
        {label: 'Allocated', y: allocableInfo?.count_allocable},
        {label: 'Free', y: (allocatedInfo?.allocated_tag > 0) ? allocatedInfo?.allocated_tag : 0},
        {label: 'New Free', y: newFreeInfo},
    ];

    const capexData = [
        {label: 'Capex', y: capexInfo?.count_capex},
        {label: 'Opex', y: opexInfo?.count_opex},
    ];

    const COLORS = ['#0088FE', '#00C49F'];
    const COLORS1 = ['#0088FE', '#00C49F', '#FFBB28'];
    const COLORS_HARI = ["#0088FE", "#FFC300", "#01A36A", "#9AECDB", "#85C1E9", "#A569BD", "#F1948A", "#BB8FCE", "#82E0AA", "#F8C471"];
    //console.log('yearAdditionData');console.log(yearAdditionData);
    const fc_bar_options = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2", //"light1", "dark1", "dark2"
        title:{
          text: ""
        },
        axisY: {
          includeZero: true
        },
        data: [{
          type: "column", //change type to bar, line, area, pie, etc
          indexLabel: "{y}", //Shows y value on all Data Points
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          dataPoints: fcData
        }]
      }
    const bar_options = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2", //"light1", "dark1", "dark2"
        title:{
          text: ""
        },
        axisY: {
          includeZero: true
        },
        data: [{
          type: "column", //change type to bar, line, area, pie, etc
          indexLabel: "{y}", //Shows y value on all Data Points
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          dataPoints: yearAdditionData
        }]
      }
    const bar_options1 = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2", //"light1", "dark1", "dark2"
        title:{
          text: ""
        },
        axisY: {
            prefix: "₹",
          includeZero: true
        },
        data: [{
          type: "column", //change type to bar, line, area, pie, etc
          indexLabel: "{y}", //Shows y value on all Data Points
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          dataPoints: yearAdditionValue
        }]
      }
    const multi_bar_options = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2",
        title: {
            text: ""
        },
        axisY: {
            prefix: "₹",
            //gridThickness: 0,
            tickLength: 0,
            //suffix: "K",
            //title: "Sales in INR",
            includeZero: true
        },
        
        toolTip: {
            shared: true
        },
        data: yearAdditionCostValue1
    };
      const pie_options = {
        exportEnabled: true,
        animationEnabled: true,
        title: {
            text: ""
        },
        data: [{
            type: "pie",
            startAngle: 75,
            //toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            //indexLabel: "{label} - {y}%",
            dataPoints: IdentifyData
        }]
    }
      
      const allocable_options = {
        exportEnabled: true,
        animationEnabled: true,
        title: {
            text: ""
        },
        data: [{
            type: "pie",
            startAngle: 75,
            //toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            //indexLabel: "{label} - {y}%",
            dataPoints: allocableData
        }]
    }
    const allocable1_options = {
        exportEnabled: true,
        animationEnabled: true,
        title: {
            text: ""
        },
        data: [{
            type: "pie",
            startAngle: 75,
            //toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            //indexLabel: "{label} - {y}%",
            dataPoints: allocableData2
        }]
    }
    const capex_options = {
        exportEnabled: true,
        animationEnabled: true,
        title: {
            text: ""
        },
        data: [{
            type: "pie",
            startAngle: 75,
            //toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            //indexLabel: "{label} - {y}%",
            dataPoints: capexData
        }]
    }
    const imageUrl = "fixed-asset1.png";
    return (
        <div id="Dashboard">
            <div className="container">
                <div className="row gx-lg-5">
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        <div className="card dasboard-card1">
                            
                            <div className="card-body">
                            <div class="css-ellwb2"><b>Count:</b> <span class="MuiBox-root css-j4c6pt">{assetInfo.count_assets}</span></div>
                            <div class="css-1tjwiah"><img style={{maxWidth:'100%'}} src={imageUrl}/></div>
                                <h5 className="card-title">Total Book Assets</h5>
                                <div class="css-yi7yso"><div class="MuiBox-root css-5poqnu">Total Cost</div>
                                <div class="css-y7xf7j">{getNumberFormat(assetInfo?.total_cost)}</div></div>
                            </div>
                            <span class="css-hn7b8w"></span>
                        </div>


                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className="card dasboard-card2" >
                        <div class="css-ellwb2"><b>Count:</b> <span class="css-j4c6pt">{BangaloreInfo?.count_assets}</span></div>
                        <div class="css-1tjwiah"><img style={{maxWidth:'100%'}} src={imageUrl}/></div>
                            <div className="card-body">
                                <h5 class="card-title">IDC - Bangalore</h5>
                                <div class="css-yi7yso"><div class="MuiBox-root css-5poqnu">Total Cost</div>
                                <div class="css-y7xf7j">{getNumberFormat(BangaloreInfo?.total_cost)}</div></div>
                            </div>
                            <span class="css-esqfyn"></span>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className="card dasboard-card3">
                        <div class="css-ellwb2"><b>Count:</b> <span class="MuiBox-root css-j4c6pt">{ChennaiInfo?.count_assets}</span></div>
                        <div class="css-1tjwiah"><img style={{maxWidth:'100%'}} src={imageUrl}/></div>
                            <div className="card-body">
                                <h5 class="card-title">IDC - Chennai</h5>
                                <div class="css-yi7yso"><div class="MuiBox-root css-5poqnu">Total Cost</div>
                                <div class="css-y7xf7j">{getNumberFormat(ChennaiInfo?.total_cost)}</div></div>
                            </div>
                            <span class="css-tso493"></span>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className="card dasboard-card4">
                        <div class="css-ellwb2"><b>Count:</b> <span class="MuiBox-root css-j4c6pt">{HyderabadInfo.count_assets}</span></div>
                        <div class="css-1tjwiah"><img style={{maxWidth:'100%'}} src={imageUrl}/></div>
                            <div className="card-body">
                                <h5 class="card-title">IDC - Hyderabad</h5>
                                <div class="css-yi7yso"><div class="MuiBox-root css-5poqnu">Total Cost</div>
                                <div class="css-y7xf7j">{getNumberFormat(HyderabadInfo?.total_cost)}</div></div>
                            </div>
                            <span class="css-1n11yoh"></span>
                        </div>
                    </div>

                </div>
                <div className="row gx-lg-5"><br></br></div>
                <div className="row gx-lg-5">
                    {(aSub_Module_Id.indexOf('114') > -1) ? (<>
                        <div className='col-lg-6 mb-5 mb-lg-0'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 style={{textAlign: 'center'}}>Year Wise Addition</h5>
                                    {/*<ResponsiveContainer width="100%" height={300}>
                                        <BarChart data={yearAdditionData} barGap={5} barCategoryGap={10}>
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip/>
                                            <Legend/>
                                            <Bar dataKey="value" fill="#8884d8" barSize={25} name="Tag Count"/>
                                        </BarChart>
                                    </ResponsiveContainer>*/}
                                    <CanvasJSChart options = {bar_options} 
                                        /* onRef={ref => this.chart = ref} */
                                        /* containerProps={{ width: '100%', height: '300px' }} */
                                    />
                                </div>
                            </div>
                        </div></>):(<></>)}
                    {(aSub_Module_Id.indexOf('114') > -1) ? (<>
                        <div className='col-lg-6 mb-5 mb-lg-0'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 style={{textAlign: 'center'}}>Year Wise Addition</h5>
                                    {/*<ResponsiveContainer width="100%" height={300}>
                                        <BarChart data={yearAdditionData} barGap={5} barCategoryGap={10}>
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip/>
                                            <Legend/>
                                            <Bar dataKey="value" fill="#8884d8" barSize={25} name="Tag Count"/>
                                        </BarChart>
                                    </ResponsiveContainer>*/}
                                    <CanvasJSChart options = {bar_options1} />
                                    
                                </div>
                            </div>
                        </div></>):(<></>)}
                    {(aSub_Module_Id.indexOf('114') > -1) ? (<>
                        <div className='col-lg-6 mb-5 mb-lg-0'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 style={{textAlign: 'center'}}>Year Wise Addition</h5>
                                    {/*<ResponsiveContainer width="100%" height={300}>
                                        <BarChart data={yearAdditionData} barGap={5} barCategoryGap={10}>
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip/>
                                            <Legend/>
                                            <Bar dataKey="value" fill="#8884d8" barSize={25} name="Tag Count"/>
                                        </BarChart>
                                    </ResponsiveContainer>*/}
                                    <CanvasJSChart options = {multi_bar_options} />
                                    
                                </div>
                            </div>
                        </div></>):(<></>)}
                    {(aSub_Module_Id.indexOf('115') > -1) ? (<>
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="card">
                                <div className="card-body">
                                    <h5 style={{textAlign: 'center'}}>Identifiable Vs Non Identifiable</h5>
                                    {/*<ResponsiveContainer width="100%" height={300}>
                                        <PieChart>
                                            <Pie dataKey="value"
                                                data={IdentifyData}
                                                cx={200}
                                                cy={150}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                label
                                            >
                                                {data.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                                ))}
                                            </Pie>

                                            <Tooltip/>
                                            <Legend/>
                                        </PieChart>
                                        
                                    </ResponsiveContainer>*/}
                                    <CanvasJSChart options = {pie_options} />
                                </div>
                            </div>
                        </div>
                    </>):(<></>)}
                </div>

                <div className="row gx-lg-5 mt-3">
                    {(aSub_Module_Id.indexOf('116') > -1) ? (<>
                    <div className='col-lg-12 mb-5 mb-lg-0'>
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{textAlign: 'center'}}>FS Classification Wise</h5>

                                {/*<ResponsiveContainer width="100%" height={400}>
                                    <BarChart data={fcData} barGap={15} barCategoryGap={25} margin={{ left: 30 }}>
                                        <XAxis dataKey="name"/>
                                        <YAxis domain={[0, 'dataMax + 1000000000']}/> 
                                        <Tooltip/>
                                        <Legend/>
                                            <Bar dataKey="asset_value" fill="#8884d8" barSize={30} name="Cost">
                                            {fcData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS_HARI[index % COLORS_HARI.length]} />
                                            ))}
                                            <LabelList dataKey="asset_value" position="top" formatter={(value) => `INR ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>*/}
                                <CanvasJSChart options = {fc_bar_options} />
                            </div>
                        </div>
                    </div>
                    </>):(<></>)}  
                </div>

                <div className="row gx-lg-5 mt-3">
                    {(aSub_Module_Id.indexOf('117') > -1) ? (<>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{textAlign: 'center'}}>Allocable VS Non-Allocable</h5>
                                {/*<ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie dataKey="value"
                                             data={allocableData}
                                             cx={200}
                                             cy={150}
                                             outerRadius={80}
                                             fill="#8884d8"
                                             label
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`}
                                                      fill={COLORS_HARI[index % COLORS_HARI.length]}/>
                                            ))}
                                        </Pie>

                                        <Tooltip/>
                                        <Legend/>
                                    </PieChart>
                                </ResponsiveContainer>*/}
                                <CanvasJSChart options = {allocable_options} />
                            </div>
                        </div>
                    </div>
                    </>):(<></>)} 
                    {(aSub_Module_Id.indexOf('118') > -1) ? (<>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{textAlign: 'center'}}>Active Usage of Allocable Assets</h5>
                                {/*<ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie data={allocableData2} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                             innerRadius={50} outerRadius={70} fill="#82ca9d" label>
                                            {
                                                allocableData2.map((entry, index) => (
                                                    <Cell key={`cell-${index}`}
                                                          fill={COLORS_HARI[index % COLORS_HARI.length]}/>
                                                ))
                                            }
                                        </Pie>
                                        <Tooltip/>
                                        <Legend align="center" verticalAlign="bottom" layout="horizontal"/>
                                    </PieChart>
                                </ResponsiveContainer>*/}
                                <CanvasJSChart options = {allocable1_options} />
                            </div>

                        </div>
                    </div>
                    </>):(<></>)} 
                    {(aSub_Module_Id.indexOf('119') > -1) ? (<>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{textAlign: 'center'}}>Capex Vs Opex</h5>
                                {/*<ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie dataKey="value"
                                             data={capexData}
                                             cx={200}
                                             cy={150}
                                             outerRadius={80}
                                             fill="#8884d8"
                                             label
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`}
                                                      fill={COLORS_HARI[index % COLORS_HARI.length]}/>
                                            ))}
                                        </Pie>

                                        <Tooltip/>
                                        <Legend/>
                                    </PieChart>
                                </ResponsiveContainer>*/}
                                <CanvasJSChart options = {capex_options} />
                            </div>
                        </div>
                    </div>
                    </>):(<></>)} 
                </div>


            </div>
        </div>
    );
}

export default Dashboard1