import React, { useState,useEffect,useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer  } from '@mui/x-data-grid';
import { getBranchTransferList } from '../../../services/transfer.service';
import  * as CryptoJS  from "crypto-js";


function getFormatedDate(dateString) {
  if(dateString!=null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function BranchTransferList() {

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  
  
  const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"headerName":"Sl No","width":50},
    {"field":"tag","hide":false ,"headerName":"TAG","width":250},
    {"field":"cat_of_flag","hide":false ,"headerName":"Cat of Flag","width":280},
    {"field":"remarks","hideable":true,"hide":true ,"headerName":"Remarks","width":250},
    {"field":"purpose_of_grouping","hide":false ,"headerName":"Purpose","width":130},
    {"field":"existing_ownership","hide":false ,"headerName":"Existing Ownership","width":130},
    {"field":"new_ownership","hide":false ,"headerName":"New Ownership","width":130},
    
    {"field":"created_at","hide":false ,"headerName":"Created Date","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
      ) },
  ];
{/*{"field":"asset_image","hide":false ,"headerName":"Action","width":200,
    renderCell: (params) => (
      <div><a href="#" onClick={() => handleAccept(params.row.id) } >Accept</a> / <a href="#/" onClick={() => handleReject(params.row.id) } >Reject</a></div>
    ) },*/}
function CustomToolbar() {
  return (
    <GridToolbarContainer>
    </GridToolbarContainer>
  );
}
const [items, setItems] = useState([]);
  useEffect(()=>{
    let isMounted = true;
    const fetchData = async () => {
      try {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':{'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        const res = await getBranchTransferList(oData);
        /*getBranchTransferList(oData).then(res=>{
          if(res?.data?.data?.length) setItems(res.data.data);
        });*/
        if (isMounted && res?.data?.data?.length) {
          setItems(res.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  
    // Cleanup function to handle unmounting
    return () => {
      isMounted = false;
    };
  },[])
  
  // readAssets();
  return (
    <div className='container' >
      <div className='col'><h5> Branch Transfer List </h5></div>
      
      {success ? (<div className="alert alert-success" role="alert">
        <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
          <div className="alert alert-danger" role="alert">
            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
      )}
      

      <DataGrid
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={50}
        //height={500}
        autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  )
}

export default BranchTransferList
