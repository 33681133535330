import React, {useCallback, useEffect, useState} from 'react';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import {getAssets, getAssetsCSV} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import './AssetInfo.css';
import {Box, FormControl, InputLabel, MenuItem, Pagination, Select} from '@mui/material';
import {Parser} from "json2csv";
import FromFieldDate from "../../../atoms/FromFieldDate";

import AWS from 'aws-sdk';
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

Date.prototype.dbFormatedDate = function () {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
};

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}
const AssetList = () => {
const columns = [
    {field: "sl_no", hideable: true, hide: true, editable: false, headerName: "Sl No", width: 50},
    {
        field: 'tag',
        editable: false,
        hide: false,
        headerName: 'TAG',
        width: 280,
        renderCell: (params) => {
            let encryptedTag = CryptoJS.AES.encrypt(JSON.stringify({'tag': params.value}), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (
                <a href={`/asset-details?tag=${encryptedTag}`}>{params.value}</a>
            );
        }
    },
    {field: "parent_tag", hideable: true, hide: false, editable: true, headerName: "Parent TAG", width: 280},
    {
        field: "year_of_addition",
        hideable: true,
        hide: false,
        editable: true,
        headerName: "Year of Addition",
        width: 130
    },
    {field: "type_of_addition", editable: true, hide: false, headerName: "Type of Addition", width: 280},
    {field: "project_marking", editable: true, hide: true, headerName: "Project Marking", width: 180},
    {field: "po_no", editable: true, hide: true, headerName: "PO No (If Any)", width: 180},
    {field: "supplier_code", editable: true, hide: true, headerName: "Supplier Code (if Any)", width: 180},
    {field: "supplier_name", editable: true, hide: false, headerName: "Name of the Supplier", width: 280},
    {field: "invoice_no", editable: true, hide: false, headerName: "Invoice No", width: 180},
    {
        field: "invoice_date", editable: true, hide: false, headerName: "Invoice Date", width: 130,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.invoice_date)}</p>
        )
    },
    {field: "boe_no", hideable: true, hide: true, editable: true, headerName: "Bill of Entry No", width: 180},
    {
        field: "boe_date", editable: true, hide: true, headerName: "Date of BOE", width: 130,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.boe_date)}</p>
        )
    },
    {
        field: "shipping_awb_no",
        hideable: true,
        hide: true,
        editable: true,
        headerName: "Shipping / AWB No",
        width: 180
    },
    {
        field: "shipping_awb_date", editable: true, hide: true, headerName: "Date of Shipping/AWB", width: 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.shipping_awb_date)}</p>
        )
    },
    {field: "accounting_voucher_no", editable: false, hide: true, headerName: "Accounting voucher no", width: 180},
    {field: "internal_approval_no", editable: false, hide: true, headerName: "Internal approval no", width: 180},
    {field: "cost_center_code", editable: false, hide: true, headerName: "Cost center code", width: 130},
    {field: "cost_center_name", editable: false, hide: true, headerName: "Cost center name", width: 130},
    {field: "invoice_currency", editable: false, hide: false, headerName: "Invoice Currency", width: 130},
    {field: "exchange_rate", editable: false, hide: true, headerName: "Exchange Rate", width: 130},
    {field: "invoice_value_fx", editable: false, hide: true, headerName: "Basic Cost p.u in Fx", width: 180},
    {field: "invoice_value_inr", editable: false, hide: true, headerName: "Basic Cost p.u in INR", width: 180},
    {field: "forwarding_and_insurance", editable: false, hide: true, headerName: "Forwarding & Insurance", width: 180},
    {field: "installation", editable: false, hide: true, headerName: "Installation", width: 130},
    {field: "errection", editable: false, hide: true, headerName: "Erection", width: 130},
    {field: "customs", editable: false, hide: true, headerName: "Customs", width: 130},
    {field: "cgst", editable: false, hide: true, headerName: "CGST", width: 130},
    {field: "sgst", editable: false, hide: true, headerName: "SGST", width: 130},
    {field: "igst", editable: false, hide: true, headerName: "IGST", width: 130},
    {field: "other_taxes", editable: false, hide: true, headerName: "Other Taxes", width: 130},
    {field: "total_tax", editable: false, hide: true, headerName: "Total Tax p.u", width: 130},
    {field: "value_of_asset", editable: false, hide: false, headerName: "Total cost with tax p.u", width: 200},
    {field: "qty", editable: false, hide: false, headerName: "Qty", width: 130},
    {
        field: "total_cost_without_tax",
        editable: false,
        hide: false,
        headerName: "Total Cost Without Tax p.u",
        width: 220,
        renderCell: (params) => (
            <p>{params.row.total_cost_without_tax}</p>
        )
    },
    {field: "tax", editable: false, hide: true, headerName: "Tax", width: 130},
    {field: "asset_name", editable: false, hide: false, headerName: "Name of the Asset (as per Invoice)", width: 250},
    {
        field: "asset_description",
        editable: false,
        hide: false,
        headerName: "Detailed Description of the Asset",
        width: 250
    },
    {field: "asset_serial_no", editable: false, hide: true, headerName: "Unique Sl No of the Asset", width: 200},
    {field: "manufacturer", editable: false, hide: true, headerName: "Make / Manufacturer", width: 180},
    {field: "model_no", editable: false, hide: true, headerName: "Model No", width: 130},
    {field: "part_no", editable: false, hide: true, headerName: "Part No", width: 130},
    {field: "nature_of_presence", editable: true, hide: false, headerName: "Nature of Presence", width: 180},
    {field: "nature_of_addition", editable: true, hide: false, headerName: "Nature of Addition", width: 180},
    {
        field: "principal_asset_tag",
        editable: true,
        hide: false,
        headerName: "Principal Asset to which Cost is Added",
        width: 280
    },
    {field: "tag_name", editable: false, hide: true, headerName: "Asset TAG Name", width: 280},
    {field: "tag_division", editable: false, hide: true, headerName: "Asset TAG Branch", width: 180},
    {field: "department_name", editable: true, hide: false, headerName: "Control Department Name", width: 180},
    {field: "unit_of_measure", editable: false, hide: false, headerName: "Unit of Measure", width: 130},
    {
        field: "security_seal_date", editable: false, hide: true, headerName: "Security Seal Date", width: 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.security_seal_date)}</p>
        )
    },
    {field: "comp_ref_no", editable: false, hide: true, headerName: "Comp Ref. No.", width: 230},
    {"field":"thumbnail","editable":false,"hide":false ,"headerName":"Thumbnail","width":120,
        renderCell: (params) => (
          <div >
            { params.row.asset_image && params.row.asset_image ? (
              <img onClick={() => handlePreviewImage(params.row.asset_image,"Front") }
                src={getSignedUrl('image/thumbnails/'+params.row.asset_image)} // Thumbnail URL
                style={{ width: 50, height: 50, objectFit: "cover", borderRadius: "4px" }} // Styling for thumbnail
              />
            ) : (
              <span>No Image</span> // Fallback if no thumbnail exists
            ) }
            </div>
        ) 
      },
    {
        field: "created_at", editable: false, hide: false, headerName: "Created Date", width: 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.created_at)}</p>
        )
    },
];
const getSignedUrl = (key) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: key,
      Expires: 60, // URL validity in seconds
    };
    return s3.getSignedUrl("getObject", params);
  };
/* const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport/>
    </GridToolbarContainer>
);*/

const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

    const [items, setItems] = useState([]);
    const [itemsCSV, setItemsCSV] = useState([]);

    const [searchTag, setSearchTag] = useState('');
    const [ErrSearchTag, setErrSearchTag] = useState(false);

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [filterCriteria, setFilterCriteria] = useState('');
    const [ErrFilterCriteria, setErrFilterCriteria] = useState(false);

    const [filterOperator, setFilterOperator] = useState('');
    const [ErrFilterOperator, setErrFilterOperator] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [ErrStartDate, setErrStartDate] = useState(false);

    const [endDate, setEndDate] = useState('');
    const [ErrEndDate, setErrEndDate] = useState(false);

    const [previewImage, setPreviewImage] = useState(null); // Image URL
    const [showModal, setShowModal] = useState(false);  // Modal visibility
    const [head_title, setHeadTitle] = useState('');     
    const [imageName, setImageName] = useState('');
    const handlePreviewImage = async (imageId,headtitle) => {
        try {
            const key = 'image/' + imageId;
            setShowModal(true);
            setHeadTitle(headtitle);
            setImageName(imageId);
            s3.getObject({
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: key,
            }, (err, data) => {
                if (err) {
                    console.error('Error fetching image:', err);
                } else {
                    const url = URL.createObjectURL(new Blob([data.Body]));
                    setPreviewImage(url); // Set the URL to a state variable for the modal
                    setShowModal(true);   // Open the modal
                }
            });
        } catch (error) {
            console.error('Error previewing image:', error);
        }
    };
    const handleDownloadFile = async (id, imageId) => {
        // Use the S3 getObject method to get the image and initiate the download
        console.log('imageId'+imageId);
        imageId = 'image/'+imageId;
        s3.getObject({
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: imageId, //imageId,
        }, (err, data) => {
            if (err) {
                console.error('Error downloading image:', err);
            } else { console.log(data.Body);
                const url = URL.createObjectURL(new Blob([data.Body]));
                const a = document.createElement('a');
                a.href = url;
                a.download = imageId;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        });
    };
    
    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchAssets = useCallback(async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: {'offset': offset, 'pageSize': pageSize},
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = {token: encryptedData};

        try {
            const res = await getAssets(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    }, [page, pageSize]);

    const fetchAssets1 = async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: {
                'offset': offset,
                'pageSize': pageSize,
                'searchTag': searchTag,
                'filterCriteria': filterCriteria,
                'filterOperator': filterOperator,
                'startDate': getFormatedDate(startDate),
                'endDate': getFormatedDate(endDate)
            },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = {token: encryptedData};

        try {
            const res = await getAssets(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    };

// Call this function whenever you need to fetch assets, such as inside a useEffect hook or in response to an event


    const fetchAssetsCSV = useCallback(async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify({
                param_data: {
                    offset,
                    pageSize,
                    searchTag,
                    filterCriteria,
                    filterOperator,
                    startDate: getFormatedDate(startDate),
                    endDate: getFormatedDate(endDate),
                },
                authToken: sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();

        const requestData = {token: encryptedData};

        try {
            const resCSV = await getAssetsCSV(requestData);
            if (resCSV?.data?.data?.length) {
                setItemsCSV(resCSV.data.data);
            }
        } catch (error) {
            console.error('Error fetching assets CSV:', error);
        } finally {
            setLoading(false);
        }
    }, [filterCriteria, filterOperator, searchTag, startDate, endDate, page, pageSize]);

    useEffect(() => {
        fetchAssets();
        //fetchAssets1();
    }, [fetchAssets]);

    useEffect(() => {
        if (itemsCSV.length > 0) {
            generateCSV();
        }
    }, [itemsCSV]);

    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    const handleExportToCSV = async () => {
        await fetchAssetsCSV();
    };

    const generateCSV = () => {
        try {
            const jsonData = itemsCSV.map(item => ({
                tag: item.tag ? item.tag : '',
                parent_tag: item.parent_tag ? item.parent_tag : '',
                year_of_addition: item.year_of_addition ? item.year_of_addition : '',
                type_of_addition: item.type_of_addition ? item.type_of_addition : '',
                project_marking: item.project_marking ? item.project_marking : '',
                po_no: item.po_no ? item.po_no : '',
                supplier_code: item.supplier_code ? item.supplier_code : '',
                supplier_name: item.supplier_name ? item.supplier_name : '',
                invoice_no: item.invoice_no ? item.invoice_no : '',
                invoice_date: item.invoice_date ? getFormatedDate(item.invoice_date) : '',
                boe_no: item.boe_no ? item.boe_no : '',
                boe_date: item.boe_date ? getFormatedDate(item.boe_date) : '',
                shipping_awb_no: item.shipping_awb_no ? item.shipping_awb_no : '',
                shipping_awb_date: item.shipping_awb_date ? getFormatedDate(item.shipping_awb_date) : '',
                accounting_voucher_no: item.accounting_voucher_no ? item.accounting_voucher_no : '',
                internal_approval_no: item.internal_approval_no ? item.internal_approval_no : '',
                cost_center_code: item.cost_center_code ? item.cost_center_code : '',
                cost_center_name: item.cost_center_name ? item.cost_center_name : '',
                invoice_currency: item.invoice_currency ? item.invoice_currency : '',
                exchange_rate: item.exchange_rate ? item.exchange_rate : '',
                invoice_value_fx: item.invoice_value_fx ? item.invoice_value_fx : '',
                invoice_value_inr: item.invoice_value_inr ? item.invoice_value_inr : '',
                forwarding_and_insurance: item.forwarding_and_insurance ? item.forwarding_and_insurance : '',
                installation: item.installation ? item.installation : '',
                errection: item.errection ? item.errection : '',
                customs: item.customs ? item.customs : '',
                cgst: item.cgst ? item.cgst : '',
                sgst: item.sgst ? item.sgst : '',
                igst: item.igst ? item.igst : '',
                other_taxes: item.other_taxes ? item.other_taxes : '',
                total_tax: item.total_tax ? item.total_tax : '',
                value_of_asset: item.value_of_asset ? item.value_of_asset : '',
                qty: item.qty ? item.qty : '',
                total_cost_without_tax: item.total_cost_without_tax ? item.total_cost_without_tax : '',
                tax: item.tax ? item.tax : '',
                asset_name: item.asset_name ? item.asset_name : '',
                asset_description: item.asset_description ? item.asset_description : '',
                asset_serial_no: item.asset_serial_no ? item.asset_serial_no : '',
                manufacturer: item.manufacturer ? item.manufacturer : '',
                model_no: item.model_no ? item.model_no : '',
                part_no: item.part_no ? item.part_no : '',
                nature_of_presence: item.nature_of_presence ? item.nature_of_presence : '',
                nature_of_addition: item.nature_of_addition ? item.nature_of_addition : '',
                principal_asset_tag: item.principal_asset_tag ? item.principal_asset_tag : '',
                tag_name: item.tag_name ? item.tag_name : '',
                tag_division: item.tag_division ? item.tag_division : '',
                department_name: item.department_name ? item.department_name : '',
                unit_of_measure: item.unit_of_measure ? item.unit_of_measure : '',
                security_seal_date: item.security_seal_date ? getFormatedDate(item.security_seal_date) : '',
                comp_ref_no: item.comp_ref_no ? item.comp_ref_no : '',
                created_at: item.created_at ? getFormatedDate(item.created_at) : '',
                // Add more fields as needed based on your item structure
            }));

            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(jsonData);

            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'All_Asset_List.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting data to CSV:', error);
        }
    };

    const handleSearch = () => {
        let isValid = true;
        if (!filterCriteria) {
            setErrFilterCriteria('Please select a filter criteria');
            isValid = false;
        } else {
            setErrFilterCriteria('');
        }

        if (filterCriteria !== 'created_at' && !filterOperator) {
            setErrFilterOperator('Please select a filter operator');
            isValid = false;
        } else {
            setErrFilterOperator('');
        }

        if (!searchTag && filterCriteria != 'created_at') {
            setErrSearchTag('Please enter a search text');
            isValid = false;
        } else {
            setErrSearchTag('');
        }
        if (!startDate && filterCriteria == 'created_at') {
            setErrStartDate('Please select start date');
            isValid = false;
        } else {
            setErrStartDate('');
        }
        if (!endDate && filterCriteria == 'created_at') {
            setErrEndDate('Please select end date');
            isValid = false;
        } else {
            setErrEndDate('');
        }

        if (isValid) {
            setPage(1);
            fetchAssets1();
        }

    };

    // Define options and values
    const options = [
        "TAG Number", "Year Of Addition", "Type of Addition", "PO Number", "Supplier Name", "Invoice Number", "Asset Name", "Make", "Model Number", "Asset Serial Number", "Part Number", "TAG Name", "Nature of Presence", "Date Range"
    ];

    const values = [
        "tag", "year_of_addition", "type_of_addition", "po_no", "supplier_name", "invoice_no", "asset_name", "manufacturer", "model_no", "asset_serial_no", "part_no", "tag_name", "nature_of_presence", "created_at"
    ];

    // Combine options and values into an array of objects
    const combinedOptions = options.map((option, index) => ({
        label: option,
        value: values[index]
    }));

    // Handle change event
    const handleSelectChange = (e) => {
        const selectedOption = combinedOptions.find(option => option.value === e.target.value);
        setFilterCriteria(selectedOption ? selectedOption.value : '');
        setErrFilterCriteria(''); // Reset error message on change
    };

    const optionsFilter = [
        "Contains", "Equals", "Not Contains", "Not Equals", "Blank", "Non Blank"
    ];

    const valuesFilter = [
        "LIKE", "=", "NOT LIKE", "!=", "IS NULL", "IS NOT NULL"
    ];

    // Combine options and values into an array of objects
    const combinedOptionsFilter = optionsFilter.map((option, index) => ({
        label: option,
        value: valuesFilter[index]
    }));

    // Handle operator change event
    const handleOperatorChange = (e) => {
        setFilterOperator(e.target.value);
        setErrFilterOperator(''); // Reset error message on change
    };
    const handelSearchText = (e) => {
        setSearchTag(e.target.value);
    };


    return (
        <div className='container'>
            <div className='col'><h5> All Asset List </h5></div>
            <div className='filter-container' id="single-asset">
                <div className='row m-2'>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>
                                Select Criteria <span className="star error">*</span>
                            </label>
                            <select
                                className="form-control"
                                value={filterCriteria}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>Select Filter Criteria</option>
                                {combinedOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {ErrFilterCriteria && <span className="invalid-feedback">{ErrFilterCriteria}</span>}
                        </div>
                    </div>

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>
                                    Select Operator <span className="star error">*</span>
                                </label>
                                <select
                                    className="form-control"
                                    value={filterOperator}
                                    onChange={handleOperatorChange}
                                >
                                    <option value="" disabled>Select Operator</option>
                                    {combinedOptionsFilter.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {ErrFilterOperator && <span className="invalid-feedback">{ErrFilterOperator}</span>}
                            </div>
                        </div>
                    )}

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    Enter Criteria <span className="star error">*</span>
                                </label>

                                <input
                                    type="text" className="form-control"
                                    placeholder="Search..."
                                    inputValue={searchTag}
                                    //setInputValue={setSearchTag}
                                    onChange={handelSearchText}
                                    style={{marginRight: '10px'}}
                                />
                                {ErrSearchTag && <span className="invalid-feedback">{ErrSearchTag}</span>}
                            </div>
                        </div>
                    )}
                </div>
                {filterCriteria === 'created_at' && (
                    <div className="row m-2">
                        <div className="col-6">
                            <div className="form-group">
                                <FromFieldDate
                                    label="Start Date"
                                    placeholder="Select Start Date"
                                    inputValue={startDate}
                                    setInputValue={setStartDate}
                                    setErrorMsg={ErrStartDate}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <FromFieldDate
                                    label="End Date"
                                    placeholder="Select End Date"
                                    inputValue={endDate}
                                    setInputValue={setEndDate}
                                    setErrorMsg={ErrEndDate}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="row mt-2">
                    <div className="col-6 text-center">
                        <button onClick={handleSearch} className='btn btn-info btn-md'>
                            <i className="bi bi-search"></i> Search
                        </button>

                    </div>
                    <div className="col-6 text-center">
                        <button
                            onClick={handleExportToCSV}
                            className='btn btn-secondary btn-md'
                            disabled={loading}
                        >
                            <i className="bi bi-download"></i> {loading ? 'Exporting...' : 'Export All Data to CSV'}
                        </button>
                    </div>
                </div>


            </div>

                
            <Box>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.sl_no}
                    columns={columns}
                    autoHeight
                    components={{
                        LoadingOverlay: CustomLoader,
                        Pagination: () => (
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                <Box sx={{mr: 4}}>
                                    Showing {items.length} records out of {totalItems}
                                </Box>
                                <FormControl variant="outlined" sx={{minWidth: 120}}>
                                    <InputLabel>Rows per page</InputLabel>
                                    <Select
                                        value={pageSize}
                                        onChange={(event) => handlePageSizeChange(Number(event.target.value))}
                                        label="Rows per page"
                                    >
                                        {[10, 25, 50, 100, 200, 500].map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />

                            </Box>
                        ),
                    }}
                    loading={loading}
                />
            </Box>

            {/* Modal */}
            {showModal && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            
                        }}
                        onClick={() => setShowModal(false)} // Close on background click
                    >
                        <div
                            style={{
                                backgroundColor: '#fff',
                                padding: '20px',
                                borderRadius: '8px',
                                position: 'relative',
                                minWidth: '500px',
                            }}
                            onClick={(e) => e.stopPropagation()} // Prevent modal close on content click
                        > <h5>{head_title} Preview Image</h5> 

                            <img
                                src={previewImage}
                                alt="Preview"
                                style={{ maxWidth: '100%', maxHeight: '80vh' }}
                            />
                            <button className="btn btn-secondary btn-sm"
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '50px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleDownloadFile(imageName,'1')}
                            ><i class="bi bi-download"></i> Download </button>
                            <button className="btn btn-danger btn-sm"
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowModal(false)}
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default AssetList;
