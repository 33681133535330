import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridOverlay, GridToolbarContainer} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import {getMadeTransferList} from '../../../services/transfer.service';
import AssetListPopupBox from '../../../atoms/AssetListPopupBox';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function TransferMadeListing() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tinNo, setTinNo] = useState('');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const columns = [{
        "field": "sl_no",
        "hideable": true,
        "hide": true,
        "editable": false,
        "headerName": "Sl No",
        "width": 50
    }, {
        "field": "tin_no",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "TIN No",
        "width": 180,
        renderCell: (params) => (<div>
                <a class="text-right" onClick={() => handleConfirmOpen(params.row.tin_no)}>{params.row.tin_no}</a>
            </div>)
    },
    {
        "field": "transfer", "hideable": true, "hide": false, "editable": false, "headerName": "Transport", "width": 250,
        renderCell: (params) => {
            let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                'tin_no': params.row.tin_no,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (<a href={`/update-transport-details?tin_no=${EncryptID}`} className='btn btn-info btn-sm'>Update Transport</a>)
        }
    },

        {
            "field": "tag_count",
            "editable": false,
            "hide": false,
            "headerName": "No. of TAG",
            "width": 100
        }, /*{"field": "asset_name", "editable": false, "hide": false, "headerName": "Asset Name", "width": 280}, {
            "field": "status",
            "editable": true,
            "hide": false,
            "headerName": "Allocation Status",
            "width": 120,
            renderCell: (params) => (<div>{(params.row.status == '1') ? (
                    <span class="btn-warning p-1">Pending</span>) : ''}{(params.row.status == '2') ? (
                    <span class="btn-danger p-1">Rejected</span>) : ''}{(params.row.status == '5') ? (
                    <span class="btn-success p-1">Accepted</span>) : ''} &nbsp; </div>)
        }, {"field": "created_by", "editable": true, "hide": false, "headerName": "Invoice No.", "width": 250}, {
            "field": "created_at",
            "editable": true,
            "hide": false,
            "headerName": "Invoice Date",
            "width": 180,
            renderCell: (params) => (<p>{getFormatedDate(params.row.created_at)}</p>)
        },*/ {
            "field": "cat_of_flag",
            "editable": true,
            "hide": false,
            "headerName": "CAT",
            "width": 280
        }, {
            "field": "remarks",
            "hideable": true,
            "hide": true,
            "editable": true,
            "headerName": "Remarks",
            "width": 250
        }, {
            "field": "purpose_of_grouping",
            "editable": true,
            "hide": false,
            "headerName": "Purpose",
            "width": 180
        }, {
            "field": "division_name",
            "editable": true,
            "hide": false,
            "headerName": "Transfered Division From",
            "width": 280
        },{
            "field": "existing_ownership",
            "editable": true,
            "hide": false,
            "headerName": "Transfered Department From",
            "width": 280
        }, {
            "field": "department_name",
            "editable": true,
            "hide": false,
            "headerName": "Transfered Division To",
            "width": 280
        }, {
            "field": "new_ownership",
            "editable": true,
            "hide": false,
            "headerName": "Transfered Department To",
            "width": 280
        }, {
            "field": "flag_accepted_date",
            "editable": true,
            "hide": false,
            "headerName": "Date of Flagging",
            "width": 180,
            renderCell: (params) => (<p>{getFormatedDate(params.row.flag_accepted_date)}</p>)
        }, {
            "field": "id",
            "editable": false,
            "hide": false,
            "headerName": "Action",
            "width": 200,
            renderCell: (params) => (<div>{(params.row.transfer_status == '1') ? (
                <span class="btn-btn-warning p-1">Initiated</span>) : ''}{(params.row.transfer_status == '2') ? (
                <span class="btn-btn-success p-1">Completed</span>) : ''}</div>)
        }, /*{
            "field": "mode_of_transport",
            "editable": false,
            "hide": false,
            "headerName": "Mode of Transport",
            "width": 180
        }, {
            "field": "transport_name",
            "editable": true,
            "hide": false,
            "headerName": "Transporter Name",
            "width": 180
        }, {
            "field": "shipping_doc_no",
            "hideable": true,
            "hide": false,
            "editable": false,
            "headerName": "AWB/Shipping Doc No",
            "width": 180
        }, {
            "field": "shipping_date",
            "editable": true,
            "hide": false,
            "headerName": "Date of Shipping",
            "width": 180,
            renderCell: (params) => (<p>{getFormatedDate(params.row.shipping_date)}</p>)
        }, {
            "field": "contact_person",
            "editable": true,
            "hide": false,
            "headerName": "Contact Person",
            "width": 180
        }, {"field": "contact_number", "editable": true, "hide": false, "headerName": "Contact Number", "width": 180},

        {
            "field": "receiver_of_consignment",
            "editable": true,
            "hide": false,
            "headerName": "Receiver of Consignment",
            "width": 200
        }, {
            "field": "receiver_mail",
            "editable": true,
            "hide": false,
            "headerName": "Receiver Mail",
            "width": 180
        }, {
            "field": "receiver_mobile",
            "editable": true,
            "hide": false,
            "headerName": "Receiver mobile",
            "width": 180
        }, */

    ];
    const handleConfirmOpen = async (tin_no) => {
        setTinNo(tin_no);
        setIsConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setIsConfirmOpen(false);
    };
    const CustomLoader = () => (<GridOverlay>
            <CircularProgress color="primary" size={30}/>
        </GridOverlay>);

    function CustomToolbar() {
        return (<GridToolbarContainer>
            </GridToolbarContainer>);
    }

    const [items, setItems] = useState([]);
    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data': {'new_ownership_id': sessionStorage.getItem('LoggedInDepartmentId')},
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                let oData = {'token': EncryptData}
                const res = await getMadeTransferList(oData);
                /*getBranchTransferList(oData).then(res=>{
                  if(res?.data?.data?.length) setItems(res.data.data);
                });*/
                if (isMounted && res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        // Cleanup function to handle unmounting
        return () => {
            isMounted = false;
        };
    }, [])

    // readAssets();
    return (<div className='container'><div className='col'><h5> Transfer Made Listing </h5></div>
            {success ? (<div className="alert alert-success" role="alert">
                <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
                    <div className="alert alert-danger" role="alert">
                        <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : (''))}


                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}

                    //height={500}
                    autoHeight
                    //stickyHeaders={true}
                    rowsPerPageOptions={[50]}
                    components={{
                        LoadingOverlay: CustomLoader, Toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    // initialState={{
                    //     pinnedColumns: { left: ['tag'] },
                    //   }}
                />
            <AssetListPopupBox
                isTinNo={tinNo}
                isOpen={isConfirmOpen}
                onClose={handleConfirmClose}
            />
        </div>)
}

export default TransferMadeListing
