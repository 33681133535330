import React, {useEffect, useState} from 'react';
import {getDashboardUserCount, getUserLogData, getUsersList} from '../../services/asset.service';
import {getCompanyProfileList, getDivisionList, getDepartmentList} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import Select from "react-select";
import FromFieldDate from "../../atoms/FromFieldDate";
import './Dashboard.css';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";

const getFormattedDate1 = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        throw new Error("Invalid date value");
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0');        // Add leading zero if needed
    return `${year}-${month}-${day}`;
};

function getFormatedDate(dateString) {
    if (dateString !== null) {
        let dte = new Date(dateString);

        // Format date
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;

        // Format time
        let hours = dte.getHours();
        let minutes = dte.getMinutes();
        let seconds = dte.getSeconds();
        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else {
        return null;
    }
}


const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport/>
    </GridToolbarContainer>
);

function UserDashboard() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [activeUsers, setActiveUsers] = useState([]);
    const [inActiveUsers, setInActiveUsers] = useState([]);

    const [fromDate, setFromDate] = useState('');
    const [ErrFromDate, setErrFromDate] = useState(false);

    const [toDate, setToDate] = useState('');
    const [ErrToDate, setErrToDate] = useState(false);

    const [usersList, setUsersListData] = useState([]);
    const [ErrUsersList, setErrUsersListData] = useState([]);
    const [userListOptions, setUsersListOptions] = useState();


    const [companyId, setCompanyId] = useState('');
    const [companies, setCompanies] = useState([]);
    const [ErrCompanyId, setErrCompanyId] = useState(false);
    const [divisions, setDivisions] = useState([]);
    const [ErrDivisionId, setErrDivisionId] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(null);

    const [department, setDepartment] = useState('');
    const [ErrDepartment, setErrDepartment] = useState(false);
    const [departments, setDepartments] = useState([]);

    const columns = [

        {"field": "event", "hide": false, "headerName": "Event", "width": 200},
        {"field": "auditable_type", "hide": false, "headerName": "Application", "width": 250},
        {
            "field": "created_at", "hide": false, "headerName": "Login At", "width": 230,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },
    ];

    useEffect(() => {
        const authToken = sessionStorage.getItem('LoggedInToken');
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({authToken}), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        // Fetch Active and Inactive User Count
        getDashboardUserCount({token: EncryptData}).then(res => {
            const data = res?.data?.data?.[0];
            if (data) {
                setActiveUsers(data.count_active_users || 0);
                setInActiveUsers(data.count_inactive_users || 0);
            }
        }).catch(console.error);

        // Fetch Company List
        getCompanyProfileList({token: EncryptData}).then(res => {
            setCompanies(res?.data?.data || []);
        }).catch(console.error);

        if(sessionStorage.getItem("LoggedInCompanyId") > 0 ){

            setCompanyId(sessionStorage.getItem("LoggedInCompanyId"));
            const EncryptData = CryptoJS.AES.encrypt(
                JSON.stringify({
                    param_data: { company_id: companyId },
                    authToken: sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            getDivisionList({ token: EncryptData })
                .then((res) => {
                    setDivisions(res?.data?.data || []);
                })
                .catch(console.error);
            getDepartmentList({ token: EncryptData })
                .then((res) => {
                    setDepartments(res?.data?.data || []);
                })
                .catch(console.error);
        }

    }, []);

    const handleCompanyChange = (e) => {
        const companyId = e.target.value;
        setCompanyId(companyId);

        // Reset division and user data when the company changes
        setSelectedDivision(null);
        setUsersListOptions(null);
        setDivisions([]);
        setUsersListData([]);

        if (companyId) {
            const EncryptData = CryptoJS.AES.encrypt(
                JSON.stringify({
                    param_data: { company_id: companyId },
                    authToken: sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            getDivisionList({ token: EncryptData })
                .then((res) => {
                    setDivisions(res?.data?.data || []);
                })
                .catch(console.error);
            getDepartmentList({ token: EncryptData })
                .then((res) => {
                    setDepartments(res?.data?.data || []);
                })
                .catch(console.error);
        }
    };
    const handleDepartment = (selectedOption) => {
        if (selectedOption === null) {
            setDepartment(''); // Clear the selection
        } else {
            setDepartment(selectedOption.value); // Set the department ID from the selected option
        }
    };

    const handleDivisionChange = (division) => {
        // Reset selected user list and clear existing data
        setUsersListOptions(null);
        setUsersListData([]); // Reset the user list when division changes

        setSelectedDivision(division);

        if (division?.value) {
            const EncryptData = CryptoJS.AES.encrypt(
                JSON.stringify({
                    param_data: { company_id: companyId, division_id: division.value, department_id: department },
                    authToken: sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            // Fetch Users List
            getUsersList({ token: EncryptData })
                .then((res) => {
                    setUsersListData(res?.data?.data || []);
                })
                .catch((error) => {
                    console.error("Error fetching user list:", error.message);
                    setUsersListData([]); // Clear data on error
                });

            // Fetch Active and Inactive User Count
            const EncryptDataForCount = CryptoJS.AES.encrypt(
                JSON.stringify({
                    param_data: { company_id: companyId, division_id: division.value, department_id: department },
                    authToken: sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            getDashboardUserCount({ token: EncryptDataForCount })
                .then((res) => {
                    const data = res?.data?.data?.[0];
                    if (data) {
                        setActiveUsers(data.count_active_users || 0);
                        setInActiveUsers(data.count_inactive_users || 0);
                    }
                })
                .catch(console.error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let valid = true;

        // Validate Company
        if (!companyId) {
            setErrCompanyId('Company name is required');
            valid = false;
        } else {
            setErrCompanyId('');
        }

        // Validate Division
        if (!selectedDivision || !selectedDivision.value) {
            setErrDivisionId('Division name is required');
            valid = false;
        } else {
            setErrDivisionId('');
        }


        // Validate User List
        if (!userListOptions || !userListOptions.value) {
            setErrUsersListData('User name is required');
            valid = false;
        } else {
            setErrUsersListData('');
        }

        // Validate From Date
        if (!fromDate) {
            setErrFromDate('From Date is required');
            valid = false;
        } else {
            setErrFromDate('');
        }

        // Validate To Date
        if (!toDate) {
            setErrToDate('To Date is required');
            valid = false;
        } else {
            setErrToDate('');
        }

        if (valid) {
            setItems([]); // Clear the previous data
            setLoading(true); // Show the loader

            const formattedFromDate = getFormattedDate1(fromDate);
            const formattedToDate = getFormattedDate1(toDate);

            const EncryptData = CryptoJS.AES.encrypt(
                JSON.stringify({
                    param_data: {
                        company_id: companyId,
                        division_id: selectedDivision.value,
                        department_id: department, // Send only the selected department ID
                        user_id: userListOptions.value,
                        fromDate: formattedFromDate,
                        toDate: formattedToDate
                    },
                    authToken: sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            let oData = {'token': EncryptData};
            getUserLogData(oData)
                .then((res) => {
                    setItems(res?.data?.data || []); // Update the items array with new data
                    setSuccess(true);
                })
                .catch((error) => {
                    setErrMsg(error.message);
                    setSuccess(false);
                })
                .finally(() => {
                    setLoading(false); // Hide the loader after data is loaded or an error occurs
                });

        } else {
            console.log('Validation failed');
        }
    };

    const companyOptions = companies.map(company => ({value: company.company_id, label: company.company_name}));
    const divisionOptions = divisions.map(division => ({value: division.id, label: division.division_name}));
    const departmentOptions = departments.map(department => ({
        value: department.id,
        label: department.department_name,
    }));

    const userOptions = usersList.map(user => ({value: user.id, label: user.name}));

    return (
        <form onSubmit={handleSubmit}>
            <div className="container">
                <div className="row gx-lg-5">
                    
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        <div className="card dasboard-card2">
                            <div className="card-body">
                            <div class="css-ellwb2"><span class="MuiBox-root css-j4c6pt"></span></div>
                            <div class="css-1tjwiah"><img style={{maxWidth:'100%'}} src="https://assets.minimals.cc/public/assets/icons/glass/ic-glass-users.svg"/></div>
                                <h5 className="card-title">Total In-Active Users</h5>
                                <div class="css-yi7yso"><div class="MuiBox-root css-5poqnu"></div>
                                <div class="css-y7xf7j">{inActiveUsers}</div></div>
                            </div>
                            <span class="css-hn7b8w"></span>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        <div className="card dasboard-card1">
                            <div className="card-body">
                            <div class="css-ellwb2"><span class="MuiBox-root css-j4c6pt"></span></div>
                            <div class="css-1tjwiah"><img style={{maxWidth:'100%'}} src="https://assets.minimals.cc/public/assets/icons/glass/ic-glass-users.svg"/></div>
                                <h5 className="card-title">Total Active Users</h5>
                                <div class="css-yi7yso"><div class="MuiBox-root css-5poqnu"></div>
                                <div class="css-y7xf7j">{activeUsers}</div></div>
                            </div>
                            <span class="css-hn7b8w"></span>
                        </div>
                    </div>
                </div>
                <div className="row gx-lg-5 mt-4">
                    {(sessionStorage.getItem("LoggedInCompanyId") == 0 || sessionStorage.getItem('CurrentCompanyId') > 0) ? (
                        <div className="col-lg-4">
                            <select onChange={handleCompanyChange} className="form-select form-select-sm">
                                <option value="">Select Company</option>
                                {companyOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <span className="invalid-feedback"> {ErrCompanyId} </span>
                        </div>

                    ) : (<div className="col-lg-4">{sessionStorage.getItem("LoggedInCompanyName")}</div>)}



                    {(sessionStorage.getItem("LoggedInDepartmentName") == 0 || sessionStorage.getItem('LoggedInDepartmentName') > 0) ? (
                        <div className="col-lg-4">
                            <Select
                                options={departmentOptions}
                                placeholder="Select Department"
                                value={departmentOptions.find(option => option.value === department) || null}
                                onChange={handleDepartment}
                                isClearable // Allow clearing the selection
                            />

                            {ErrDepartment && <span className="text-danger">{ErrDepartment}</span>}
                        </div>

                    ) : (<div className="col-lg-4">{sessionStorage.getItem("LoggedInDepartmentName")}</div>)}

                    {(sessionStorage.getItem("LoggedInDivisionId") == 0 || sessionStorage.getItem('LoggedInDivisionId') > 0) ? (
                        <div className="col-lg-4">
                            <Select
                                options={divisionOptions}
                                placeholder="Select Division"
                                value={selectedDivision}
                                onChange={handleDivisionChange}
                                isClearable
                            />
                            {ErrDivisionId && <span className="text-danger">{ErrDivisionId}</span>}
                        </div>

                    ) : (<div className="col-lg-4">{sessionStorage.getItem("LoggedInDivisionId")}</div>)}

                </div>
                <div className="row gx-lg-5 mt-4">

                    <div className="col-lg-4">
                        <Select
                            options={userOptions}
                            placeholder="Select User"
                            value={userListOptions}
                            onChange={setUsersListOptions}
                            isClearable
                        />
                        {ErrUsersList && <span className="text-danger">{ErrUsersList}</span>}
                    </div>

                    <div className="col-lg-4">
                        <FromFieldDate label="From Date" inputValue={fromDate} setInputValue={setFromDate}
                                       setErrorMsg={ErrFromDate}/>
                    </div>
                    <div className="col-lg-4">
                        <FromFieldDate label="To Date" inputValue={toDate} setInputValue={setToDate}
                                       setErrorMsg={ErrToDate}/>
                    </div>
                </div>

                <div className="row gx-lg-5 mt-4">
                    <div className="col-lg-12 text-center">
                        <button type="submit" className="btn btn-primary btn-sm">Search</button>
                    </div>
                </div>

            </div>

            <div className="container">
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            </div>

        </form>

    );
}

export default UserDashboard;
