import React, { useState,useEffect,useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import { getFlagAssetOut,cancelFlagAsset } from '../../../services/asset.service';
import  * as CryptoJS  from "crypto-js";


function getFormatedDate(dateString) {
  if(dateString!=null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function FlagListingOut() {

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  
  const handleBulkCancel = async (flagId) => {
    if(selectionModel.length>0){ setErrMsg('');
    //console.log('flag_id'+flagId,'flag_status'+flagId);
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data': {'flag_ids':selectionModel,'flag_status':'3'},
        'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token': EncryptData}
    try {
        //setLoading('..'); // Set loading before sending API request
        cancelFlagAsset(oData).then(function (res) {
        //setLoading(false); // Stop loading
        setSuccess(res.data.message); 
        const updatedData = items.filter((item) => !selectionModel.includes(item.id) );
          setItems(updatedData);
      });
    } catch (err) {
        setSuccess(false);
        setLoading(false); // Stop loading
        if (!err?.data1) {
            console.log(err);
            //setErrMsg('No Server Response');
            //setSuccess("Successfully saved Single Asset");
        } else if (err.data1?.status === 409) {
            //setErrMsg('Record already exist');
        } else {
            setErrMsg('Insert/Update Failed');
        }
        //elementRef.current.focus();
    }
  }else{ setErrMsg('Please select atleast one asset.')}
};
  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
  };

const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"headerName":"Sl No","width":50},
    {"field":"tag","hide":false ,"headerName":"TAG","width":250,},
    {"field":"cat_of_flag","hide":false ,"headerName":"Cat of Flag","width":230},
    {"field":"remarks","hideable":true,"hide":true ,"headerName":"Remarks","width":250},
    {"field":"purpose_of_grouping","hide":false ,"headerName":"Purpose","width":230},
    {"field":"existing_ownership","hide":false ,"headerName":"Existing Ownership","width":150},
    {"field":"new_ownership","hide":false ,"headerName":"New Ownership","width":130},
    {"field":"status","hide":false ,"headerName":"Status","width":130,
    renderCell: (params) => (
      <div>{(params.row.status=='1')?(<span class="btn-btn-warning p-1">Pending</span>):''}{(params.row.status=='2')?(<span class="btn-btn-danger p-1">Rejected</span>):''}{(params.row.status=='3')?(<span class="btn-btn-secondary p-1"> Cancel </span>):''}{(params.row.status=='5')?(<span class="btn-btn-success p-1">Accepted</span>):''} &nbsp; </div>
    )},
    {"field":"acceped_by_name","hide":false ,"headerName":"Accept By","width":130},
    {"field":"flag_accepted_date","hide":false ,"headerName":"Accept Date","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.flag_accepted_date)}</p>
      ) },
    {"field":"created_by_name","hide":false ,"headerName":"Created By","width":130},
    {"field":"created_at","hide":false ,"headerName":"Created Date","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
      ) },

    
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      
    </GridToolbarContainer>
  );
}
const [items, setItems] = useState([]);
  useEffect(()=>{
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      'param_data':{'created_by':sessionStorage.getItem('LoggedInUserId')},
      'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token':EncryptData}
    getFlagAssetOut(oData).then(res=>{ console.log(res.data.data)
      if(res?.data?.data?.length) setItems(res.data.data);
    })
  },[])
  
  // readAssets();
  return (
    <div className='container' >
      <div className='col'>
        <h5> Flag List Out </h5>
        </div>
      <div className='filter-container1 col-12'>
      <div className='col-8 mb-2'></div><div className='col-2 mb-2'>
        <button className='btn btn-secondary btn-sm text-right' onClick={handleBulkCancel}><i class="bi bi-x-square"></i> Cancel</button>
        </div>
      </div>
      
      <DataGrid
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={50}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        //height={500}
        autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      
    </div>
  )
}

export default FlagListingOut
