import React, {useCallback, useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import * as CryptoJS from "crypto-js";
import '../ExcelUploads/AssetInfo/AssetInfo.css';
import {Box, FormControl, InputLabel, MenuItem, Pagination, Select} from '@mui/material';
import {Parser} from "json2csv";
import FromFieldDate from "../../atoms/FromFieldDate";
import {getAllocationCSV} from '../../services/asset.service';
import {getERAssignmentReportList} from '../../services/repair.service';


Date.prototype.dbFormatedDate = function () {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
};

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

const onActiveStatusClick = (e, row) => {
    e.stopPropagation(); //alert(row.id)
    //window.open.url
    //do whatever you want with the row
};
const onDeactiveStatusClick = (e, row) => {
    e.stopPropagation(); //alert(row.tag)

};

const columns = [
    //ir-assessment-report
    {"field": "assignment_no", "editable": false, "hide": false, "headerName": "Assignment Number", "width": 230,renderCell: (params) => {
        let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
            'assignment_no': params.row.assignment_no,
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        return (<a href={`/er-assessment-view-report?assignment_no=${EncryptID}`} >{params.row.assignment_no}</a>)
      } 
    },
    {
        "field": "detail_problem_identified",
        "editable": false,
        "hide": false,
        "headerName": "Detail Problem Identified",
        "width": 230
    },
    {
        "field": "materialUsed",
        "editable": false,
        "hide": false,
        "headerName": "Any Materials Used",
        "width": 230
    },
    {"field": "issue_caused", "editable": false, "hide": false, "headerName": "Any Accident", "width": 230},
    {
        "field": "dateOfCopletion",
        "editable": false,
        "hide": false,
        "headerName": "Date of Completion",
        "width": 230
    },
    {"field": "timeOfCompletion", "editable": false, "hide": false, "headerName": "Time of Completions", "width": 230},
    {"field": "nextServiceDue", "editable": false, "hide": false, "headerName": "Next Service Due (if Any)", "width": 230},
    {"field": "handleClosingNote", "editable": false, "hide": false, "headerName": "Closing Note", "width": 280},
    /* {"field": "handleClosingNote", "editable": false, "hide": false, "headerName": "Closing Note", "width": 230,
        renderCell: (params) => (
            <div>{(params.row.handleClosingNote=='5')?(<span class="btn-success p-1">Repair Completed - Asset Working</span>):''}{(params.row.handleClosingNote=='6')?(<span class="btn-danger p-1">Recommended for Disposal</span>):''}{(params.row.handleClosingNote=='7')?(<span class="btn-warning p-1">Repair Not Completed - Send to external</span>):''} </div>
          )}, */
    {
        "field": "assignedDate",
        "editable": false,
        "hide": false,
        "headerName": "Created Date",
        "width": 130,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.created_at)}</p>
        )
    },
];

/*const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport/>
    </GridToolbarContainer>
);*/

const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

const ERAssessmentReportList = () => {
    const [items, setItems] = useState([]);
    const [itemsCSV, setItemsCSV] = useState([]);

    const [searchTag, setSearchTag] = useState('');
    const [ErrSearchTag, setErrSearchTag] = useState(false);

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);

    const [filterCriteria, setFilterCriteria] = useState('');
    const [ErrFilterCriteria, setErrFilterCriteria] = useState(false);

    const [filterOperator, setFilterOperator] = useState('');
    const [ErrFilterOperator, setErrFilterOperator] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [ErrStartDate, setErrStartDate] = useState(false);

    const [endDate, setEndDate] = useState('');
    const [ErrEndDate, setErrEndDate] = useState(false);

    const [hasData, setHasData] = useState(false);
    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchAssets = useCallback(async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = { token: encryptedData };

        try {
            const res = await getERAssignmentReportList(requestData);
            if (res?.data?.data?.length) {
                const itemsWithId = res.data.data.map((item, index) => ({
                    ...item,
                    id: item.id || uuidv4(),  // Assign unique id if missing
                }));
                setItems(itemsWithId);
                setTotalItems(res.data.total || 0);
            } else {
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    }, [page, pageSize]);

    const fetchAssets1 = async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize, 'searchTag': searchTag, 'filterCriteria': filterCriteria, 'filterOperator': filterOperator, 'startDate': startDate, 'endDate': endDate },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = { token: encryptedData };

        try {
            const res = await getERAssignmentReportList(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    };

// Call this function whenever you need to fetch assets, such as inside a useEffect hook or in response to an event


    const fetchAssetsCSV = useCallback(async () => {
        setLoading(true);

        const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify({authToken: sessionStorage.getItem('LoggedInToken')}),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();

        const requestData = {token: encryptedData};

        try {
            const resCSV = await getAllocationCSV(requestData);
            if (resCSV?.data?.data?.length) {
                setItemsCSV(resCSV.data.data);
                setHasData(true);
            }else{
                setHasData(false);
            }
        } catch (error) {
            console.error('Error fetching assets CSV:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAssets();
        //fetchAssets1();
    }, [fetchAssets]);

    useEffect(() => {
        if (itemsCSV.length > 0) {
            generateCSV();
        }
    }, [itemsCSV]);

    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    const handleExportToCSV = async () => {
        await fetchAssetsCSV();
        if (hasData) {
            generateCSV();
        }
    };

    const generateCSV = () => {
        try {
            const jsonData = itemsCSV.map(item => ({
                tag: item.tag ? item.tag : '',
                division: item.division ? item.division : '',
                premises: item.premises ? item.premises : '',
                floor: item.floor ? item.floor : '',
                site_id: item.site_id ? item.site_id : '',
                user_type: item.user_type ? item.user_type : '',
                user_department: item.user_department ? item.user_department : '',
                employee_id: item.employee_id ? item.employee_id : '',
                employee_name: item.employee_name ? item.employee_name : '',
                effective_date: item.effective_date ? getFormatedDate(item.effective_date) : '',
                created_at: item.created_at ? getFormatedDate(item.created_at) : '',
                status: item.status ? getFormatedDate(item.status) : '',
            }));

            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(jsonData);

            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'View_Allocation_List.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting data to CSV:', error);
        }
    };

    const handleSearch = () => {
        let isValid = true;
        if (!filterCriteria) {
            setErrFilterCriteria('Please select a filter criteria');
            isValid = false;
        } else {
            setErrFilterCriteria('');
        }

        if (filterCriteria !== 'created_at' && !filterOperator) {
            setErrFilterOperator('Please select a filter operator');
            isValid = false;
        } else {
            setErrFilterOperator('');
        }

        if (!searchTag && filterCriteria != 'created_at' ) {
            setErrSearchTag('Please enter a search text');
            isValid = false;
        }else{
            setErrSearchTag('');
        }
        if (!startDate && filterCriteria == 'created_at' ) {
            setErrStartDate('Please select start date');
            isValid = false;
        }else{
            setErrStartDate('');
        }
        if (!endDate && filterCriteria == 'created_at' ) {
            setErrEndDate('Please select end date');
            isValid = false;
        }else{
            setErrEndDate('');
        }

        if (isValid) {
            setPage(1);
            fetchAssets1();
        }

    };

    const options = [
        "TAG Number", "Name of the asset", "Serial no", "Assignment no", "Assigned to", "Date Range", "status"
    ];

    const values = [
        "tag", "tag_name", "serial_no", "assignment_no", "assigned_to", "created_at", "status"
    ];

    // Combine options and values into an array of objects
    const combinedOptions = options.map((option, index) => ({
        label: option,
        value: values[index]
    }));

    // Handle change event
    const handleSelectChange = (e) => {
        const selectedOption = combinedOptions.find(option => option.value === e.target.value);
        setFilterCriteria(selectedOption ? selectedOption.value : '');
        setErrFilterCriteria(''); // Reset error message on change
    };

    const optionsFilter = [
        "Contains", "Equals", "Not Contains", "Not Equals", "Blank", "Non Blank"
    ];

    const valuesFilter = [
        "LIKE", "=", "NOT LIKE", "!=", "IS NULL", "IS NOT NULL"
    ];

    // Combine options and values into an array of objects
    const combinedOptionsFilter = optionsFilter.map((option, index) => ({
        label: option,
        value: valuesFilter[index]
    }));

    // Handle operator change event
    const handleOperatorChange = (e) => {
        setFilterOperator(e.target.value);
        setErrFilterOperator(''); // Reset error message on change
    };
    const handelSearchText = (e) => {
        setSearchTag(e.target.value);
    };


    return (
        <div className='container'>
            <div className='col'><h5> ER Assignment Report List </h5></div>

            <div className='filter-container' id="single-asset">

                <div className='row m-2'>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label>
                                Select Criteria <span className="star error">*</span>
                            </label>
                            <select
                                className="form-control"
                                value={filterCriteria}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>Select Filter Criteria</option>
                                {combinedOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {ErrFilterCriteria && <span className="invalid-feedback">{ErrFilterCriteria}</span>}
                        </div>
                    </div>

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>
                                    Select Operator <span className="star error">*</span>
                                </label>
                                <select
                                    className="form-control"
                                    value={filterOperator}
                                    onChange={handleOperatorChange}
                                >
                                    <option value="" disabled>Select Operator</option>
                                    {combinedOptionsFilter.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {ErrFilterOperator && <span className="invalid-feedback">{ErrFilterOperator}</span>}
                            </div>
                        </div>
                    )}

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    Enter Criteria <span className="star error">*</span>
                                </label>

                                <input
                                    type="text" className="form-control"
                                    placeholder="Search..."
                                    inputValue={searchTag}
                                    //setInputValue={setSearchTag}
                                    onChange={handelSearchText}
                                    style={{marginRight: '10px'}}
                                />
                                {ErrSearchTag && <span className="invalid-feedback">{ErrSearchTag}</span>}
                            </div>
                        </div>
                    )}
                </div>
                {filterCriteria === 'created_at' && (
                    <div className="row m-2">
                        <div className="col-6">

                            <FromFieldDate
                                label="Start Date"
                                placeholder="Select Start Date"
                                inputValue={startDate}
                                setInputValue={setStartDate}
                                setErrorMsg={ErrStartDate}
                            />

                        </div>
                        <div className="col-6">

                            <FromFieldDate
                                label="End Date"
                                placeholder="Select End Date"
                                inputValue={endDate}
                                setInputValue={setEndDate}
                                setErrorMsg={ErrEndDate}
                            />

                        </div>
                    </div>
                )}

                <div className="row mt-2">
                    <div className="col-6 text-center">
                        <button onClick={handleSearch} className='btn btn-primary btn-md'>
                            <i className="bi bi-search"></i> Search
                        </button>

                    </div>
                    <div className="col-6 text-center">
                        <button
                            onClick={handleExportToCSV}
                            className='btn btn-primary btn-md'
                            disabled={loading}
                        >
                            <i className="bi bi-download"></i> {loading ? 'Exporting...' : (hasData ? 'Export All Data to CSV' : 'Export All Data to CSV')}
                        </button>
                    </div>
                </div>


            </div>



            <DataGrid
                rows={items}
                getRowId={(row) => row.id}
                columns={columns}
                autoHeight
                components={{
                    LoadingOverlay: CustomLoader,
                    //Toolbar: CustomToolbar,
                    Pagination: () => (
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                            <Box sx={{mr: 4}}>
                                Showing {items.length} records out of {totalItems}
                            </Box>
                            <FormControl variant="outlined" sx={{minWidth: 120}}>
                                <InputLabel>Rows per page</InputLabel>
                                <Select
                                    value={pageSize}
                                    onChange={(event) => handlePageSizeChange(Number(event.target.value))}
                                    label="Rows per page"
                                >
                                    {[50, 100, 200, 500].map((size) => (
                                        <MenuItem key={size} value={size}>
                                            {size}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                            />

                        </Box>
                    ),
                }}
                loading={loading}
            />

        </div>
    );
};

export default ERAssessmentReportList;
