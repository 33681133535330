import React, {useCallback, useEffect, useState} from 'react';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import {getStickeringAssets} from '../../services/asset.service';
import Box from '@mui/material/Box';
import * as CryptoJS from "crypto-js";
import '../ExcelUploads/AssetInfo/AssetInfo.css';
import StickerPasteStatusPopupBox from "../../atoms/StickerPasteStatusPopupBox";
import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";


Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}
const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);
function StickeringStatusList() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);


    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    const columns = [
        {"field": "sl_no", "hideable": true, "hide": true, "editable": false, "headerName": "Sl No", "width": 50},
        {
            "field": "tag", "editable": false, "hide": false, "headerName": "TAG", "width": 250,
            renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'tag': params.row.tag,'back_url':'stickering-status'
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (<a href={`/asset-basic-details?tag=${EncryptID}`}>{params.value}</a>);
            }
        },
        {
            "field": "parent_tag",
            "hideable": true,
            "hide": false,
            "editable": true,
            "headerName": "Parent TAG",
            "width": 250
        },


        {"field": "qr_paste_status", "editable": true, "hide": false, "headerName": "Status of QR Sticker", "width": 230,
            renderCell: (params) => (
                <div>
                    {params.row.qr_paste_status == 'N' ? (
                        <span className="btn-btn-danger btn-sm text-right" onClick={() => handleConfirmOpen(params.row.qr_paste_status, params.row.tag)}
                        >Not Pasted {params.row.qr_paste_status}</span>
                    ) : (
                        <span className="btn-btn-success btn-sm text-right"
                            onClick={() => handleConfirmOpen(params.row.qr_paste_status, params.row.tag)}
                        > Pasted {params.row.qr_paste_status}</span>
                    )}
                </div>

            )
        },
        {
            "field": "created_at", "editable": true, "hide": false, "headerName": "Date of Addition", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },

    ];

    const [tag, setTag] = useState('');
    const [qrPasteStatus, setQrPasteStatus] = useState('');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchAssets = useCallback(async () => {
        setLoading(true);
        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = {token: encryptedData};

        try {
            const res = await getStickeringAssets(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            }else{
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    }, [page, pageSize]);

    useEffect(() => {
        fetchAssets();
        //fetchAssets1();
    }, [fetchAssets]);

    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / pageSize);


    const handleConfirmClose = () => {
        /*let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        setLoading(true);
        getStickeringAssets(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });*/
            fetchAssets();
        setIsConfirmOpen(false);
    };
    const handleConfirmOpen = async (qr_paste_status, tag) => {
        setQrPasteStatus(qr_paste_status);
        setTag(tag);
        setIsConfirmOpen(true);
    };

    // readAssets();
    return (
        <div className='container'>
            <div className='col'><h5> Stickering Status List </h5></div>
                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <Box>
                        <DataGrid
                            rows={items}
                            getRowId={(row) => row.tag}
                            columns={columns}
                            autoHeight
                            components={{
                                LoadingOverlay: CustomLoader,
                                Toolbar: CustomToolbar,
                                Pagination: () => (
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                        <Box sx={{mr: 4}}>
                                            Showing {items.length} records out of {totalItems}
                                        </Box>
                                        <FormControl variant="outlined" sx={{minWidth: 120}}>
                                            <InputLabel>Rows per page</InputLabel>
                                            <Select
                                                value={pageSize}
                                                onChange={(event) => handlePageSizeChange(Number(event.target.value))}
                                                label="Rows per page"
                                            >
                                                {[50, 100, 200, 500].map((size) => (
                                                    <MenuItem key={size} value={size}>
                                                        {size}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>


                                        <Pagination
                                            count={totalPages}
                                            page={page}
                                            onChange={handlePageChange}
                                            variant="outlined"
                                            shape="rounded"
                                        />

                                    </Box>
                                ),
                            }}
                            loading={loading}
                        />
                    </Box>
                )}
                <StickerPasteStatusPopupBox
                    qrPasteStatus={qrPasteStatus}
                    tag={tag}
                    isOpen={isConfirmOpen}
                    onClose={handleConfirmClose}
                />
        </div>
    )
}

export default StickeringStatusList
