import React,{useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate  } from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import { getSTPI } from '../../../services/asset.service';
import {getClasificationsList} from '../../../services/user.service';
import FromFieldNumber from '../../../atoms/FromFieldNumber';
import  * as CryptoJS  from "crypto-js";
Date.prototype.dbFormatedDate = function () {
    
  let year = this.getFullYear();
  let month = this.getMonth() + 1;
  let day = this.getDate();
  if (month < 10) {
    month = '0' + month;
  }if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;
  //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}
function getFormatedDate(dateString) {
  if(dateString!==null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function SACapForm() {
  const location = useLocation(); // Access location object
  const navigate = useNavigate(); // Access navigate function

  const [tag, setTag] = useState('');
  const [assetInfo, setAssetInfo] = useState([]);
  const [parentTag, setParentTag] = useState('');
  const [typeofdepreciation,setTypeOfDepreciation]= useState('');;
  const [depreciationrate, setDepreciationRate] = useState('');
  const [capTypeOfAsset, setCapTypeOfAsset] = useState('');
  const [assetInuseDate, setAssetInuseDate] = useState('');
  const [endDepDate, setDepEndDate] = useState('');
  const [accountingCode, setAccountingCode] = useState('');
  const [accountingName, setAccountingName] = useState('');
  const [financialsClassification, setFinancialsClassification] = useState('');
  const [classOfAsset,setClassOfAsset] = useState('');
  const [recordstartdate,setRecordStartDate] = useState('');
  const [finalAsset,setFinalAsset] = useState('');
  const [isTaxCapitalized,setIsTaxCapitalized] = useState('');
  const [is100PercDepreciable,setIs100PercDepreciable] = useState('');
  const [invoicecurrency, setinvoicecurrency] = useState('');
  const [exchangerate, setexchangerate] = useState('');
  const [preCapitalizedAssetValue,setPreCapitalizedAssetValue] = useState('');
  const [isAnyOtherAccgGaapApplicable,setIsAnyOtherAccgGaapApplicable] = useState('');
  const [igaapLimitAmount,setIgaapLimitAmount] = useState('');
  const [othergaapLimitAmount,setOthergaapLimitAmount] = useState('');
  const [salvageValue,setSalvageValue] = useState('');
  const [iGaapCapitalizedValue,setIGaapCapitalizedValue] = useState('');
  const [othergaapCapitalizedValue,setOthergaapCapitalizedValue] = useState('');
  const [otherrecordstartdate, setOtherRecordStartDate] = useState('');
  const [otherdepreciationrate, setOtherDepreciationRate] = useState('');
  const [is100PercOtherDepreciable,setIs100PercOtherDepreciable] = useState('');
  const [assetotherenddate, setAssetOtherEndDate] = useState();
  const [classifications, setClasifications] = useState([]);
  const [valueofAsset,setValueofAsset] = useState('');
  const [valueWithTax,setValueWithTax] = useState('');
  const [totalcostwithouttax,setTotalCostWithoutTax] = useState('');
  const [ capitalization_status ,setCapitalizationStatus]= useState('');
  const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    //const elementRef = useRef();
    useEffect(() => {
        if(Tag!='' && Tag!=null){

            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedRoleid = decrypt_data.tag;

          getTagCapInfo(decryptedRoleid);
            //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          //'param_data':{'tag':tag},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getClasificationsList(oData).then(res=>{
          if(res?.data?.data?.length) setClasifications(res.data.data.toString().replace(',',''));
        });
    }, []);

    const getTagCapInfo = function(Tag) {
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':{'tag':Tag},
        'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData1 = {'token':EncryptData}
      getSTPI(oData1).then(function(res){
        if(res?.data?.data) {
          var {assetInfo,assetInfo} = res.data.data;
          //console.log(assetInfo);
          setAssetInfo(assetInfo);
          setCapitalizationStatus(assetInfo.capitalization_status);
          if(assetInfo.tag) { 
            setTag(assetInfo.tag);

            //check for child asset
            if(assetInfo.parent_tag){ 
              setParentTag(assetInfo.parent_tag);
              let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':{'tag':assetInfo.parent_tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData2 = {'token':EncryptData}
              getSTPI(oData2).then(function(res2){
                if(res2?.data?.data) {
                  var assetInfo2 = res2.data.data.assetInfo;
                  //setParentTag(assetInfo.parent_tag);
                  setTypeOfDepreciation(assetInfo.type_of_depreciation);
                  if(assetInfo.cap_type_of_asset=="UseParent"){
                    //setAssetInuseDate(assetInfo2.asset_in_use_date?(new Date(new Date(assetInfo2.asset_in_use_date))) :'');
                    setAssetInuseDate(getFormatedDate(assetInfo2.asset_in_use_date));
                  }else{ 
                    //setAssetInuseDate(assetInfo.asset_in_use_date?(new Date(new Date(assetInfo.asset_in_use_date))) :'');
                    setAssetInuseDate(getFormatedDate(assetInfo.asset_in_use_date));
                  }
                  //setAssetInuseDate(getFormatedDate(assetInfo.asset_in_use_date));
                  setDepreciationRate(assetInfo2.depreciation_rate);
                  setCapTypeOfAsset(assetInfo2.cap_type_of_asset);
                  setDepEndDate(getFormatedDate(assetInfo2.depreciation_end_date));
                  setAccountingCode(assetInfo2.accounting_code);
                  setAccountingName(assetInfo2.accounting_name);
                  setFinancialsClassification(assetInfo2.category_name);
                  setClassOfAsset(assetInfo2.class_of_asset);
                  setRecordStartDate(getFormatedDate(assetInfo2.record_start_date));
                  setAssetOtherEndDate(getFormatedDate(assetInfo.depreciation_other_end_date));
                  setOtherRecordStartDate(assetInfo.other_record_start_date?(getFormatedDate(assetInfo.other_record_start_date)) :'');
                  setOtherDepreciationRate(assetInfo2.other_depreciation_rate?assetInfo2.other_depreciation_rate:'');
                  setFinalAsset(assetInfo2.capitalization_flag);
                  setIsTaxCapitalized(assetInfo.is_tax_capitalized);
                  setIs100PercDepreciable(assetInfo.is_100_perc_depreciable);
                  setPreCapitalizedAssetValue(assetInfo.value_of_asset?assetInfo.value_of_asset:assetInfo2.value_of_asset);
                  setinvoicecurrency(assetInfo.invoice_currency);
                  setexchangerate(assetInfo.cap_exchange_rate?assetInfo.cap_exchange_rate:assetInfo.exchange_rate);
                  setIs100PercOtherDepreciable(assetInfo.is_100_perc_other_depreciable);
                  setIsAnyOtherAccgGaapApplicable(assetInfo.is_any_other_accg_gaap_applicable);
                  setIgaapLimitAmount(assetInfo.igaap_limit_amount);
                  setOthergaapLimitAmount(assetInfo.othergaap_limit_amount);
                  setSalvageValue(assetInfo.salvage_value);
                  setTotalCostWithoutTax(assetInfo.total_cost_without_tax); 
                  if(assetInfo2.is_tax_capitalized=='No'){
                    setIGaapCapitalizedValue(assetInfo.total_cost_without_tax);
                  }else{
                    setIGaapCapitalizedValue(assetInfo.value_of_asset);
                  }
                  setOthergaapCapitalizedValue(assetInfo.othergaap_capitalized_value);
                  }
              });
            }else{ //asset details for parent asset
              console.log('parent_assetInfo');
              console.log(assetInfo);
                setParentTag(assetInfo.parent_tag);
                setTypeOfDepreciation(assetInfo.type_of_depreciation);
                setAssetInuseDate(getFormatedDate(assetInfo.asset_in_use_date));
                setDepreciationRate(assetInfo.depreciation_rate);
                setCapTypeOfAsset(assetInfo.cap_type_of_asset);
                setDepEndDate(getFormatedDate(assetInfo.depreciation_end_date));
                setAccountingCode(assetInfo.accounting_code);
                setAccountingName(assetInfo.accounting_name);
                setFinancialsClassification(assetInfo.category_name);
                setClassOfAsset(assetInfo.class_of_asset);
                setRecordStartDate(getFormatedDate(assetInfo.record_start_date));
                setAssetOtherEndDate(getFormatedDate(assetInfo.depreciation_other_end_date));
                setFinalAsset(assetInfo.capitalization_flag);
                setIsTaxCapitalized(assetInfo.is_tax_capitalized);
                setIs100PercDepreciable(assetInfo.is_100_perc_depreciable);
                setIs100PercOtherDepreciable(assetInfo.is_100_perc_other_depreciable);
                setOtherRecordStartDate(assetInfo.other_record_start_date?(getFormatedDate(assetInfo.other_record_start_date)) :'');
                setOtherDepreciationRate(assetInfo.other_depreciation_rate?assetInfo.other_depreciation_rate:'');
                setinvoicecurrency(assetInfo.invoice_currency);
                setexchangerate(assetInfo.cap_exchange_rate?assetInfo.cap_exchange_rate:assetInfo.exchange_rate);
                setPreCapitalizedAssetValue(assetInfo.value_of_asset);
                setIsAnyOtherAccgGaapApplicable(assetInfo.is_any_other_accg_gaap_applicable);
                setIgaapLimitAmount(assetInfo.igaap_limit_amount);
                setOthergaapLimitAmount(assetInfo.othergaap_limit_amount);
                setSalvageValue(assetInfo.salvage_value);
                //setIGaapCapitalizedValue(assetInfo.igaap_capitalized_value);
                setTotalCostWithoutTax(assetInfo.total_cost_without_tax); 
                if(assetInfo.is_tax_capitalized=='No'){
                  setIGaapCapitalizedValue(assetInfo.total_cost_without_tax);
                }else{
                  //setIGaapCapitalizedValue(assetInfo.igaap_capitalized_value);
                  setIGaapCapitalizedValue(assetInfo.value_of_asset);
                }
                setOthergaapCapitalizedValue(assetInfo.othergaap_capitalized_value);
              }
            //setValueofAsset(assetInfo.value_of_asset);
            //setValueWithTax(assetInfo.value_with_tax);
          }
        }
  
      });
  }

  const handleBack = () => {
    if (location.state && location.state.prevPath) {
      navigate(location.state.prevPath); // Use prevPath from state if available
    } else {
      navigate(-1); // Fallback to browser history for dynamic back
    }
  };

  return (
    <div className="container" id="capitalization-view">
      <div className="row">
        <div className="col-10">
          <h5>Single Capitalization</h5>
        </div>
        <div className="col-2">
        
        {
            (Tag!='' && Tag!=null) ? <div> {(capitalization_status=='Y') ?<a href={'/single-asset-capitalization?mode=edit&tag='+ Tag } className='btn btn-info btn-md'><i className="bi bi-pencil-square"></i> Edit</a>:''} &nbsp;

                <button onClick={handleBack} className="btn btn-secondary btn-md">
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              &nbsp;</div> : <div></div>}
        </div>
      </div>
      {
        (Tag != '' && Tag != null) ? <div className='row'><br></br></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
            <div className='col'></div></div>
            }
      
      <div className="row">
        <div className="col-6">
         <FromFieldText 
            label="TAG" 
            inputValue={tag}
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
        
        </div>
      </div>
      <div className="row">
        <div className="col-6">
         <FromFieldText 
            label="Class of Asset" 
            inputValue={classOfAsset}
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
        <FromFieldText 
            label="Record Start Date" 
            inputValue={recordstartdate}
            readOnly="readonly"
          /> 
        </div>
      </div>
      <div className="row">
        <div className="col-6">
         <FromFieldText 
            label="Parent TAG" 
            inputValue={parentTag}
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
        <FromFieldText 
            label="Depreciation mode" 
            inputValue={capTypeOfAsset}
            readOnly="readonly"
          /> 
        </div>
      </div>

      <div className="row">
      <div className="col-6">
          <FromFieldText 
            label="Date of Capitalization / Asset Ready To Use"
            inputValue={assetInuseDate}
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
          
          <FromFieldText  
            label="Type of Depreciation"
            inputValue={typeofdepreciation}
            readOnly="readonly"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
        <FromFieldNumber  
            label="Depreciation Rate (No. of years)"
            inputValue={depreciationrate}
            readOnly="readonly"
          />
        </div>
        <div className="col-6">
        <FromFieldText 
            label="End Date" 
            inputValue={endDepDate}
            readOnly="readonly"
          /> 
        </div>
      </div>
      <div className="row">
        <div className="col-6">
        <FromFieldText  
            label="Accounting Code"
            inputValue={accountingCode}
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
          <FromFieldText      
            label="Accounting Name"
            //placeholder="Nature of Addition"
            inputValue={accountingName} 
            //setInputValue={setNatureOfAddition} 
            //setErrorMsg={ErrNatureOfAddition}
            readOnly="readonly"
          /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
        <FromFieldText //not star 
              label="Financials Classification"
              inputValue= { financialsClassification }
              readOnly="readonly"
            />
          {/*<FromFieldText //not star 
              label="Financials Classification"
              inputValue= { classifications && classifications.length > 0 ? ( 
                classifications.map((option) => 
                (option.id==financialsClassification)? option.category_name : "") 
                  ) : "" }
              readOnly="readonly"
              />*/}
        </div>
        <div className="col-6">
          <FromFieldText  
              label="Class of Asset"
              //placeholder="Class of Asset"
              inputValue={classOfAsset}
              //setInputValue={setClassOfAsset}
              //setErrorMsg={ErrClassOfAsset}
              readOnly="readonly"
            />
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldText 
            label="Final Asset Capitalization Flag" 
            //placeholder="Final Asset Capitalization Flag" 
            inputValue={finalAsset} 
            //setInputValue={setFinalAsset}  
            //setErrorMsg={ErrFinalAsset}
            readOnly="readonly"
          />
        </div>
        <div className="col-6">
         <FromFieldText 
            label="Is Tax Capitalized" 
            //placeholder="Approval Value in CIF" 
            inputValue={isTaxCapitalized} 
            //setInputValue={setIsTaxCapitalized} 
            //setErrorMsg={ErrIsTaxCap}
            readOnly="readonly"
          /> 
        </div>
      
      </div>
      <div className="row">
        <div className="col-6">
          <FromFieldText  
            label="Invoice Currency"
            //placeholder="Is 100% Depreciable Asset" 
            inputValue={invoicecurrency} 
            //setInputValue={setIs100PercDepreciable}
            //setErrorMsg={ErrIs100PercDep} 
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
          <FromFieldNumber 
              label="Exchange Rate"
              //placeholder="Pre Capitalized Asset Value"
              inputValue={exchangerate} 
              readOnly="readonly"
            /> 
        </div>
      
      </div>
      <div className="row">
        <div className="col-6">
          <FromFieldText  
            label="Is 100% Depreciable Asset"
            //placeholder="Is 100% Depreciable Asset" 
            inputValue={is100PercDepreciable} 
            //setInputValue={setIs100PercDepreciable}
            //setErrorMsg={ErrIs100PercDep} 
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
          <FromFieldNumber 
              label="Pre Capitalized Asset Value"
              //placeholder="Pre Capitalized Asset Value"
              inputValue={preCapitalizedAssetValue} 
              readOnly="readonly"
            /> 
        </div>
      
      </div>
          
      <div className="row">
        <div className="col-6">
          <FromFieldText 
            label="Is any other Accg GAAP Applicable"
            //placeholder="Is any other Accg GAAP Applicable" 
            inputValue={isAnyOtherAccgGaapApplicable}
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
         <FromFieldNumber 
            label="I-GAAP - Limit for 100% Depreciable Asset (Rs)"
            //placeholder="I-GAAP - Limit for 100% Depreciable Asset (Rs)" 
            inputValue={igaapLimitAmount} 
            readOnly="readonly"
          /> 
        </div>
      
      </div>

      <div className="row ">
        <div className="col-6">
        <FromFieldNumber 
            label="Salvage Value"
            //placeholder="Salvage Value" 
            inputValue={salvageValue}
            readOnly="readonly"
          /> 
        </div>
        <div className="col-6">
        <FromFieldNumber   
            label="Capitalized Value (I-GAAP)"
            //placeholder="Capitalized Value (I-GAAP)" 
            inputValue={iGaapCapitalizedValue}
            readOnly="readonly"
          /> 
        </div>
           
      </div>
      
      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
        
        </div>
      </div>
      
      { ((isAnyOtherAccgGaapApplicable!=='' && isAnyOtherAccgGaapApplicable=='Yes')) ? <div>
      <div className="row">
        <h5>&nbsp;</h5>
        <hr></hr>
        <h5>Other-GAAP</h5>
        </div>
        <div className="row">
        <div className="col-6">
        <FromFieldText star="*"
                    label="Record Start Date [Other-GAAP]"
                    inputValue={otherrecordstartdate}
                    readOnly="readonly"
                  />
         
        </div>
        <div className="col-6">
        <FromFieldText star="*"
                    label="Is 100% Depreciable Asset [Other-GAAP]"
                    inputValue={is100PercOtherDepreciable}
                    readOnly="readonly"
                />
         
        </div>
        </div>
        <div className="row">
        <div className="col-6">
            <div className="row"><div className="col-md-6">
              <label> Depreciation Rate(No. of years) [Other-GAAP] <span className="star error"> * </span></label>
              </div>
              <div className="col-md-6">
                <input type="number" value={otherdepreciationrate} className="form-control" placeholder="Depreciation Rate[Other-GAAP]" readOnly="readonly" />
                  
              </div>
              </div>
          </div>
          <div className="col-6">
          <FromFieldText  //star="*"
              label="End Date [Other-GAAP]"
              inputValue={assetotherenddate} 
              readOnly="readOnly"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <FromFieldNumber   star="*"
              label="Other-GAAP - Limit for 100% Depreciable Asset (Rs)"
              inputValue={othergaapLimitAmount} 
              readOnly="readonly"
              
            />
            </div>
          <div className="col-6">
            <FromFieldNumber star="*"
              label="Capitalized Value (Other GAAP)" 
              inputValue={othergaapCapitalizedValue} 
              readOnly="readonly"
            />        
          </div>
        </div>
      </div>:('') }
    </div>
  )
}

export default SACapForm;

