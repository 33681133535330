import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import FromSpFieldText from '../../atoms/FromSpFieldText';
import FromSpFieldTextArea  from '../../atoms/FromSpFieldTextArea';
import FromFieldDate from '../../atoms/FromFieldDate';
import FromFileUpload from '../../atoms/FromFileUpload';
import FromFieldNumber from '../../atoms/FromFieldNumber';
//import './SingleAsset.css';
import {getCompanyProfileData, saveCompanyProfile, saveRole} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import AWS from "aws-sdk";

const s3 = new AWS.S3();
//import FromFieldLabel from '../../atoms/FromFieldLabel';
Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!=^|~:;{}<> ]{1,500}$/;
const TEXT_REGEX = /^[A-Za-z0-9-_/.= ]{1,255}$/;
const NUMBER_REGEX = /^[0-9.]{1,15}$/;
const Email_REGEX = /^[a-zA-Z0-9@.-_ ]{1,255}$/;
export default function CompanyProfile() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const CompanyId = query.get('companyid');
    const elementRef = useRef();

    useEffect(() => {
        if (CompanyId !== '' && CompanyId !== null) {

            const decryptedBytes = CryptoJS.AES.decrypt(CompanyId.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            //console.log(decrypt_data);
            const decrypt_dataTag = decrypt_data.company_id;
            get_Company_Profile(decrypt_dataTag);
            //alert(Tag);
        }
    }, [])

    const get_Company_Profile = function (decrypt_dataTag) {

        try {
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'company_id': decrypt_dataTag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}

            getCompanyProfileData(oData1).then(function (res) {
                console.log(res.data.data);
                if (res?.data?.data.length) {
                    var aInfo = res.data.data[0];
                    setCompanyName(aInfo.company_name);
                    setCompanyAddress(aInfo.company_address);
                    setCompanyCode(aInfo.company_code);
                    setCompanyShortCode(aInfo.company_short_name);
                    setPanNumber(aInfo.pan_number);
                    setGstNumber(aInfo.gst_number);
                    setWebsiteName(aInfo.website_name);
                    setWebsiteUrl(aInfo.website_url);
                    setLandlineNo(aInfo.landline_no);
                    setAuthorizedSignatory(aInfo.name_authorized_signatory);
                    setAuthMailId(aInfo.authorized_mail_id);
                    setAuthMobileNumber(aInfo.authorized_mobile_number);
                    setContactPerson(aInfo.contact_person_name);
                    setConPersonMailId(aInfo.contact_mail_id);
                    setConPersonMobile(aInfo.contact_mobile_number);
                    //setDataFromDate(aInfo.date_of_contract ? getFormatedDate(aInfo.date_of_contract) : '');
                    setDateOfContract((new Date(aInfo.date_of_contract)));
                    setSignedBy(aInfo.signed_by);
                    //setDataFromDate(aInfo.data_from_date ? getFormatedDate(aInfo.data_from_date) : '');
                    setDataFromDate((new Date(aInfo.data_from_date)));

                    //setDataFromDate(aInfo.data_to_date ? getFormatedDate(aInfo.data_to_date) : '');
                    setDataToDate((new Date(aInfo.data_to_date)));

                    //setDataFromDate(aInfo.contract_from_date ? getFormatedDate(aInfo.contract_from_date) : '');
                    setContractFromDate((new Date(aInfo.contract_from_date)));

                    //setDataFromDate(aInfo.contract_end_date ? getFormatedDate(aInfo.contract_end_date) : '');
                    setContractToDate((new Date(aInfo.contract_end_date)));
                    setAccgGAAP(aInfo.other_gaap_applicable);
                }
            })
        } catch (err) {
            //setAssetInfo('');
        }

    }
    //const userRef = useRef();
    const errRef = useRef();
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);

    const [companyname, setCompanyName,] = useState('');
    const [ErrCompanyName, setErrCompanyName] = useState(false);

    const [companyaddress, setCompanyAddress] = useState('');
    const [ErrCompanyAddress, setErrCompanyAddress] = useState(false);

    const [pannumber, setPanNumber] = useState('');
    const [ErrPanNumber, setErrPanNumber] = useState(false);

    const [gstnumber, setGstNumber] = useState('');
    const [ErrGstNumber, setErrGstNumber] = useState(false);

    const [companylogo, setCompanyLogo] = useState('');
    const [ErrCompanyLogo, setErrCompanyLogo] = useState(false);

    const [websitename, setWebsiteName] = useState('');
    const [ErrWebsiteName, setErrWebsiteName] = useState(false);

    const [websiteurl, setWebsiteUrl] = useState('');
    const [ErrWebsiteUrl, setErrWebsiteUrl] = useState(false);

    const [landlineno, setLandlineNo] = useState('');
    const [ErrLandlineNo, setErrLandlineNo] = useState(false);

    const [authorizedsignatory, setAuthorizedSignatory] = useState('');
    const [ErrAuthorizedSignatory, setErrAuthorizedSignatory] = useState(false);

    const [authmailid, setAuthMailId] = useState('');
    const [ErrAuthMailId, setErrAuthMailId] = useState(false);

    const [authmobilenumber, setAuthMobileNumber] = useState('');
    const [ErrAuthMobileNumber, setErrAuthMobileNumber] = useState(false);

    const [contactperson, setContactPerson] = useState('');
    const [ErrContactPerson, setErrContactPerson] = useState(false);

    const [conpersonmailid, setConPersonMailId] = useState('');
    const [ErrConPersonMailId, setErrConPersonMailId] = useState(false);

    const [conpersonmobile, setConPersonMobile] = useState('');
    const [ErrConPersonMobile, setErrConPersonMobile] = useState(false);

    const [dateofcontract, setDateOfContract] = useState('');
    const [ErrDateOfContract, setErrDateOfContract] = useState(false);

    const [signedby, setSignedBy] = useState('');
    const [ErrSignedBy, setErrSignedBy] = useState(false);

    const [datafromdate, setDataFromDate] = useState('');
    const [ErrDataFromDate, setErrDataFromDate] = useState(false);

    const [datatodate, setDataToDate] = useState('');
    const [ErrDataToDate, setErrDataToDate] = useState(false);

    const [contractfromdate, setContractFromDate] = useState('');
    const [ErrContractFromDate, setErrContractFromDate] = useState(false);

    const [contracttodate, setContractToDate] = useState('');
    const [ErrContractToDate, setErrContractToDate] = useState(false);

    const [companycode, setCompanyCode] = useState('');
    const [ErrCompanyCode, setErrCompanyCode] = useState(false);

    const [companyshortcode, setCompanyShortCode] = useState('');
    const [ErrCompanyShortCode, setErrCompanyShortCode] = useState(false);

    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');
    const [ErrFileName, setErrFileName] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const handleUploadLogo = (e) => {
        //setFile(e.target.files[0]);
        //setFileName(e.target.files[0].name);
        setCompanyLogo(e.target.files);
    };

    const resetUserInfo = function () {
        setCompanyName('');
        setCompanyShortCode('');
        setCompanyCode('');
        setCompanyAddress('');
        setPanNumber('');
        setGstNumber('');
        setWebsiteName('');
        setWebsiteUrl('');
        setLandlineNo('');
        setAuthorizedSignatory('');
        setAuthMailId('');
        setAuthMobileNumber('');
        setContactPerson('');
        setConPersonMailId('');
        setConPersonMobile('');
        setDateOfContract('');
        setSignedBy('');
        setDataFromDate('');
        setDataToDate('');
        setContractFromDate('');
        setContractToDate('');
        setAccgGAAP('');
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        var valid_Name = true;
        //alert(valid_Name);
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(yearaddition);

        if (!TEXT_REGEX.test(companyname)) {
            setErrCompanyName('Company name is required');
            valid_Name = false; //alert('16');
            //return;
        } else {
            setErrCompanyName('');
        }

        if (!TEXT_SPCL_REGEX.test(companyaddress)) {
            setErrCompanyAddress('Company address is required');
            valid_Name = false; //alert('16');
            //return;
        } else {
            setErrCompanyAddress('');
        }

        if (!TEXT_REGEX.test(companycode)) {
            setErrCompanyCode('Company code is required');
            valid_Name = false; //alert('16');
            //return;
        } else {
            setErrCompanyCode('');
        }

        if (!TEXT_REGEX.test(companyshortcode)) {
            setErrCompanyShortCode('Company short code is required');
            valid_Name = false; //alert('16');
            //return;
        } else {
            setErrCompanyShortCode('');
        }


        if (!TEXT_REGEX.test(pannumber)) {
            setErrPanNumber('PAN number is required');
            valid_Name = false; //alert('16');
            //return;
        } else {
            setErrPanNumber('');
        }
        if (!TEXT_REGEX.test(gstnumber)) {
            setErrGstNumber('GST number is required');
            valid_Name = false; //alert('16');
            //return;
        } else {
            setErrGstNumber('');
        }

        /*if (!TEXT_REGEX.test(companylogo)) {
			setErrCompanyLogo('Company logo is required'); valid_Name=false; //alert('16');
			//return;
		}else{ setErrCompanyLogo('');  }*/

        if (!TEXT_REGEX.test(websitename)) {
            setErrWebsiteName('Website name is required');
            valid_Name = false; //alert('16');
            //return;
        } else {
            setErrWebsiteName('');
        }
        if (!TEXT_SPCL_REGEX.test(websiteurl)) {
            setErrWebsiteUrl('Website url is required');
            valid_Name = false; //alert('17');
            //return;
        } else {
            setErrWebsiteUrl('');
        }

        if (!TEXT_REGEX.test(landlineno)) {
            setErrLandlineNo('Landline number is required');
            valid_Name = false; //alert('20');
            //return;
        } else if (!NUMBER_REGEX.test(landlineno)) {
            setErrLandlineNo('Landline number should be a number');
            valid_Name = false; //alert('21');
        } else {
            setErrLandlineNo('');
        }

        if (!TEXT_REGEX.test(authorizedsignatory)) {
            setErrAuthorizedSignatory('Authorized Signatory is required');
            valid_Name = false; //alert('16');
            //return;
        } else {
            setErrAuthorizedSignatory('');
        }

        if (!Email_REGEX.test(authmailid)) {
            setErrAuthMailId('Authorized mailid is required');
            valid_Name = false; //alert('22');
            //return;
        } else {
            setErrAuthMailId('');
        }

        if (!authmobilenumber) {
            setErrAuthMobileNumber('Authorized mobile number is required');
            valid_Name = false; //alert('24');
            //return;
        } else if (!NUMBER_REGEX.test(authmobilenumber)) {
            setErrAuthMobileNumber('Authorized mobile number should be a number');
            valid_Name = false; //alert('29');
        } else {
            setErrAuthMobileNumber('');
        }

        if (!TEXT_REGEX.test(contactperson)) {
            setErrContactPerson('Contact person is required');
            valid_Name = false; //alert('26');
            //return;
        } else {
            setErrContactPerson('');
        }

        if (!Email_REGEX.test(conpersonmailid)) {
            setErrConPersonMailId('Contact person Mail id is required');
            valid_Name = false; //alert('286');
            //return;
        } else {
            setErrConPersonMailId('');
        }

        if (!conpersonmobile) {
            setErrConPersonMobile('Contact person mobile is required');
            valid_Name = false; //alert('286');
            //return;
        } else if (!NUMBER_REGEX.test(conpersonmobile)) {
            setErrConPersonMobile('Contact person mobile should be a number');
            valid_Name = false; //alert('29');
        } else {
            setErrConPersonMobile('');
        }

        if (dateofcontract == '') {
            setErrDateOfContract('Date of contract is required');
            valid_Name = false; //alert('32');
            //return;
        } else {
            setErrDateOfContract('');
        }

        if (!TEXT_REGEX.test(signedby)) {
            setErrSignedBy('Signed By is required');
            valid_Name = false; //alert('32');
            //return;
        } else {
            setErrSignedBy('');
        }

        if (datafromdate == '') {
            setErrDataFromDate('Data from date is required');
            valid_Name = false; //alert('32');
            //return;
        } else {
            setErrDataFromDate('');
        }

        if (datatodate == '') {
            setErrDataToDate('Data from date is required');
            valid_Name = false; //alert('32');
            //return;
        } else {
            setErrDataToDate('');
        }

        if (contractfromdate == '') {
            setErrContractFromDate('Contract from date is required');
            valid_Name = false; //alert('32');
            //return;
        } else {
            setErrContractFromDate('');
        }

        if (contracttodate == '') {
            setErrContractToDate('Contract end date is required');
            valid_Name = false; //alert('32');
            //return;
        } else {
            setErrContractToDate('');
        }
        if (accgGAAP == '') {
            setErrAccgGAAP('Accg GAAP Applicable is required');
            valid_Name = false; //alert('32');
            //return;
        } else {
            setErrContractToDate('');
        }


        if (valid_Name) { //alert('validName');
            saveData(); //submit form
        } else {
            //alert('valid_Name false');
            return false;
        }
    }


    const saveData = function () {
        try {

            const file = companylogo[0];
            let img_name ='';
            if(file){
                const fileName = file.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                img_name = companyshortcode + '_' + '.' + fileExtension;

                //if (file) {
                //console.log(img_name);
                //console.log("ATS");
                uploadFile(file, img_name);
                //}
                //oData.upload_file = img_name;
            }

            if (CompanyId !== '' && CompanyId !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(CompanyId.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedCompanyID = decrypt_data.company_id;
                var obj = {
                    'company_ids': decryptedCompanyID,

                    'company_name': companyname,
                    'company_address': companyaddress,

                    'company_code': companycode,
                    'company_short_name': companyshortcode,

                    'pan_number': pannumber,
                    'gst_number': gstnumber,
                    'logo': companylogo?img_name:'',
                    'website_name': websitename,
                    'website_url': websiteurl,
                    'name_authorized_signatory': authorizedsignatory,
                    'contact_person_name': contactperson,
                    'landline_no': landlineno,
                    'authorized_mail_id': authmailid,
                    'authorized_mobile_number': authmobilenumber,
                    'contact_mail_id': conpersonmailid,
                    'contact_mobile_number': conpersonmobile,
                    'date_of_contract': getFormatedDate(dateofcontract),
                    'signed_by': signedby,
                    'data_from_date': getFormatedDate(datafromdate),
                    'data_to_date': getFormatedDate(datatodate),
                    'contract_from_date': getFormatedDate(contractfromdate),
                    'contract_end_date': getFormatedDate(contracttodate),
                    'other_gaap_applicable': accgGAAP,
                }
            }else {
                var obj = {
                    'company_name': companyname,
                    'company_address': companyaddress,
                    'company_code': companycode,
                    'company_short_name': companyshortcode,
                    'pan_number': pannumber,
                    'gst_number': gstnumber,
                    'logo': companylogo?img_name:'',
                    'website_name': websitename,
                    'website_url': websiteurl,
                    'name_authorized_signatory': authorizedsignatory,
                    'contact_person_name': contactperson,
                    'landline_no': landlineno,
                    'authorized_mail_id': authmailid,
                    'authorized_mobile_number': authmobilenumber,
                    'contact_mail_id': conpersonmailid,
                    'contact_mobile_number': conpersonmobile,
                    'date_of_contract': getFormatedDate(dateofcontract),
                    'signed_by': signedby,
                    'data_from_date': getFormatedDate(datafromdate),
                    'data_to_date': getFormatedDate(datatodate),
                    'contract_from_date': getFormatedDate(contractfromdate),
                    'contract_end_date': getFormatedDate(contracttodate),
                    'other_gaap_applicable': accgGAAP,
                }
            }


            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            saveCompanyProfile(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const uploadFile = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'company_logo/' + img_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                return params.name;
            }
        });
    };

    const handleAccgGAAP = (e) => {
        setAccgGAAP(e.target.value);
    }

    const [accgGAAP, setAccgGAAP] = useState('');
    const [ErrAccgGAAP, setErrAccgGAAP] = useState(false);

    return (
        <div className='container'>
            <div className='row single__assets' ref={elementRef}>
                <div className="col">
                    <h5>Create New Company Profile</h5>
                </div>

                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data" method="post">

                <div className='row single__asset__form'>
                    <div className='col-12'>
                        <h6>Basic Details</h6>
                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldText label="Name of the company"
                                       inputValue={companyname}
                                       setInputValue={setCompanyName}
                                       setErrorMsg={ErrCompanyName}
                        />
                    </div>
                    <div className='col-6'>
                        <FromSpFieldTextArea numRows = "3" maxLength = "500" label="Address of the Company"
                                       inputValue={companyaddress}
                                       setInputValue={setCompanyAddress}
                                       setErrorMsg={ErrCompanyAddress}
                        />
                    </div>
                </div>


                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldText label="Company Code"
                                       inputValue={companycode}
                                       setInputValue={setCompanyCode}
                                       setErrorMsg={ErrCompanyCode}
                        />
                    </div>
                    <div className='col-6'>
                        <FromFieldText label="Company Short Code"
                                       inputValue={companyshortcode}
                                       setInputValue={setCompanyShortCode}
                                       setErrorMsg={ErrCompanyShortCode}
                        />
                    </div>
                </div>


                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldText label="PAN Number" star="*"
                                       inputValue={pannumber}
                                       setInputValue={setPanNumber}
                                       setErrorMsg={ErrPanNumber}
                        />
                    </div>
                    <div className='col-6'>
                        <FromFieldText label="GST Number" star="*"
                                       inputValue={gstnumber}
                                       setInputValue={setGstNumber}
                                       setErrorMsg={ErrGstNumber}
                            //readOnly="readonly"
                        />
                    </div>
                </div>

                <div className='row single__asset__form'>
                    <div className='col-6'>

                        <label style={{marginRight: '10px'}}> Company Logo </label>
                        <input type="file" name='uploadLogo' multiple onChange={handleUploadLogo}/>
                        <span className="invalid-feedback"> {ErrCompanyLogo} </span>

                    </div>
                    <div className='col-6'>

                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-12'>
                        <h6>Contact Details</h6>
                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldText label="Website Name" star="*"
                                       inputValue={websitename}
                                       setInputValue={setWebsiteName}
                                       setErrorMsg={ErrWebsiteName}

                        />
                    </div>
                    <div className='col-6'>
                        <FromSpFieldText label="Website URL" star="*"
                                       inputValue={websiteurl}
                                       setInputValue={setWebsiteUrl}
                                       setErrorMsg={ErrWebsiteUrl}

                        />
                    </div>
                    <div className='col-6'>
                        <FromFieldNumber label="Landline No" star="*"
                                         inputValue={landlineno}
                                         setInputValue={setLandlineNo}
                                         setErrorMsg={ErrLandlineNo}
                        />
                    </div>
                </div>

                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldText label="Name of the Authorized Signatory" star="*"
                                       inputValue={authorizedsignatory}
                                       setInputValue={setAuthorizedSignatory}
                                       setErrorMsg={ErrAuthorizedSignatory}
                        />
                    </div>
                    <div className='col-6'>
                        <FromFieldText label="Mail Id" star="*"
                                       inputValue={authmailid}
                                       setInputValue={setAuthMailId}
                                       setErrorMsg={ErrAuthMailId}
                        />
                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldNumber label="Mobile Number" star="*"
                                         inputValue={authmobilenumber}
                                         setInputValue={setAuthMobileNumber}
                                         setErrorMsg={ErrAuthMobileNumber}
                        />
                    </div>
                    <div className='col-6'>

                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldText label="Name of the Contact Person" star="*"
                                       inputValue={contactperson}
                                       setInputValue={setContactPerson}
                                       setErrorMsg={ErrContactPerson}
                        />
                    </div>
                    <div className='col-6'>
                        <FromFieldText label="Contact Person Mail Id" star="*"
                                       inputValue={conpersonmailid}
                                       setInputValue={setConPersonMailId}
                                       setErrorMsg={ErrConPersonMailId}
                        />
                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldNumber label="Contact Person Mobile Number" star="*"
                                         inputValue={conpersonmobile}
                                         setInputValue={setConPersonMobile}
                                         setErrorMsg={ErrConPersonMobile}
                        />
                    </div>
                    <div className='col-6'>

                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-12'>
                        <h6>Contract Details</h6>
                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldDate
                            label="Date of Contract" star="*"
                            inputValue={dateofcontract}
                            setInputValue={setDateOfContract}
                            setErrorMsg={ErrDateOfContract}
                        />
                    </div>
                    <div className='col-6'>
                        <FromFieldText
                            label="Signed By" star="*"
                            inputValue={signedby}
                            setInputValue={setSignedBy}
                            setErrorMsg={ErrSignedBy}
                        />
                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldDate
                            label="DATA from Date" star="*"
                            inputValue={datafromdate}
                            setInputValue={setDataFromDate}
                            setErrorMsg={ErrDataFromDate}
                        />
                    </div>
                    <div className='col-6'>
                        <FromFieldDate
                            label="DATA to Date" star="*"
                            inputValue={datatodate}
                            setInputValue={setDataToDate}
                            setErrorMsg={ErrDataToDate}
                        />
                    </div>
                </div>
                <div className='row single__asset__form'>
                    <div className='col-6'>
                        <FromFieldDate
                            label="Contract from Date" star="*"
                            inputValue={contractfromdate}
                            setInputValue={setContractFromDate}
                            setErrorMsg={ErrContractFromDate}
                        />
                    </div>
                    <div className='col-6'>
                        <FromFieldDate
                            label="Contract to Date" star="*"
                            inputValue={contracttodate}
                            setInputValue={setContractToDate}
                            setErrorMsg={ErrContractToDate}
                        />
                    </div>
                </div>

                <div className='row single__asset__form'>

                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label> Is any other Accg GAAP Applicable </label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-6">
                                <select onChange={handleAccgGAAP} name="accgGAAP"
                                        className="form-select form-select-sm">
                                    <option value="">Select other Accg GAAP</option>
                                    <option value="Yes"
                                            selected={accgGAAP === "Yes" ? "selected" : ''}>Yes
                                    </option>
                                    <option value="No"
                                            selected={accgGAAP === "No" ? "selected" : ''}>No
                                    </option>
                                </select>
                                <span className="invalid-feedback">{ErrAccgGAAP} </span>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='row save__button text-center'>
                    <div className='col-md-12'>
                        {(CompanyId !== '' && CompanyId !== null) ? <div>
                                <button className="btn btn-primary btn-md">Update</button>
                                &nbsp;&nbsp;<a href={'/companyprofile?companyid=' + CompanyId}
                                               className="btn btn-secondary btn-md">Cancel</a></div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                      disabled={disabled}> SAVE </button>
                        }

                    </div>
                </div>
            </form>
        </div>
    )
}
