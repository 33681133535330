import React, {useEffect, useState} from 'react';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";
import * as CryptoJS from "crypto-js";
import {getAssetsDisposalData, getLastDinNo, saveAssetDisposal} from "../../../services/disposal.service";
import {DataGrid} from '@mui/x-data-grid';
import {getDisposalAssetsList} from '../../../services/asset.service'
import {useLocation} from "react-router-dom";

import FromSpFieldTextArea from '../../../atoms/FromSpFieldTextArea';

import AWS from 'aws-sdk';
// Configure AWS with your credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();


const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function AddFormSingleDisposal() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [dinno, setDinno] = useState('');
    const [dinnoError, setDinnoError] = useState('');

    const [saleInitiatedBy, setSaleInitiatedBy] = useState('');
    const [saleInitiatedError, setSaleInitiatedByError] = useState('');

    const [saleAuthorizedBy, setSaleAuthorizedBy] = useState('');
    const [saleAuthorizedByError, setSaleAuthorizedByError] = useState('');

    const [dateInitiation, setDateInitiation] = useState('');
    const [dateInitiationError, setDateInitiationError] = useState('');

    const [dateAuthorization, setDateAuthorization] = useState('');
    const [dateAuthorizationError, setdateAuthorizationError] = useState('');

    const [reasonForDisposal, setReasonForDisposal] = useState('');
    const [reasonForDisposalError, setReasonForDisposalError] = useState('');

    const [disposalDate, setDisposalDate] = useState('');
    const [disposalDateError, setDisposalDateError] = useState('');

    const [uploadMail, setUploadMail] = useState('');
    const [uploadInvoiceError, setUploadInvoiceError] = useState('');
    const [foundAssets, setFoundAssets] = useState();
    const [oldfoundAssets, setOldFoundAssets] = useState([]);
    const [disposalTag, setDisposalTag] = useState([]); 
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [listOptions1, setListData1] = useState([]);

    const [formSubmitted, setFormSubmitted] = useState(false);

    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    const [items, setItems] = useState([]);

    const columns = [
        {field: 'tag', headerName: 'TAG No', width: 200},
        {field: 'supplier_name', headerName: 'Supplier Name', width: 200},
        {field: 'financial_classification', headerName: 'Financial Classification', width: 200},
        {field: 'capitalized_value', headerName: 'Capitalized Value', width: 200},
        {field: 'acc_dep_date', headerName: 'Acc Dep upto Date', width: 200},
        {field: 'wdv_date', headerName: 'WDV Value on Date', width: 200},
        {field: 'disposal_type', headerName: 'Type of disposal', width: 200},
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
        },
    ];

    const [activeTab, setActiveTab] = useState(0);

    const rows = disposalTag.map((option) => ({
        id: option.tag, // Assuming tag is unique and can be used as the row id
        tag: option.tag,
        supplier_name: option.supplier_name,
        financial_classification: option.financials_classification,
        capitalized_value: option.total_cost_without_tax,
        acc_dep_date: option.depreciation_date,
        wdv_date: '',
        disposal_type: '',
        action: '',
    }));

    useEffect(() => {
        //encrypted data
        setLoading(true);
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getLastDinNo(oData).then(res => {

            let lastDinId = ((res.data.data?.res[0]?.id) ? res.data.data.res[0].id : 0) + 1;
            let newlastDinId = lastDinId.toString().padStart(3, '0');
            let currentYear = new Date().getFullYear();
            let divisionName = sessionStorage.LoggedInDivisionShortName;
            let setNewDinNo = divisionName + currentYear + newlastDinId;

            setDinno('DS' + setNewDinNo);
        });

        getDisposalAssetsList(oData).then(res => {
            if (res?.data?.data?.length) setFoundAssets(res.data.data); setOldFoundAssets(res.data.data); setLoading(false);
        })
        //setFoundAssets(asset_data);
        setListData1(listOptions1);

    }, []);

    const handleUploadInvoice = (e) => {
        setUploadMail(e.target.files);
        //setFileName(e.target.files[0].name);
    };

    const resetAssetDisposalInfo = function () {
        setDinno('');
        setSaleInitiatedBy('');
        setSaleAuthorizedBy('');
        setDateInitiation('');
        setDateAuthorization('');
        setReasonForDisposal('');
        setDisposalDate('');
        setUploadMail('');
        setListData1([]);
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();
        var valid_Name = true;

        // Validate the inputs before submitting the form

        if (!(dinno) && dinno !== 0) {
            setDinnoError('DIN is required');
            valid_Name = false;
        } else {
            setDinnoError('');
        }

        if (!(saleInitiatedBy) && saleInitiatedBy !== 0) {
            setSaleInitiatedByError('Sale initiated by is required');
            valid_Name = false;
        } else {
            setSaleInitiatedByError('');
        }

        if (!(dateInitiation) && dateInitiation !== 0) {
            setDateInitiationError('Date of initiation is required');
            valid_Name = false;
        } else {
            setDateInitiationError('');
        }

        if (!(dateAuthorization) && dateAuthorization !== 0) {
            setdateAuthorizationError('Date of authorization is required');
            valid_Name = false;
        } else {
            setdateAuthorizationError('');
        }

        if (!(saleAuthorizedBy) && saleAuthorizedBy !== 0) {
            setSaleAuthorizedByError('Sale authorized by is required');
            valid_Name = false;
        } else {
            setSaleAuthorizedByError('');
        }

        if (!(reasonForDisposal) && reasonForDisposal !== 0 && (!TEXT_SPCL_REGEX.test(reasonForDisposal))) {
            setReasonForDisposalError('Reason for disposal by is required');
            valid_Name = false;
        } else {
            setReasonForDisposalError('');
        }

        if (!(disposalDate) && disposalDate !== 0) {
            setDisposalDateError('Disposal date is required');
            valid_Name = false;
        } else {
            setDisposalDateError('');
        }
        /*if (uploadMail == '') {
            setUploadInvoiceError('Upload mail images is required');
            valid_Name = false;
            //return;
        } else {
            setUploadInvoiceError('');
        }*/


        if (valid_Name) {
            //alert('test1');
            //saveSingleDisposal(); //submit form
            handleNextClick();
        } else {
            return false;
        }
        // Perform form submission logic here
    };
    const [validName1, setValidName] = useState(true);
    const handleFormSubmit2 = (e) => {
        e.preventDefault();
        var valid_Name1 = true;

        if (listOptions1.length === 0) { //alert(listOptions1.length);
            setValidName(false);
            alert('Please select at least one Asset');
            valid_Name1 = false;
        }
        if (!valid_Name1) { //alert(valid_Name);
            return false;
        } else { //alert(mon); alert(fy);
            //saveData();
            handleNextClick();
        }
    };

    const handleFormSubmit3 = (e) => {
        e.preventDefault();
        var valid_Name1 = true;

        if (valid_Name1) {
            //alert('test1');
            saveSingleDisposal(); //submit form
            //saveDisposalTAG(); //submit form
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const saveSingleDisposal = function () {
        try {
            const file = uploadMail[0];
            let img_name ='';
            if(file){
                const fileName = file.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let img_name = dinno + '_' + '.' + fileExtension;

                //if (file) {
                    uploadFile(file, img_name);
                //}
                //oData.upload_file = img_name;
            }
            var obj = {
                'din_no': dinno,
                'company_id': sessionStorage.getItem('LoggedInCompanyId'),
                'division_id': sessionStorage.getItem('LoggedInDivisionId'),
                'department_id': sessionStorage.getItem('LoggedInDepartmentId'),
                'sale_initiated_by': saleInitiatedBy,
                'initiation_date': (dateInitiation ? getFormatedDate(dateInitiation) : ''),
                'authorization_date': (dateAuthorization ? getFormatedDate(dateAuthorization) : ''),
                'sale_authorized_by': saleAuthorizedBy,
                'disposal_reason': reasonForDisposal,
                'sale_date': (disposalDate ? getFormatedDate(disposalDate) : ''),
                'tag': listOptions1,
                'upload_file': file?img_name:'',
            }
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj, 'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveAssetDisposal(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetDisposalInfo();
                    setFormSubmitted(true);
                    /*setTimeout(function () {
                        // Redirect to secondpage.js after 10 seconds
                        window.location.href = 'addformone';
                    }, 500); // 10000 milliseconds = 10 seconds*/
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const handleNextClick = () => {
        // Handle the "Next" button click to move to the next tab
        setActiveTab(activeTab + 1);
    };

    const handlePrevClick = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setActiveTab(activeTab - 1);
    };

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = oldfoundAssets.filter((asset) => {
                return asset.tag.startsWith(keyword);
            });
            setFoundAssets(results);
        } else {
            setFoundAssets(oldfoundAssets);
            // If the text field is empty, show all assets
        }
        setName(keyword);
    };

    const handleAllMoveList1 = (e) => {
        //var listOptions = [];
        var options1 = document.getElementById('lstBox1').options;
        for (var i = 0, l = options1.length; i < l; i++) {
            var option1 = {
                label: options1[i].label,
                value: options1[i].value,
            }
            if (pushListOptions(option1)) {
                listOptions1.push(option1);
            }
            //listOptions1.push(option1);
        } //setListData2(listOptions1); console.log(listOptions2);
    }

    const handleMoveList1 = (e) => {
        const selectedOptions = [];

        var options1 = document.getElementById('lstBox1').options;
        //console.log(options1);
        for (var i = 0, l = options1.length; i < l; i++) {
            if (options1[i].selected) {
                var option1 = {
                    label: options1[i].label,
                    value: options1[i].value,
                }
                if (pushListOptions(option1)) {
                    //alert('dd');
                    listOptions1.push(option1);
                }
            }
        }


        setListData1((prevList) => prevList.filter((option) => !selectedOptions.find((selected) => selected.value === option.value)));

        const selectedTags = [];

        for (var i = 0, l = listOptions1.length; i < l; i++) {
            selectedTags[i] = "'" + listOptions1[i].value + "'";
        }

        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag_ids': selectedTags},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getAssetsDisposalData(oData).then(res => {
            //console.log(res?.data?.data?.res?.length);

            if (res?.data?.data?.res?.length) {
                setDisposalTag(res.data.data.res);
            }
        })
        console.log(disposalTag);
        //setListData2((prevList) => [...prevList, ...selectedOptions]);
    }

    const pushListOptions = (obj) => {
        var flag = 0;
        setLoading('..');
        listOptions1.forEach((elem) => {
            if (obj.value === elem.value && obj.label === elem.label) {
                flag = 1;
            }
        });
        setLoading(false);
        if (flag === 1) {
            return false;
        } else {
            return true;
        }
    };

    const handleAllRemove = (e) => { //alert('dd');
        //var options2 = document.getElementById('lstBox1').options;
        //alert(options.length);
        setListData1([]);
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const removeTag = (value) => {
        const confirmRemove = window.confirm("Are you sure you want to remove TAG from list?");
        if (confirmRemove) {
            listOptions1.pop(value);
            const updatedList = listOptions1.filter((item) => item !== value);
            setListData1(updatedList);
        }
    }

    const uploadFile = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'image/' + img_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                return params.name;
            }
        });
    };


    return (
        <div className='container'>

            <div className="tab-content">

                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>

                    <div className="row">
                        <div className="col">
                            <h5>Initiate Single Disposal</h5>
                        </div>


                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="DIN No"
                                placeholder="DIN No"
                                inputValue={dinno}
                                setInputValue={setDinno}
                                setErrorMsg={dinnoError}
                            />

                        </div>
                        <div className="col-6">
                            <FromFieldLabel
                                label="Disposal Identification No"
                                inputValue=''
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Sale Initiated By"
                                placeholder="Sale Initiated By"
                                inputValue={saleInitiatedBy}
                                setInputValue={setSaleInitiatedBy}
                                setErrorMsg={saleInitiatedError}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldDate
                                label="Date of Initiation"
                                placeholder="Date of Initiation"
                                inputValue={dateInitiation}
                                setInputValue={setDateInitiation}
                                setErrorMsg={dateInitiationError}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Sale Authorized By"
                                placeholder="Sale Authorized By"
                                inputValue={saleAuthorizedBy}
                                setInputValue={setSaleAuthorizedBy}
                                setErrorMsg={saleAuthorizedByError}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldDate
                                label="Date of Authorization"
                                placeholder="Date of Authorization"
                                inputValue={dateAuthorization}
                                setInputValue={setDateAuthorization}
                                setErrorMsg={dateAuthorizationError}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromSpFieldTextArea numRows = "3" maxLength = "500"
                                label="Reason for Disposal"
                                placeholder="Reason for Disposal"
                                inputValue={reasonForDisposal}
                                setInputValue={setReasonForDisposal}
                                setErrorMsg={reasonForDisposalError}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldDate
                                label="Target Date for Disposal"
                                placeholder="Date for Disposal"
                                inputValue={disposalDate}
                                setInputValue={setDisposalDate}
                                setErrorMsg={disposalDateError}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <label style={{marginRight: '10px'}}> Upload approval mail </label>
                            <input type="file" name='uploadInvoice' multiple onChange={handleUploadInvoice}/>
                            <span className="invalid-feedback"> {uploadInvoiceError} </span>
                        </div>
                    </div>

                    <div className="row mt-4 text-center">
                        <form name="" onSubmit={handleFormSubmit}>
                            <button className="btn btn-primary btn-md"> Next <i class="bi bi-arrow-right"></i></button>
                        </form>
                        <div className="col-4"></div>
                    </div>
                </div>

                <div className={`tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>

                    <div className="row">
                        <div className="col">
                            <h5>Initiate Single Disposal- Add Form One</h5>
                        </div>
                    </div>

                    <div className="subject-info-box-1">
                        <input type="search" value={name} onChange={filter} className="form-control" placeholder="Filter" />
                        <select multiple="multiple" id='lstBox1' className="form-select form-select-sm">
                            {foundAssets && foundAssets.length > 0 ? (
                                foundAssets.map((option) => (
                                    <option value={option.tag}>{option.tag}</option>
                                ))
                            ) : (
                                <option disabled>No results</option>
                            )}
                        </select>
                        <p>Total count: {foundAssets ? foundAssets.length : 0}</p>
                    </div>


                    <div className="subject-info-arrows text-center">{loading ? (
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">{loading}</span>
                        </div>) : ('')}

                        <input type='button' id='btnAllRight' onClick={handleAllMoveList1} value='Add All'
                               className="btn btn-default"/><br/>
                        <input type='button' id='btnRight' onClick={handleMoveList1} value='>'
                               className="btn btn-default"/><br/>

                        <input type='button' id='btnAllLeft' onClick={handleAllRemove} value='Remove All'
                               className="btn btn-default"/>
                    </div>

                    <div className="subject-info-box-2">
                        <select multiple="multiple" id='lstBox2' className="form-select form-select-sm">
                            {listOptions1.map((option) => (
                                <option value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <p>Total count: {listOptions1.length}</p>
                    </div>


                    <div className="clearfix"></div>


                    <div className="row mt-4 text-center">

                        <div className="col-md-4">
                            <form name="" onSubmit={handlePrevClick}>
                                <button className="btn btn-secondary btn-md float-end"><i class="bi bi-arrow-left"></i> Back</button>
                            </form>
                        </div>

                        <div className="col-md-4">
                            <form name="" onSubmit={handleFormSubmit2}>
                                <button className="btn btn-primary btn-md float-end"> Next <i class="bi bi-arrow-right"></i></button>
                            </form>
                        </div>

                    </div>

                </div>

                <div className={`tab-pane ${activeTab === 2 ? 'active' : 'hidden'}`}>

                    <div className="row">
                        <div className="col">
                            <h5>Initiate Single Disposal- Add Form One</h5>
                        </div>
                    </div>


                    {success ? (<div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i> {success}</div>) : (errMsg ? (
                        <div className="alert alert-danger" role="alert"><i
                            className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>) : (''))}

                    <div style={{height: 400, width: '100%'}}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                        />
                    </div>


                    <div className="row mt-4">

                        <div className="col-md-4">
                            <form name="" onSubmit={handlePrevClick}>
                                <button className="btn btn-secondary btn-md float-end"><i class="bi bi-arrow-left"></i> Back</button>
                            </form>
                        </div>

                        <div className="col-md-4">
                            <form name="" onSubmit={resetAssetDisposalInfo}>
                                <button className="btn btn-secondary btn-md float-end"> Cancel</button>
                            </form>
                        </div>

                        <div className="col-md-4">
                            {!formSubmitted && (
                                <form name="" onSubmit={handleFormSubmit3}>
                                    <button className="btn btn-primary btn-md float-end">INITIATE / UPDATE</button>
                                </form>
                            )}
                        </div>

                    </div>

                </div>


            </div>

        </div>

    )
}

export default AddFormSingleDisposal;