import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField, InputAdornment } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import './FromFieldDate.css'; // Import custom styles

function FromFieldDate(props) {
    const [open, setOpen] = useState(false);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="row">
                <div className="col-md-6">
                    <label> {props.label} </label>
                    <span className="star error"> {props.star} </span>
                </div>
                <div className="col-md-6">
                    <DatePicker
                        format='yyyy-MM-dd'
                        openTo="year"
                        views={['year', 'month','day']}
                        value={props.inputValue}
                        onChange={(newDate) => props.setInputValue(newDate, 'yyyy-MM-dd')}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={props.placeholder ? props.placeholder : 'YYYY-MM-DD'}
                                onClick={() => setOpen(true)}
                                fullWidth
                                error={Boolean(props.setErrorMsg)}
                                helperText={props.setErrorMsg}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CalendarTodayIcon style={{ color: '#6c757d' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                
                                className="custom-date-picker-input"
                            />
                        )}
                    />
                </div>
            </div>
        </LocalizationProvider>
    );
}

export default FromFieldDate;
