import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {getCountryInfo, getContinentList, saveCountryName} from '../../services/user.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
function AddCountryName() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [countryName, setCountryName] = useState('');
    const [ErrCountryName, setErrCountryName] = useState(false);

    const [countryCode, setCountryCode] = useState('');
    const [ErrCountryCode, setErrCountryCode] = useState(false);

    const [continentId, setContinentId] = useState('');
    const [ErrContinentId, setErrContinentId] = useState(false);

    const [continents, setContinents] = useState([]);

    const resetUserInfo = function () {
        setContinentId('');
        setCountryName('');
        setCountryCode('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Country_ID = query.get('country_id');

    useEffect(() => {
        if (Country_ID !== '' && Country_ID !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(Country_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptCountryId = decrypt_data.country_id;
            getCountryData(decryptCountryId); // get comment info
        } else {
            resetUserInfo();
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getContinentList(oData).then(res => {
            if (res?.data?.data?.length) setContinents(res.data.data);
        })
    }, [])

    const getCountryData = function (decryptCountryId) {
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'country_id': decryptCountryId },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData1 = { 'token': EncryptData };

        getCountryInfo(oData1).then(function (res) {

            if (res?.data?.data?.length > 0) {
                const continentInfo = res.data.data[0];
                setContinentId(continentInfo?.continent_id || ''); // Fallback to empty string
                setCountryName(continentInfo?.country_name || ''); // Fallback to empty string
                setCountryCode(continentInfo?.country_code || ''); // Fallback to empty string
            } else {
                console.error("Country data is empty or undefined.");
                setErrMsg("Failed to fetch country data.");
            }
        }).catch(function (error) {
            console.error("Error fetching country data:", error);
            setErrMsg("Unable to fetch country details. Please try again.");
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(continentId)) {
            setErrContinentId('Continent name is required');
            valid_Name = false;
        } else {
            setErrContinentId('');
        }
        if (!TEXT_REGEX.test(countryName)) { //alert(name);
            setErrCountryName('Country name is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrCountryName('');
        }if (!TEXT_REGEX.test(countryCode)) { //alert(name);
            setErrCountryCode('Country code is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrCountryCode('');
        }
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {  //alert('fa');
            return false;
        }
    }

    const saveData = function () {
        try {

            if (Country_ID !== '' && Country_ID !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(Country_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedCountryId = decrypt_data.country_id;
                var obj = {
                    'coun_id': decryptedCountryId,
                    'continent_id': continentId,
                    'country_name': countryName,
                    'country_code': countryCode,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'continent_id': continentId,
                    'country_name': countryName,
                    'country_code': countryCode,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            }


            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveCountryName(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    const handleContinentId = (e) => {
        setContinentId(e.target.value);
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col-6">
                    <h5>Add Country Name</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>

            </div>
            <form onSubmit={handleSubmit}>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Continent</label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleContinentId}>
                            <option value="">Select Continent</option>
                            {continents && continents.length > 0 ? (
                                continents.map((option) => (
                                    <option value={option.id}
                                            selected={(option.id == continentId) ? "selected" : ''}>{option.continent_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrContinentId} </span>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Country Name"
                                       inputValue={countryName}
                                       setInputValue={setCountryName}
                                       setErrorMsg={ErrCountryName}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6"></div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Country Code"
                                       inputValue={countryCode}
                                       setInputValue={setCountryCode}
                                       setErrorMsg={ErrCountryCode}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6"></div>
                </div>

                <div className="row mt-2">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary  btn-sm"> SAVE</button>


                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default AddCountryName


