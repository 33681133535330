import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {getContinentInfo, saveContinentName} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import {add} from "react-modal/lib/helpers/classList";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
function AddContinent() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [addContinent, setContinent] = useState('');
    const [ErrContinent, setErrContinent] = useState(false);

    const [continentCode, setContinentCode] = useState('');
    const [ErrContinentCode, setErrContinentCode] = useState(false);

    const resetUserInfo = function () {
        setContinent('');
        setContinentCode('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Continent_ID = query.get('continent_id');

    useEffect(() => {
        if (Continent_ID !== '' && Continent_ID !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(Continent_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptContinentId = decrypt_data.continent_id;
            getCountryData(decryptContinentId); // get comment info
        } else {
            resetUserInfo();
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
    }, [])

    const getCountryData = function (decryptContinentId) {
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'continent_id': decryptContinentId },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData1 = { 'token': EncryptData };

        getContinentInfo(oData1).then(function (res) {

            if (res?.data?.data?.length > 0) {
                const continentInfo = res.data.data[0];
                setContinent(continentInfo?.continent_name || ''); // Fallback to empty string
                setContinentCode(continentInfo?.continent_code || ''); // Fallback to empty string
            } else {
                console.error("Continent data is empty or undefined.");
                setErrMsg("Failed to fetch continent data.");
            }
        }).catch(function (error) {
            console.error("Error fetching continent data:", error);
            setErrMsg("Unable to fetch continent details. Please try again.");
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(addContinent)) { //alert(name);
            setErrContinent('Continent name is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrContinent('');
        }if (!TEXT_REGEX.test(continentCode)) { //alert(name);
            setErrContinentCode('Continent code is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrContinentCode('');
        }
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {  //alert('fa');
            return false;
        }
    }

    const saveData = function () {
        try {

            if (Continent_ID !== '' && Continent_ID !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(Continent_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedContinentId = decrypt_data.continent_id;
                var obj = {
                    'cont_id': decryptedContinentId,
                    'continent_name': addContinent,
                    'continent_code': continentCode,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'continent_name': addContinent,
                    'continent_code': continentCode,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            }
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveContinentName(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col-6">
                    <h5>Add Continent Name</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>

            </div>
            <form onSubmit={handleSubmit}>
                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Continent Name"
                                       inputValue={addContinent}
                                       setInputValue={setContinent}
                                       setErrorMsg={ErrContinent}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6"></div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Continent Code"
                                       inputValue={continentCode}
                                       setInputValue={setContinentCode}
                                       setErrorMsg={ErrContinentCode}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6"></div>
                </div>

                <div className="row mt-2">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary  btn-sm"> SAVE</button>


                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default AddContinent


