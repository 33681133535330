import axios from "axios";
var baseURL = process.env.REACT_APP_API_BASE_URL;
var apis = {
/*  'checkLogin' : `${baseURL}users/login/`,
    'registerUser' : `${baseURL}users/add/`,
    'saveCompanyProfile' : `${baseURL}companyprofile/add/`,
    'saveDivision' : `${baseURL}companyprofile/add/`,
    'getDivisioin' : `${baseURL}user/division/list/`,  */
    'getDashboardDetails' :`${baseURL}dashboard/details`,
    'getAssets' : `${baseURL}companyasset`,
    'getAssetsCSV' : `${baseURL}companyasset/getassetscsv`,
    'getAuditLogCSV' : `${baseURL}companyasset/getauditlogcsv`,
    'getAssetsRepositoryCSV' : `${baseURL}companyasset/getassetsrepositorycsv`,
    'getConsumableAssetsCSV' : `${baseURL}companyasset/getconsumableassetscsv`,
    'getAllConsumableAssetsCSV' : `${baseURL}companyasset/getallconsumableassetscsv`,
    'getPastAssetsCSV' : `${baseURL}companyasset/getpastassetscsv`,
    'getMyPastAssetsCSV' : `${baseURL}companyasset/getmypastassetscsv`,
    'getBranchPastAssetsCSV' : `${baseURL}companyasset/getbranchpastassetscsv`,
    'getBranchPastAssets' : `${baseURL}companyasset/branchpastassets`,
    'getAsset' : `${baseURL}companyasset/`,
    'getConsumableAssets' : `${baseURL}companyasset/getconsumableassets`,
    'getAllConsumableAssets' : `${baseURL}companyasset/getallconsumableassets`,
    'getMyPastAssets' : `${baseURL}companyasset/mypastassets`,
    'getPastAssets' : `${baseURL}companyasset/pastassets`,
    'getAssetsRepository' : `${baseURL}companyasset/assetsrepository`,
    'getParentAssets' : `${baseURL}companyasset/parent`,
    'getStickeringAssets' : `${baseURL}companyasset/stickeringassets`,
    'getCountOfTagByPrefix': `${baseURL}companyasset/prefixtag`,
    'getCountOfChildTag': `${baseURL}companyasset/childtagcount`,
    'getAllAssetsTag' : `${baseURL}companyasset/allassetstag`,
    'getSuppliers' : `${baseURL}companyasset/allassetsupplier`,
    'getInvoices' : `${baseURL}companyasset/allassetinvoice`,

    'getRepairLog' : `${baseURL}companyasset/getrepairlog`,
    'getDepreciation' : `${baseURL}companyasset/getdepresiation`,
    'getAllDepreciation' : `${baseURL}companyasset/getalldepresiation`,
    'getAllDepreciationLog' : `${baseURL}companyasset/getalldepresiationlog`,
    'getDepresiationSummery' : `${baseURL}companyasset/depresiationsummery`,
    'getFarDepresiation' : `${baseURL}companyasset/fardepresiation`,
    'getFarDepreciationCSV':`${baseURL}companyasset/fardepreciationcsv`,
    'getAdjustment' : `${baseURL}companyasset/depradjustment`,
    'uploadAssets' : `${baseURL}companyasset/uploadexcel`,
    'uploadAssetsCapt' : `${baseURL}companyasset/uploadcaptexcel`,
    'uploadAllocation' : `${baseURL}assetallocation/uploadallocationexcel`,
    'uploadBulkFlagAsset' : `${baseURL}assetallocation/uploadflagassetexcel`,
    'uploadStfBulkFlagAsset' : `${baseURL}assetallocation/upload_stf_flag_asset`,

    'deleteUploadTAG' : `${baseURL}assetallocation/deleteuploadtagexcel`,


    'getAssetDetails' : `${baseURL}assetallocation/getassetdetails`,


    'getAllocation' : `${baseURL}assetallocation/getallocation`,
    'getAllAllcationData': `${baseURL}assetallocation/getallallocation`,
    'getNotAllocatedCSV': `${baseURL}companyasset/getnotallocatedcsv`,
    'getAllocationCSV': `${baseURL}assetallocation/getallocationcsv`,
    'getNotAllocableCSV': `${baseURL}assetallocation/getnotallocablecsv`,
    'getCapitalizedCSV': `${baseURL}assetallocation/getcapitalizedcsv`,
    'getNotCapitalizedCSV': `${baseURL}assetallocation/getnotcapitalizedcsv`,
    'getPendingCapitalizedCSV': `${baseURL}assetallocation/getpendingcapitalizedcsv`,

    'getNotAllcatedData': `${baseURL}assetallocation/getnotallocated`,
    'getNotAllocatedFlag': `${baseURL}assetallocation/getnotallocatedflag`,
    'saveUnallocation' :`${baseURL}assetallocation/saveunallocated`,
    'saveAllocation' :`${baseURL}assetallocation/saveallocation`,
    'getNotAllocableData' :`${baseURL}assetallocation/getnotallocabledata`,

    'linkRFIDTAG' :`${baseURL}assetallocation/linkrfidtag`,
    'getRFIDReadersList' :`${baseURL}assetallocation/getrfidreaderslist`,
    'getTAGMappingData' :`${baseURL}assetallocation/gettagmappingdata`,
    'getRFIDMappingList' :`${baseURL}assetallocation/getrfidmappinglist`,

    'deleteTAG' :`${baseURL}assetallocation/deletetag`,

    'saveComment' :`${baseURL}assetallocation/savecomment`,

    'savePartialCaptalizedValue' :`${baseURL}assetallocation/savepartialcaptalizedvalue`,
    'updateQRStatus' :`${baseURL}assetallocation/updateqrstatus`,

    'getComment' :`${baseURL}assetallocation/getcomment`,
    'getAllCommentData' :`${baseURL}assetallocation/getallcommentdata`,
    'getCommentListByTag' :`${baseURL}assetallocation/getcommentlistBytag`,

    'getIncomeInfo' :`${baseURL}assetallocation/getincomeinfo`,
    'saveIncomeTax' :`${baseURL}assetallocation/saveincometax`,
    'getAllIncomeTaxData' :`${baseURL}assetallocation/getallincometaxdata`,
    'deleteIncomeTaxData' :`${baseURL}assetallocation/deleteincometaxdata`,

    'getAuditLogData' :`${baseURL}assetallocation/getAuditLogData`,


    'getAllCostCenterData' :`${baseURL}assetallocation/getallcostcenterdata`,
    'getCostCenterByCompany' :`${baseURL}assetallocation/getcostcenterbycompany`,
    'saveCostCenter' :`${baseURL}assetallocation/savecostcenter`,
    'getCostCenterInfo' :`${baseURL}assetallocation/getcostcenterinfo`,
    'deleteCostCenter' :`${baseURL}assetallocation/deletecostcenter`,

    'deleteBranch' :`${baseURL}assetallocation/deletebranch`,
    'deleteDepartmentGroup' :`${baseURL}assetallocation/deletedepartmentgroup`,
    'deleteDepartment' :`${baseURL}assetallocation/deletedepartment`,
    'deleteSubDepartment' :`${baseURL}assetallocation/deletesubdepartment`,
    'deleteCompanyProfile' :`${baseURL}assetallocation/deletecompanyprofile`,
    'deleteRole' :`${baseURL}assetallocation/deleterole`,


    'getAllScanSerialNoData' :`${baseURL}assetallocation/getallscanserialnodata`,
    'getDisposalAssetsList' :`${baseURL}assetdisposal/getdisposalassetslist`,

    'getTagList' :`${baseURL}assetallocation/gettaglist`,

    

    'saveYearName' :`${baseURL}assetallocation/saveyearname`,
    'getYearNameInfo' :`${baseURL}assetallocation/getyearnameinfo`,
    'getYearNameList' :`${baseURL}assetallocation/getyearnamelist`,
    'deleteYearName' :`${baseURL}assetallocation/deleteyearname`,
    'saveFlagAsset' :`${baseURL}assetallocation/saveflag`,
    'updateFlagAsset' :`${baseURL}assetallocation/updateflag`,
    'cancelFlagAsset' :`${baseURL}assetallocation/cancelflag`,
    'acceptFlagAsset' :`${baseURL}assetallocation/acceptflag`,
    'getFlagAssetOut' :`${baseURL}assetallocation/getflagout`,
    'getFlagAssetIn' :`${baseURL}assetallocation/getflagin`,
    'getNonUseableList' :`${baseURL}assetallocation/getnonuseablelist`,
    'getDisposaleList' :`${baseURL}assetallocation/getdisposalelist`,
    'getAllocationLog':`${baseURL}assetallocation/allocationlog`,
    'cellUpdate' : `${baseURL}file/cellupdate`,
    'saveSTPI' :`${baseURL}assetstpi`,
    'createSTPI' :`${baseURL}assetstpi/create`,
    'getAllSTPI' :`${baseURL}assetstpi/getall`,
    'saveSingle' :`${baseURL}assetsingle`,
    'saveSingleImage':`${baseURL}assetsingle/uploadimage`,
    'saveSingleExcel':`${baseURL}assetsingle/uploadexcel`,
    'saveSingleInvoice':`${baseURL}assetsingle/uploadinvoice`,
    'saveSingleCertificate':`${baseURL}assetsingle/uploadCertificate`,
    'getAssetsImages': `${baseURL}assetsingle/allassetimages`,
    'getAllImagesByTag': `${baseURL}assetsingle/assetallimages`,
    'getAssetsInvoiceData': `${baseURL}assetsingle/allassetinvoicedata`,
    //'getAssetsInvoiceData': `${baseURL}assetsingle/allassetinvoicedata`,
    'getAssetInvoiceCSVData': `${baseURL}assetsingle/getassetinvoicecsvdata`,
    'getAssetImagesCSVData': `${baseURL}assetsingle/getassetimagescsvdata`,
    'getAssetsCertificatesCSVData': `${baseURL}assetsingle/getassetscertificatescsvdata`,
    'getAssetsInvoiceCount': `${baseURL}assetsingle/getassetsinvoicecount`,
    'getAssetsCertificateCount': `${baseURL}assetsingle/getassetscertificatecount`,
    'getAssetsCertificate': `${baseURL}assetsingle/allassetcertificate`,
    'downloadFile':`${baseURL}assetsingle/downloadfile`,
    'getRepositoryDetails' :`${baseURL}assetstpi/repositorybasicdetails`,

    'saveCapitalize' :`${baseURL}assetcapitalize`,
    'getCapitalized' :`${baseURL}assetcapitalize/capitalized`,
    'getNotCapitalized' :`${baseURL}assetcapitalize/notcapitalized`,
    'getPendingCapitalized' :`${baseURL}assetcapitalize/pendingcapitalized`,
    'calculateDepreciation':`${baseURL}calculatedepreciation`,
    'calculateOtherDepreciation':`${baseURL}calculatedepreciation/other`,
    'createMonthDepreciation':`${baseURL}monthdepreciation/create`, 
    'lockMonthDepreciation':`${baseURL}monthdepreciation/lockmonth`, 
    'getCreateMonthData':`${baseURL}monthdepreciation/getdata`,
    'saveAdjustment' :`${baseURL}assetadjustment`,
    'uploadAdjustment':`${baseURL}uploadadjustment`,
    'uploadDepreciation':`${baseURL}uploaddepreciation`,
    'saveDeletion' :`${baseURL}assetdisposal/deletion`,
    'getAssetTransfer' :`${baseURL}asset/gettransfer`,
    'allAssetTransfer' :`${baseURL}asset/getalltransfer`,
    
    'filterAssetsData':`${baseURL}asset/filterdata`,
    'filterQrAssetsData':`${baseURL}asset/filterqrdata`,
    'getFilterAssetsLogData':`${baseURL}asset/getfilterassetslogdata`,
    'getFilterReaderData':`${baseURL}asset/getfilterreaderdata`,
    'getActiveInactiveData':`${baseURL}asset/getactiveinactivedata`,
    'getReaderList':`${baseURL}asset/getreaderlist`,


    'assetDisposal' :`${baseURL}assetdisposal`,
    'assetPermanentTransfer':`${baseURL}permanenttransfer`,
    'assetTemproryTransfer':`${baseURL}temprorytransfer`,
    'savePhyVeriData' :`${baseURL}physicalverfication`,
    'savePhyVeriMaster' :`${baseURL}physicalverfication/master`,
    'getPhyVeriMaster' :`${baseURL}physicalverfication/masterlist`,
    'getSinglePhyVeriMaster' :`${baseURL}physicalverfication/singlemasterdata`,
    'getSeekExtRequest' :`${baseURL}physicalverfication/seekextrequest`,
    'getNotPhyVerified' :`${baseURL}physicalverfication/notphyverified`,
    'getPhyVeriReport' :`${baseURL}physicalverfication/pvreport`,
    'filterPvData':`${baseURL}physicalverfication/filterpvdata`,
    'getFilterNotVerifiedData':`${baseURL}physicalverfication/filternotverifieddata`,
    'getFilterNotIdentifiable':`${baseURL}physicalverfication/filternotidentifiable`, 
    'getNotPhysicalVerifiedAssetsCSV':`${baseURL}physicalverfication/getnotphysicalverifiedassetscsv`,
    'getFilterNotIdentifiableCSV':`${baseURL}physicalverfication/getfilternotidentifiablecsv`,
    'getPhysicalVerifiedAssetsCSV':`${baseURL}physicalverfication/getphysicalverifiedassetscsv`,
    'deletePVTAG':`${baseURL}physicalverfication/deletepvtag`,
    'deleteUploadPVTAG':`${baseURL}physicalverfication/deleteuploadpvtag`,

    'getTAGDetails':`${baseURL}assetallocation/gettagdetails`,
    'saveGatePass':`${baseURL}assetallocation/savegatepass`,
    'saveGatePassRemark':`${baseURL}assetallocation/savegatepassremark`,
    'getGatePassList':`${baseURL}assetallocation/getgatepasslist`,
    'getAllGatePassList':`${baseURL}assetallocation/getallgatepasslist`,
    'getGatePassData':`${baseURL}assetallocation/getgatepassdata`,
    'getGatePassDetailByGatePassNo':`${baseURL}assetallocation/getgatepassdetailbygatepassno`,
    'updateGatePass':`${baseURL}assetallocation/updategatepass`,
    'updateGatePassRemark':`${baseURL}assetallocation/updategatepassremark`,
    'getAllGatePassTAG':`${baseURL}assetallocation/getallgatepasstag`,
    'saveReceiveAssetRemark':`${baseURL}assetallocation/savereceiveassetremark`,
    'saveReceiveAsset':`${baseURL}assetallocation/savereceiveasset`,

    'getDashboardUserCount':`${baseURL}dashboard/getdashboardusercount`,
    'getUsersList':`${baseURL}dashboard/getuserslist`,
    'getUserLogData' : `${baseURL}dashboard/getuserlogdata`,


}
/*
export function loginUser(data) {
    return axios.put(apis.checkLogin,data);
}
export function registerUser(data) {
    return axios.put(apis.registerUser,data);
}
export function saveCompanyProfile(data) {
    return axios.put(apis.saveCompanyProfile,data);
} */
export function getAssets(data = {'company_id' : sessionStorage.getItem('LoggedInToken')}) {
    return axios.put(apis.getAssets,data);
}

export function getAssetsCSV(data) {
    return axios.put(apis.getAssetsCSV,data);
}
export function getAuditLogCSV(data) {
    return axios.put(apis.getAuditLogCSV,data);
}
export function getAssetsRepositoryCSV(data) {
    return axios.put(apis.getAssetsRepositoryCSV,data);
}
export function getConsumableAssetsCSV(data) {
    return axios.put(apis.getConsumableAssetsCSV,data);
}
export function getAllConsumableAssetsCSV(data) {
    return axios.put(apis.getAllConsumableAssetsCSV,data);
}
export function getPastAssetsCSV(data) {
    return axios.put(apis.getPastAssetsCSV,data);
}
export function getMyPastAssetsCSV(data) {
    return axios.put(apis.getMyPastAssetsCSV,data);
}
export function getAllocationCSV(data) {
    return axios.put(apis.getAllocationCSV,data);
}
export function getBranchPastAssets(data) {
    return axios.put(apis.getBranchPastAssets,data);
}
export function getBranchPastAssetsCSV(data) {
    return axios.put(apis.getBranchPastAssetsCSV,data);
}
export function getConsumableAssets(data) {
    return axios.put(apis.getConsumableAssets,data);
}
export function getAllConsumableAssets(data) {
    return axios.put(apis.getAllConsumableAssets,data);
}

export function getAsset(assetId) {
    return axios.get(`${apis.getAsset}/${assetId}`);
}
export function getAssetsRepository(data) {
    return axios.put(apis.getAssetsRepository,data);
}
export function getPastAssets(data) {
    return axios.put(apis.getPastAssets,data);
}
export function getMyPastAssets(data) {
    return axios.put(apis.getMyPastAssets,data);
}
export function getParentAssets(data) {
    return axios.put(apis.getParentAssets,data);
}
export function getStickeringAssets(data) {
    return axios.put(apis.getStickeringAssets,data);
}
export function getCountOfTagByPrefix(data) {
    return axios.put(apis.getCountOfTagByPrefix,data);
}
export function getCountOfChildTag(data) {
    return axios.put(apis.getCountOfChildTag,data);
}
export function getAllAssetsTag(data) {
    return axios.put(apis.getAllAssetsTag,data);
}
export function getSuppliers(data) {
    return axios.put(apis.getSuppliers,data);
}
export function getInvoices(data) {
    return axios.put(apis.getInvoices,data);
}
export function getAssetsImages(data) {
    return axios.put(apis.getAssetsImages,data);
}
export function getAllImagesByTag(data) {
    return axios.put(apis.getAllImagesByTag,data);
}
export function getAssetsInvoices(data) {
    return axios.put(apis.getAssetsInvoiceData,data);
}

export function getAssetsImagesCount(data) {
    return axios.put(apis.getAssetsImagesCount,data);
}

export function getAssetsCertificateCount(data) {
    return axios.put(apis.getAssetsCertificateCount,data);
}

export function getAssetsInvoiceCount(data) {
    return axios.put(apis.getAssetsInvoiceCount,data);
}
export function getAssetsCertificates(data) {
    return axios.put(apis.getAssetsCertificate,data);
}
export function getDepreciation(data) {
    return axios.put(apis.getDepreciation,data);
}export function getRepairLog(data) {
    return axios.put(apis.getRepairLog,data);
}
export function getAllDepreciation(data) {
    return axios.put(apis.getAllDepreciation,data);
}
export function getAllDepreciationLog(data) {
    return axios.put(apis.getAllDepreciationLog,data);
}
export function getDepresiationSummery(data) {
    return axios.put(apis.getDepresiationSummery,data);
}
export function getFarDepresiation(data) {
    return axios.put(apis.getFarDepresiation,data);
}
export function getFarDepreciationCSV(data) {
    return axios.put(apis.getFarDepreciationCSV,data);
}
export function uploadAssets(data) {
    return axios.post(apis.uploadAssets,data);
}
export function uploadAssetsCapt(data) {
    return axios.post(apis.uploadAssetsCapt,data);
}
export function uploadAllocation(data) {
    return axios.post(apis.uploadAllocation,data);
}
export function uploadBulkFlagAsset(data) {
    return axios.post(apis.uploadBulkFlagAsset,data);
}
export function uploadStfBulkFlagAsset(data) {
    return axios.post(apis.uploadStfBulkFlagAsset,data);
}
export function deleteUploadTAG(data) {
    return axios.post(apis.deleteUploadTAG,data);
}

export function updateCell(id,data) {
    return axios.put(apis.cellUpdate+"/"+id,data);
}
export function getAllocation(data) {
    return axios.put(apis.getAllocation,data);
}

export function getAssetDetails(data) {
    return axios.put(apis.getAssetDetails,data);
}

export function getComment(data) {
    return axios.put(apis.getComment,data);
}
export function getIncomeInfo(data) {
    return axios.put(apis.getIncomeInfo,data);
}
export function saveIncomeTax(data) {
    return axios.put(apis.saveIncomeTax,data);
}
export function getAllIncomeTaxData(data) {
    return axios.put(apis.getAllIncomeTaxData,data);
}

export function deleteIncomeTaxData(data) {
    return axios.put(apis.deleteIncomeTaxData,data);
}

export function getAuditLogData(data) {
    return axios.put(apis.getAuditLogData,data);
}

export function getAllAllcationData(data) {
    return axios.put(apis.getAllAllcationData,data);
}
export function getNotAllocatedCSV(data) {
    return axios.put(apis.getNotAllocatedCSV,data);
}
export function getNotAllocableCSV(data) {
    return axios.put(apis.getNotAllocableCSV,data);
}
export function getCapitalizedCSV(data) {
    return axios.put(apis.getCapitalizedCSV,data);
}
export function getPendingCapitalizedCSV(data) {
    return axios.put(apis.getPendingCapitalizedCSV,data);
}
export function getNotCapitalizedCSV(data) {
    return axios.put(apis.getNotCapitalizedCSV,data);
}
export function getAllCommentData(data) {
    return axios.put(apis.getAllCommentData,data);
}
export function getCommentListByTag(data) {
    return axios.put(apis.getCommentListByTag,data);
}

export function getAllCostCenterData(data) {
    return axios.put(apis.getAllCostCenterData,data);
}
export function getCostCenterByCompany(data) {
    return axios.put(apis.getCostCenterByCompany,data);
}
export function getCostCenterInfo(data) {
    return axios.put(apis.getCostCenterInfo,data);
}

export function deleteCostCenter(data) {
    return axios.put(apis.deleteCostCenter,data);
}

export function deleteBranch(data) {
    return axios.put(apis.deleteBranch,data);
}
export function deleteDepartmentGroup(data) {
    return axios.put(apis.deleteDepartmentGroup,data);
}

export function deleteCompanyProfile(data) {
    return axios.put(apis.deleteCompanyProfile,data);
}

export function deleteRole(data) {
    return axios.put(apis.deleteRole,data);
}

export function deleteDepartment(data) {
    return axios.put(apis.deleteDepartment,data);
}
export function deleteSubDepartment(data) {
    return axios.put(apis.deleteSubDepartment,data);
}

export function saveCostCenter(data) {
    return axios.put(apis.saveCostCenter,data);
}

export function getAllScanSerialNoData(data) {
    return axios.put(apis.getAllScanSerialNoData,data);
}

export function getNotAllcatedData(data) {
    return axios.put(apis.getNotAllcatedData,data);
}
export function getNotAllocatedFlag(data) {
    return axios.put(apis.getNotAllocatedFlag,data);
}
export function getNotAllocableData(data) {
    return axios.put(apis.getNotAllocableData,data);
}
export function getAllocationLog(data) {
    return axios.put(apis.getAllocationLog,data);
}
export function saveAllocation(data) {
    return axios.put(apis.saveAllocation,data);
}

export function deleteTAG(data) {
    return axios.put(apis.deleteTAG,data);
}

export function getYearNameInfo(data) {
    return axios.put(apis.getYearNameInfo,data);
}

export function getYearNameList(data) {
    //return axios.get(`${apis.getUserList}`);
    return axios.put(apis.getYearNameList,data);
}

export function deleteYearName(data) {
    //return axios.get(`${apis.getUserList}`);
    return axios.put(apis.deleteYearName,data);
}

export function getTagList(data) {
    //return axios.get(`${apis.getUserList}`);
    return axios.put(apis.getTagList,data);
}


export function saveYearName(data) {
    return axios.put(apis.saveYearName,data);
}

export function saveComment(data) {
    return axios.put(apis.saveComment,data);
}

export function savePartialCaptalizedValue(data) {
    return axios.put(apis.savePartialCaptalizedValue,data);
}

export function updateQRStatus(data) {
    return axios.put(apis.updateQRStatus,data);
}

export function saveFlagAsset(data) {
    return axios.put(apis.saveFlagAsset,data);
}
export function updateFlagAsset(data) {
    return axios.put(apis.updateFlagAsset,data);
}
export function cancelFlagAsset(data) {
    return axios.put(apis.cancelFlagAsset,data);
}
export function acceptFlagAsset(data) {
    return axios.put(apis.acceptFlagAsset,data);
}
export function getFlagAssetOut(data) {
    return axios.put(apis.getFlagAssetOut,data);
}
export function getFlagAssetIn(data) {
    return axios.put(apis.getFlagAssetIn,data);
}
export function getNonUseableList(data) {
    return axios.put(apis.getNonUseableList,data);
}
export function getDisposaleList(data) {
    return axios.put(apis.getDisposaleList,data);
}

export function saveUnallocated(data) {
    return axios.put(apis.saveUnallocation,data);
}
export function saveTransferData(data) {
    return axios.put(apis.saveTransferData,data);
}
export function saveSTPI(data) {
    return axios.put(apis.saveSTPI,data); 
}
export function savePhyVeriData(data) {
    return axios.put(apis.savePhyVeriData,data);
}
export function savePhyVeriMaster(data) {
    return axios.put(apis.savePhyVeriMaster,data);
}
export function getPhyVeriMaster(data) {
    return axios.put(apis.getPhyVeriMaster,data);
}
export function getSinglePhyVeriMaster(data) {
    return axios.put(apis.getSinglePhyVeriMaster,data);
}
export function getSeekExtRequest(data) {
    return axios.put(apis.getSeekExtRequest,data);
}
export function getPhyVeriReport(data) {
    return axios.put(apis.getPhyVeriReport,data);
}
export function getNotPhyVerified(data) {
    return axios.put(apis.getNotPhyVerified,data);
}
export function getFilterPVData(data) {
    return axios.put(apis.filterPvData,data);
}
export function getFilterNotVerifiedData(data) {
    return axios.put(apis.getFilterNotVerifiedData,data);
}
export function getFilterNotIdentifiable(data) {
    return axios.put(apis.getFilterNotIdentifiable,data);
}
export function getFilterNotIdentifiableCSV(data) {
    return axios.put(apis.getFilterNotIdentifiableCSV,data);
}
export function getNotPhysicalVerifiedAssetsCSV(data) {
    return axios.put(apis.getNotPhysicalVerifiedAssetsCSV,data);
}
export function getPhysicalVerifiedAssetsCSV(data) {
    return axios.put(apis.getPhysicalVerifiedAssetsCSV,data);
}
export function saveSingle(data,config={}) {
    return axios.put(apis.saveSingle,data);
    //return axios.post(apis.saveSingle,data,config);
}
export function saveSingleImage(data,config={}) {
    return axios.post(apis.saveSingleImage,data,config);
}
export function saveSingleExcel(data) {
    return axios.put(apis.saveSingleExcel,data);
}
export function downloadFile(data) {
    return axios.put(apis.downloadFile,data);
}
export function saveSingleInvoice(data,config={}) {
    return axios.post(apis.saveSingleInvoice,data,config);
}
export function saveSingleCertificate(data,config={}) {
    return axios.post(apis.saveSingleCertificate,data,config);
}
export function saveCapitalize(data) {
    return axios.put(apis.saveCapitalize,data);
}
export function getCapitalized(data) {
    return axios.put(apis.getCapitalized,data);
}
export function getNotCapitalized(data) {
    return axios.put(apis.getNotCapitalized,data);
}
export function getPendingCapitalized(data) {
    return axios.put(apis.getPendingCapitalized,data);
}
export function calculateDepreciation(data) {
    return axios.put(apis.calculateDepreciation,data);
}
export function calculateOtherDepreciation(data) {
    return axios.put(apis.calculateOtherDepreciation,data);
}
export function createMonthDepreciation(data) {
    return axios.put(apis.createMonthDepreciation,data);
}
export function lockMonthDepreciation(data) {
    return axios.put(apis.lockMonthDepreciation,data);
}
export function getCreateMonthData(data) {
    return axios.put(apis.getCreateMonthData,data);
}
export function saveAdjustment(data) {
    return axios.put(apis.saveAdjustment,data);
}
export function uploadAdjustment(data) {
    return axios.put(apis.uploadAdjustment,data);
}
export function uploadDepreciation(data) {
    return axios.put(apis.uploadDepreciation,data);
}
export function saveDeletion(data) {
    return axios.put(apis.saveDeletion,data);
}
export function saveAssetDisposal(data) {
    return axios.post(apis.assetDisposal,data);
}
export function getAssetDisposal(tag) {
    return axios.get(apis.assetDisposal+"/"+tag);
}
export function getAssetsDisposal() {
    return axios.get(apis.assetDisposal);
}
export function getSTPI(data) {
    //return axios.get(apis.saveSTPI+"/"+tag);
    return axios.put(apis.saveSTPI,data);
}
export function getAllSTPI(data) {
    //return axios.get(apis.saveSTPI);
    return axios.put(apis.getAllSTPI,data);
}
export function getSingleAsset(tag) {
    return axios.get(apis.saveSingle+"/"+tag);
}
export function getSingleCapitalize(tag) {
    return axios.get(apis.saveCapitalize+"/"+tag);
}
export function getAdjustment(data) {
    return axios.put(apis.getAdjustment,data);
    //return axios.get(apis.getAdjustment+"/"+tag);
}
export function getTransferData(data) { 
    //return axios.get(apis.assetTransfer+"/"+tag);
    return axios.put(apis.getAssetTransfer,data);
}

export function getAllTransferData(data) {
    return axios.put(apis.allAssetTransfer,data);
}

export function getFilterAssetsData(data) {
    return axios.post(apis.filterAssetsData,data);
}
export function getFilterQrAssetsData(data) {
    return axios.post(apis.filterQrAssetsData,data);
}
export function getFilterAssetsLogData(data) {
    return axios.post(apis.getFilterAssetsLogData,data);
}
export function getFilterReaderData(data) {
    return axios.post(apis.getFilterReaderData,data);
}
export function getActiveInactiveData(data) {
    return axios.post(apis.getActiveInactiveData,data);
}
export function getReaderList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getReaderList,data);
}
export function savePermanentTransferData(data) {
    return axios.post(apis.assetPermanentTransfer,data);
}

export function saveTemproryTransferData(data) {
    return axios.post(apis.assetTemproryTransfer,data);
}

export function getPermanentTransferData(tag) {
    return axios.get(apis.assetPermanentTransfer+'/'+tag);
}

export function getTemproryTransferData(tag) {
    return axios.get(apis.assetTemproryTransfer+'/'+tag);
}

export function getDisposalAssetsList(data) {
    return axios.put(apis.getDisposalAssetsList,data);
}
export function getRepositoryDetails(data) {
    return axios.put(apis.getRepositoryDetails,data);
}
export function getDashboardDetails(data) {
    return axios.put(apis.getDashboardDetails,data);
}
export function linkRFIDTAG(data) {
    return axios.put(apis.linkRFIDTAG,data);
}
export function getRFIDReadersList(data) {
    return axios.put(apis.getRFIDReadersList,data);
}
export function getTAGMappingData(data) {
    return axios.put(apis.getTAGMappingData,data);
}
export function getRFIDMappingList(data) {
    return axios.put(apis.getRFIDMappingList,data);
}
export function getTAGDetails(data) {
    return axios.put(apis.getTAGDetails,data);
}
export function saveGatePass(data) {
    return axios.put(apis.saveGatePass,data);
}
export function saveGatePassRemark(data) {
    return axios.put(apis.saveGatePassRemark,data);
}
export function getGatePassList(data) {
    return axios.put(apis.getGatePassList,data);
}export function getAllGatePassList(data) {
    return axios.put(apis.getAllGatePassList,data);
}
export function getGatePassData(data) {
    return axios.put(apis.getGatePassData,data);
}export function getGatePassDetailByGatePassNo(data) {
    return axios.put(apis.getGatePassDetailByGatePassNo,data);
}export function updateGatePass(data) {
    return axios.put(apis.updateGatePass,data);
}
export function updateGatePassRemark(data) {
    return axios.put(apis.updateGatePassRemark,data);
}
export function getAllGatePassTAG(data) {
    return axios.put(apis.getAllGatePassTAG,data);
}
export function saveReceiveAssetRemark(data) {
    return axios.put(apis.saveReceiveAssetRemark,data);
}

export function saveReceiveAsset(data) {
    return axios.put(apis.saveReceiveAsset,data);
}
export function getAssetInvoiceCSVData(data) {
    return axios.put(apis.getAssetInvoiceCSVData,data);
}
export function getAssetImagesCSVData(data) {
    return axios.put(apis.getAssetImagesCSVData,data);
}

export function getAssetsCertificatesCSVData(data) {
    return axios.put(apis.getAssetsCertificatesCSVData,data);
}
export function getDashboardUserCount(data) {
    return axios.put(apis.getDashboardUserCount,data);
}export function getUsersList(data) {
    return axios.put(apis.getUsersList,data);
}
export function getUserLogData(data) {
    return axios.put(apis.getUserLogData,data);
}
export function deletePVTAG(data) {
    return axios.put(apis.deletePVTAG,data);
}
export function deleteUploadPVTAG(data) {
    return axios.post(apis.deleteUploadPVTAG,data);
}


