import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldTextArea from "../../../atoms/FromFieldTextArea";
import * as CryptoJS from "crypto-js";
import {getCostCenterInfo, saveCostCenter} from "../../../services/asset.service";
import {getCompanyProfileList} from '../../../services/user.service';

function AddCostCenter() {
    const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,500}$/;

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false); // New state for button disabling

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);

    const [companies, setCompanies] = useState([]);

    const [costcentercode, setCostCenterCode] = useState('');
    const [ErrCostCenterCode, setErrCostCenterCode] = useState(false);

    const [costcentername, setCostCenterName] = useState('');
    const [ErrCostCenterName, setErrCostCenterName] = useState(false);

    const resetCostCenterInfo = function () {
        setCostCenterCode('');
        setCostCenterName('');
        setCompanyId('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const cost_id = query.get('cost_ID');

    useEffect(() => {
        if (cost_id !== '' && cost_id !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(cost_id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptCostId = decrypt_data.costid;
            getCostInfo(decryptCostId);
        }

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData = {'token': EncryptData};

        getCompanyProfileList(oData).then(res => {
            if (res?.data?.data?.length) setCompanies(res.data.data);
        });
    }, []);

    const getCostInfo = function (decryptCostId) {
        resetCostCenterInfo();

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'costid': decryptCostId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData1 = {'token': EncryptData};

        getCostCenterInfo(oData1).then(function (res) {
            if (res?.data?.data) {
                const aInfo = res.data.data[0];
                setCompanyId(aInfo.company_id);
                setCostCenterCode(aInfo.cost_center_code);
                setCostCenterName(aInfo.cost_center_name);
            } else {
                setErrMsg('Cost center not found');
            }
        });
    };

    const handleCompanyId = (e) => {
        setCompanyId(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prevent multiple submissions
        if (submitting) return;

        let valid = true;

        if (!TEXT_REGEX.test(companyId)) {
            setErrCompanyId('Company is required');
            valid = false;
        } else {
            setErrCompanyId('');
        }

        if (!TEXT_REGEX.test(costcentername)) {
            setErrCostCenterName('Cost center name is required');
            valid = false;
        } else {
            setErrCostCenterName('');
        }

        if (!TEXT_REGEX.test(costcentercode)) {
            setErrCostCenterCode('Cost center code is required');
            valid = false;
        } else {
            setErrCostCenterCode('');
        }

        if (valid) {
            setSubmitting(true); // Disable the button
            saveData(); // Submit the form
        }
    };


    const saveData = function () {
        const obj = cost_id !== '' && cost_id !== null
            ? {
                'cost_id': JSON.parse(CryptoJS.AES.decrypt(cost_id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString(CryptoJS.enc.Utf8)).costid,
                'cost_center_code': costcentercode,
                'cost_center_name': costcentername,
                'company_id': companyId,
                'status': '1',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
            : {
                'cost_center_code': costcentercode,
                'cost_center_name': costcentername,
                'company_id': companyId,
                'status': '1',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            };

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData = {'token': EncryptData};

        saveCostCenter(oData)
            .then(function (res) {
                if ((res.data.errors) && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetCostCenterInfo();
                }
            })
            .catch((err) => {
                setSuccess(false);
                setErrMsg(err?.data ? err.data.message : 'No server response');
            })
            .finally(() => {
                setSubmitting(false); // Re-enable the button
            });
    };

    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>Add Cost Center</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please fill all mandatory fields.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Company </label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}
                                value={companyId}>
                            <option value="">Select Company</option>
                            {companies.map((option) => (
                                <option key={option.company_id} value={option.company_id}>
                                    {option.company_name}
                                </option>
                            ))}
                        </select>
                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldTextArea star="*"
                                           label="Cost center code"
                                           inputValue={costcentercode}
                                           setInputValue={setCostCenterCode}
                                           setErrorMsg={ErrCostCenterCode}
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldTextArea star="*"
                                           label="Cost center name"
                                           inputValue={costcentername}
                                           setInputValue={setCostCenterName}
                                           setErrorMsg={ErrCostCenterName}
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary btn-md" disabled={submitting}>
                            {submitting ? 'Processing...' : cost_id ? 'Update' : 'Save'}
                        </button>
                    </div>
                    <div className="col-3"></div>
                </div>

            </form>
        </div>
    );
}

export default AddCostCenter;
