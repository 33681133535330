import axios from "axios";

var baseURL = process.env.REACT_APP_API_BASE_URL;
var apis = {
    'checkLogin': `${baseURL}users/login`,
    'verifyToken': `${baseURL}users/verifytoken`,
    'registerUser': `${baseURL}users/add`,
    'updateUser': `${baseURL}users/update`,
    'checkForgotPassword': `${baseURL}users/forgotpassword`,
    'checkVerifyOTP': `${baseURL}users/verifyotp`,
    'checkResetPassword': `${baseURL}users/resetpassword`,
    'saveUserManual': `${baseURL}users/saveusermanual`,
    'updateUserManual': `${baseURL}users/updateusermanual`,
    'getAllManual': `${baseURL}users/getallmanual`,
    'getUserManual': `${baseURL}users/getusermanual`,
    'getManualDetails': `${baseURL}users/getmanualdetails`,

    'saveCompanyProfile': `${baseURL}companyprofile/add`,
    'getCompanyProfileList': `${baseURL}companyprofile/list`,
    'getCompanyProfileDDList': `${baseURL}companyprofile/ddlist`,
    'getBranchList': `${baseURL}companyprofile/getbranchlist`,
    'getCompanyProfileData': `${baseURL}companyprofile/info`,
    'saveRole': `${baseURL}users/roleadd`,
    'getRoleList': `${baseURL}users/rolelist`,
    'getRoleInfo': `${baseURL}users/roleinfo`,
    'saveDivision': `${baseURL}division/add`,
    'saveDepartmentGroup': `${baseURL}division/savedepartmentgroup`,
    'getDivisionInfo': `${baseURL}division/divisioninfo`,
    'getDepartmentGroupInfo': `${baseURL}division/getdepartmentgroupinfo`,
    'getDivisioin': `${baseURL}division/view`,
    'getDivisionList': `${baseURL}division/list`,
    'getDepartmentGroupList': `${baseURL}division/getdepartmentgrouplist`,
    'getDivisionDDList': `${baseURL}division/ddlist`,
    'saveDepartment': `${baseURL}department/add`,
    'saveSubDepartment': `${baseURL}department/savesubdepartment`,
    'getDepartmentInfo': `${baseURL}department/departmentinfo`,
    'getSubDepartmentInfo': `${baseURL}department/getsubdepartmentinfo`,
    'getDepartment': `${baseURL}department/view`,
    'getDepartmentList': `${baseURL}department/list`,
    'getDepartmentListForMaster': `${baseURL}department/getdepartmentlistformaster`,
    'getDepartmentGroupListAdmin': `${baseURL}department/getdepartmentgrouplistadmin`,
    'getSubDepartmentList': `${baseURL}department/getsubdepartmentlist`,
    'getDepartmentDDList': `${baseURL}department/ddlist`,
    'getUserList': `${baseURL}users/list`,
    'getUserDetails': `${baseURL}users/details`,
    'generatePassword': `${baseURL}users/generatepassword`,
    'getMyProfileDetails': `${baseURL}users/getmyprofiledetails`,
    'updateMyPassword': `${baseURL}users/updatemypassword`,

    'saveCountryName': `${baseURL}users/savecountryname`,
    'getCountryInfo': `${baseURL}users/getcountryinfo`,
    'getCountryList': `${baseURL}users/getcountrylist`,
    'deleteCountry': `${baseURL}users/deletecountry`,
    'saveRegions': `${baseURL}users/saveregions`,
    'getRegionList': `${baseURL}users/getregionlist`,
    'deleteRegion': `${baseURL}users/deleteregion`,
    'getRegionInfo': `${baseURL}users/getregioninfo`,
    'getRegionMasterList': `${baseURL}users/getregionmasterlist`,
    'saveContinentName': `${baseURL}users/savecontinentname`,
    'getContinentInfo': `${baseURL}users/getcontinentinfo`,
    'deleteContinent': `${baseURL}users/deletecontinent`,
    'getContinentList': `${baseURL}users/getcontinentlist`,
    'getFilteredCountryList': `${baseURL}users/getfilteredcountrylist`,

    'getClasificationsList': `${baseURL}division/category`,

    'deleteFinancialsClassification': `${baseURL}division/deletefinclass`,

    'getClasificationsInfo': `${baseURL}division/categoryinfo`,
    'saveClasifications': `${baseURL}division/savecategory`,
    'saveTagMaster': `${baseURL}companyprofile/savetagmaster`,
    'getTagMaster': `${baseURL}companyprofile/gettagmaster`,

    'getRFIDInfo': `${baseURL}companyprofile/getrfidinfo`,
    'saveRFIDReader': `${baseURL}companyprofile/saveRFIDReader`,
    'getRFIDList': `${baseURL}companyprofile/getrfidlist`,
    'deleteRFID': `${baseURL}companyprofile/deleterfid`,
    'saveRFIDKey': `${baseURL}companyprofile/saverfidkey`,
    'getRFIDKeyInfo': `${baseURL}companyprofile/getrfidkeyinfo`,
    'getRFIDKeyList': `${baseURL}companyprofile/getrfidkeylist`,

    'getFormCategoriesList': `${baseURL}companyprofile/getformcategorieslist`,
    'getLastAssignmentNo': `${baseURL}companyprofile/getlastassignmentno`,
    'getLastAssignmentNoOthers': `${baseURL}companyprofile/getlastassignmentnoothers`,
    'getControlDepartmentList': `${baseURL}companyprofile/getcontroldepartmentlist`,
    'getSubControlDepartmentList': `${baseURL}companyprofile/getsubcontroldepartmentlist`,
    'saveAssignemnt': `${baseURL}companyprofile/saveassignemnt`,
    'getAssignmentList': `${baseURL}companyprofile/getassignmentlist`,
    'saveERAssignemnt': `${baseURL}companyprofile/saveerassignemnt`,
    'getLastERAssignmentNo': `${baseURL}companyprofile/getlasterassignmentNo`,
    'getERAssignmentList': `${baseURL}companyprofile/geterassignmentlist`,
    'getUsersList': `${baseURL}companyprofile/getuserslist`,
    'getLastERAssignmentNoOthers': `${baseURL}companyprofile/getlasterassignmentnoothers`,
    'saveAssignmentEquipment': `${baseURL}companyprofile/saveassignemntequipment`,
    'getAssignmentListIR': `${baseURL}companyprofile/getassignmentlistir`,
    'acceptAssignment': `${baseURL}companyprofile/acceptassignment`,
    'updateAssignment': `${baseURL}companyprofile/updateassignment`,
    'getAssignmentData': `${baseURL}companyprofile/getassignmentirdata`,
    'saveAssessment': `${baseURL}companyprofile/saveassessment`,
    'saveAssessmentMaterial': `${baseURL}companyprofile/saveassessmentmaterial`,
    'getAssignmentDataView': `${baseURL}companyprofile/getassignmentdataview`,
    'getAssesmentData': `${baseURL}companyprofile/getassesmentdata`,
    'saveERAssignmentEquipment': `${baseURL}companyprofile/saveerassignmentequipment`,
    'getERAssesmentData': `${baseURL}companyprofile/geterassesmentdata`,
    'getERAssignmentData': `${baseURL}companyprofile/geterassignmentdata`,
    'saveERAssessment': `${baseURL}companyprofile/saveerassessment`,
    'saveERAssessmentMaterial': `${baseURL}companyprofile/saveerassessmentmaterial`,
    'getERAssignmentDataView': `${baseURL}companyprofile/geterassignmentdataview`,
    'getLastGatePassNo': `${baseURL}companyprofile/getLastGatePassNo`,

}

export function loginUser(data) {
    return axios.put(apis.checkLogin, data);
}

export function verifyToken(data) {
    return axios.put(apis.verifyToken, data);
}

export function registerUser(data) {
    return axios.put(apis.registerUser, data);
}

export function updateUser(data) {
    return axios.put(apis.updateUser, data);
}

export function forgotUserPassword(data) {
    return axios.put(apis.checkForgotPassword, data);
}

export function verifyOTP(data) {
    return axios.put(apis.checkVerifyOTP, data);
}

export function ResetPasswordUser(data) {
    return axios.put(apis.checkResetPassword, data);
}

export function saveCompanyProfile(data) {
    return axios.put(apis.saveCompanyProfile, data);
}

export function getCompanyProfileData(data) {
    //return axios.get(`${apis.getCompanyProfile}/${compId}`);
    return axios.put(apis.getCompanyProfileData, data);
}

export function getCompanyProfileList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getCompanyProfileList, data);
}

export function getCompanyProfileDDList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getCompanyProfileDDList, data);
}

export function getFormCategoriesList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getFormCategoriesList, data);
}

export function getBranchList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getBranchList, data);
}

export function saveRole(data) {
    return axios.put(apis.saveRole, data);
}

export function getRoleList(data) {
    //return axios.get(`${apis.getRoleList}/${company_id}`);
    return axios.put(apis.getRoleList, data);
}

export function getRoleInfo(data) {
    return axios.put(apis.getRoleInfo, data);
}

export function saveDivision(data) {
    return axios.put(apis.saveDivision, data);
}

export function saveDepartmentGroup(data) {
    return axios.put(apis.saveDepartmentGroup, data);
}

export function getDivisionInfo(data) {
    return axios.put(apis.getDivisionInfo, data);
}

export function getDepartmentGroupInfo(data) {
    return axios.put(apis.getDepartmentGroupInfo, data);
}

export function getDivision(divisionId) {
    return axios.get(`${apis.getDivision}/${divisionId}`);
}

export function getDivisionList(data) {
    return axios.put(apis.getDivisionList, data);
}

export function getDepartmentGroupList(data) {
    return axios.put(apis.getDepartmentGroupList, data);
}

export function getDivisionDDList(data) {
    return axios.put(apis.getDivisionDDList, data);
}

export function saveClasifications(data) {
    return axios.put(apis.saveClasifications, data);
}

export function getClasificationsInfo(data) {
    return axios.put(apis.getClasificationsInfo, data);
}

export function getClasificationsList(data) {
    return axios.put(apis.getClasificationsList, data);
}

export function deleteFinancialsClassification(data) {
    return axios.put(apis.deleteFinancialsClassification, data);
}

export function saveDepartment(data) {
    return axios.put(apis.saveDepartment, data);
}

export function saveSubDepartment(data) {
    return axios.put(apis.saveSubDepartment, data);
}

export function getDepartmentInfo(data) {
    return axios.put(apis.getDepartmentInfo, data);
}

export function getDepartment(departmentId) {
    return axios.get(`${apis.getDepartment}/${departmentId}`);
}

export function getDepartmentList(data) {
    //return axios.get(`${apis.getDepartmentList}/${company_id}`);
    return axios.put(apis.getDepartmentList, data);
}

export function getDepartmentListForMaster(data) {
    //return axios.get(`${apis.getDepartmentList}/${company_id}`);
    return axios.put(apis.getDepartmentListForMaster, data);
}

export function getDepartmentGroupListAdmin(data) {
    //return axios.get(`${apis.getDepartmentList}/${company_id}`);
    return axios.put(apis.getDepartmentGroupListAdmin, data);
}

export function getSubDepartmentList(data) {
    //return axios.get(`${apis.getDepartmentList}/${company_id}`);
    return axios.put(apis.getSubDepartmentList, data);
}

export function getSubDepartmentInfo(data) {
    //return axios.get(`${apis.getDepartmentList}/${company_id}`);
    return axios.put(apis.getSubDepartmentInfo, data);
}

export function getDepartmentDDList(data) {
    //return axios.get(`${apis.getDepartmentList}/${company_id}`);
    return axios.put(apis.getDepartmentDDList, data);
}

export function getUserList(data) {
    //return axios.get(`${apis.getUserList}`);
    return axios.put(apis.getUserList, data);
}

export function getUserDetails(data) {
    return axios.put(apis.getUserDetails, data);
}

export function generatePassword(data) {
    return axios.put(apis.generatePassword, data);
}

export function getTagMaster(data) {
    return axios.put(apis.getTagMaster, data);
}

export function saveTagMaster(data) {
    return axios.put(apis.saveTagMaster, data);
}

export function getUserManual(data) {
    return axios.put(apis.getUserManual, data);
}

export function getAllManual(data) {
    return axios.put(apis.getAllManual, data);
}

export function getManualDetails(data) {
    return axios.put(apis.getManualDetails, data);
}

export function saveUserManual(data) {
    return axios.put(apis.saveUserManual, data);
}

export function updateUserManual(data) {
    return axios.put(apis.updateUserManual, data);
}

export function getMyProfileDetails(data) {
    return axios.put(apis.getMyProfileDetails, data);
}

export function updateMyPassword(data) {
    return axios.put(apis.updateMyPassword, data);
}

export function getRFIDInfo(data) {
    return axios.put(apis.getRFIDInfo, data);
}

export function saveRFIDReader(data) {
    return axios.put(apis.saveRFIDReader, data);
}

export function getRFIDList(data) {
    return axios.put(apis.getRFIDList, data);
}

export function deleteRFID(data) {
    return axios.put(apis.deleteRFID, data);
}

export function saveRFIDKey(data) {
    return axios.put(apis.saveRFIDKey, data);
}

export function getRFIDKeyInfo(data) {
    return axios.put(apis.getRFIDKeyInfo, data);
}

export function getRFIDKeyList(data) {
    return axios.put(apis.getRFIDKeyList, data);
}

export function getLastAssignmentNo(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getLastAssignmentNo, data);
}

export function getLastERAssignmentNo(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getLastERAssignmentNo, data);
}

export function getLastAssignmentNoOthers(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getLastAssignmentNoOthers, data);
}

export function getLastERAssignmentNoOthers(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getLastERAssignmentNoOthers, data);
}

export function getControlDepartmentList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getControlDepartmentList, data);
}

export function saveAssignemnt(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveAssignemnt, data);
}

export function getSubControlDepartmentList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getSubControlDepartmentList, data);
}

export function getAssignmentList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAssignmentList, data);
}

export function saveERAssignemnt(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveERAssignemnt, data);
}

export function getERAssignmentList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getERAssignmentList, data);
}

export function getUsersList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getUsersList, data);
}

export function saveAssignmentEquipment(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveAssignmentEquipment, data);
}

export function acceptAssignment(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.acceptAssignment, data);
}

export function updateAssignment(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.updateAssignment, data);
}

export function getAssignmentListIR(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAssignmentListIR, data);
}

export function getAssignmentData(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAssignmentData, data);
}

export function saveAssessment(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveAssessment, data);
}

export function saveAssessmentMaterial(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveAssessmentMaterial, data);
}

export function getAssignmentDataView(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAssignmentDataView, data);
}

export function getAssesmentData(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAssesmentData, data);
}

export function saveERAssignmentEquipment(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveERAssignmentEquipment, data);
}export function getERAssesmentData(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getERAssesmentData, data);
}export function getERAssignmentData(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getERAssignmentData, data);
}export function saveERAssessment(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveERAssessment, data);
}export function saveERAssessmentMaterial(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveERAssessmentMaterial, data);
}export function getERAssignmentDataView(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getERAssignmentDataView, data);
}export function getLastGatePassNo(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getLastGatePassNo, data);
}export function saveCountryName(data) {
    return axios.put(apis.saveCountryName, data);
}export function getCountryInfo(data) {
    return axios.put(apis.getCountryInfo, data);
}export function getCountryList(data) {
    return axios.put(apis.getCountryList, data);
}export function deleteCountry(data) {
    return axios.put(apis.deleteCountry, data);
}export function saveRegions(data) {
    return axios.put(apis.saveRegions, data);
}export function getRegionList(data) {
    return axios.put(apis.getRegionList, data);
}export function deleteRegion(data) {
    return axios.put(apis.deleteRegion, data);
}export function getRegionInfo(data) {
    return axios.put(apis.getRegionInfo, data);
}export function getRegionMasterList(data) {
    return axios.put(apis.getRegionMasterList, data);
}export function saveContinentName(data) {
    return axios.put(apis.saveContinentName, data);
}export function getContinentInfo(data) {
    return axios.put(apis.getContinentInfo, data);
}export function deleteContinent(data) {
    return axios.put(apis.deleteContinent, data);
}export function getContinentList(data) {
    return axios.put(apis.getContinentList, data);
}export function getFilteredCountryList(data) {
    return axios.put(apis.getFilteredCountryList, data);
}