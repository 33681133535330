import React, {useCallback, useEffect, useState} from 'react';
import {getFilterAssetsData} from '../../services/asset.service';
import Box from '@mui/material/Box';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import QRCode from 'qrcode';
import './QRGenrate.css';
import FromFieldSelect from '../../atoms/FromFieldSelect';
import * as CryptoJS from "crypto-js";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromFieldText from "../../atoms/FromFieldText";
import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const columns = [
    {"field": "sl_no", "hideable": true, "hide": true, "editable": false, "headerName": "Sl No", "width": 50},
    {"field": "tag", "hideable": true, "hide": false, "editable": false, "headerName": "Tag", "width": 250},
    {
        "field": "asset_name",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Asset Name",
        "width": 250
    },
    {
        "field": "division_name",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Division",
        "width": 250
    },
    {
        "field": "tag_name",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Asset Tag Name",
        "width": 250
    },
    {
        "field": "tag_division",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Tag Division",
        "width": 250
    },

    {"field": "premises", "hideable": true, "hide": false, "editable": false, "headerName": "Premises"},
    {"field": "floor", "hideable": true, "hide": false, "editable": false, "headerName": "Floor"},
    {"field": "site_id", "hideable": true, "hide": false, "editable": false, "headerName": "Site Id"},
    {"field": "user_type", "hideable": true, "hide": false, "editable": false, "headerName": "User Type"},
    {"field": "user_department", "hideable": true, "hide": false, "editable": false, "headerName": "User Department"},
    {"field": "employee_id", "hideable": true, "hide": false, "editable": false, "headerName": "Employee Id"},
    {"field": "employee_name", "hideable": true, "hide": false, "editable": false, "headerName": "Employee Name"},
    {
        "field": "category_name",
        "hideable": false,
        "hide": false,
        "editable": false,
        "headerName": "Financials Classification",
        "width": 180
    },
    {
        "field": "created_at", "editable": true, "hide": false, "headerName": "Created Date", "width": 130,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.created_at)}</p>
        )
    },
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

function QRGenrate() {
    const [loading, setLoading] = useState(true);
    const [checkboxSelection] = useState(true);
    const [items, setItems] = useState([]);
    const [natureOfPresence, setNatureOfPresence] = useState('');
    const [typeOfAssets, setTypeOfAssets] = useState('');
    const [typeOfSiteId, setTypeOfSiteId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTAGno, setStartTAGno] = useState('');
    const [endTAGNo, setEndTAGNo] = useState('');
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [selectedRowsData, setSelectedRowsData] = useState([]);

    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchAssets = useCallback(async () => {
        setLoading(true);
        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: {
                'typeOfAssets': typeOfAssets,
                'natureOfPresence': natureOfPresence,
                'typeOfSiteId': typeOfSiteId,
                'startDate': startDate ? (getFormatedDate(startDate)) : '',
                'endDate': endDate ? (getFormatedDate(endDate)) : '',
                'startTAGno': startTAGno,
                'endTAGNo': endTAGNo, 'offset': offset, 'pageSize': pageSize
            },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = { token: encryptedData };

        try {
            const res = await getFilterAssetsData(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems([]);
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    }, [page, pageSize, typeOfAssets, natureOfPresence, typeOfSiteId, startDate, endDate, startTAGno, endTAGNo]);

    useEffect(() => {
        fetchAssets();
    }, [fetchAssets]);

    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value));
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onRowsSelectionHandler = (selectionModel) => {
        const selectedRows = selectionModel.map((id) => items.find((row) => row.sl_no === id));
        setSelectedRowsData(selectedRows);
    };

    const printQR = () => {
        if (!selectedRowsData || selectedRowsData.length === 0) {
            console.error("No rows selected for printing.");
            return;
        }
        const qrcodesDiv = document.createElement("div");
        qrcodesDiv.setAttribute("style", "width:350px");
        qrcodesDiv.id = 'qrcode-container';
        selectedRowsData.forEach((elm) => {
            QRCode.toDataURL(elm.tag, {
                width: 800,
                margin: 2,
            }, (err, url) => {
                if (err) return console.error(err);
                const row1 = document.createElement("div");
                row1.setAttribute("class", 'qrcodes-div');
                row1.setAttribute("style", "display:inline-block;");
                const col1 = document.createElement("div");
                col1.setAttribute("class", 'col');
                col1.setAttribute("style", "width:90px;margin-left: -20px; margin-top:0px;");
                const col3 = document.createElement("img");
                col3.setAttribute("src", url);
                col3.setAttribute("style", "width:90px");
                col1.appendChild(col3);
                const col2 = document.createElement("div");
                col2.setAttribute("class", 'col');
                col2.setAttribute("style", "width:280px;float:right;text-align: left;font-size: 16px;margin-top: 1px;margin-left: -20px");
                col2.innerHTML = `
                <label><span>${sessionStorage.getItem("LoggedInCompanyName")}</span></label><br/>
                <label>Division : <span>${elm.tag_division}</span></label><br/>
                <label>Asset : <span>${elm.tag_name}</span></label><br/>
                <label>TAG : <span>${elm.tag}</span></label><br/>
            `;

                row1.appendChild(col1);
                row1.appendChild(col2);
                qrcodesDiv.appendChild(row1);
                document.getElementById('print_qr').appendChild(qrcodesDiv);
            });
        });
        const printContents = document.getElementById('print_qr').innerHTML;
        const docprint = window.open();
        docprint.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"');
        docprint.document.write('"http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">');
        docprint.document.write('<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en">');
        docprint.document.write('<head><title>Print QR Codes</title>');
        docprint.document.write('<style type="text/css">@media screen, print { @page { size: 70mm 20mm; margin: 0mm; } html, body { margin: 0px;padding: 0px; } #qrcode-container { width: 384px;}.qrcodes-div { width: 380px; height: 25mm;margin: 1mm auto;page-break-after: always;} .col:first-child { display: inline-block; width: 80px; padding-top: 0px; padding-left: 1px; }.col:last-child { display: inline-block; width: 260px; padding-top: 0px; float: right; } }</style>');
        docprint.document.write('</head><body onload="self.print()"><center>');
        docprint.document.write(printContents);
        docprint.document.write('</center></body></html>');
        docprint.document.close();
        docprint.focus();
        docprint.print();
        document.getElementById('print_qr').innerHTML = '';
    };
    const totalPages = Math.ceil(totalItems / pageSize);
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Print QR Code</h5>
                </div>
            </div>
            <div class="filter-container">
                <div className="row mb-1">
                    <div className="col-4">
                        <FromFieldSelect
                            label="Nature of Presence"
                            placeholder="Select Nature of Presence"
                            selectedValue={natureOfPresence}
                            setSelectedValue={setNatureOfPresence}
                            values={["Identifiable", "Non Identifiable", "All"]}
                        />
                    </div>
                    <div className="col-4">
                        <FromFieldSelect
                            label="Type of Assets"
                            placeholder="Type of Assets"
                            selectedValue={typeOfAssets}
                            setSelectedValue={setTypeOfAssets}
                            values={["Parent", "Child", "All"]}
                        />
                    </div>
                    <div className="col-3">
                        <FromFieldSelect
                            label="Site ID"
                            placeholder=""
                            selectedValue={typeOfSiteId}
                            setSelectedValue={setTypeOfSiteId}
                            values={["Is Empty", "Is Not Empty"]}
                        />
                    </div>

                </div>
                <div className="row mb-1">
                    <div className="col-4">
                        <FromFieldDate
                            label="Start Date"
                            placeholder="Select Start Date"
                            inputValue={startDate}
                            setInputValue={setStartDate}
                        />
                    </div>
                    <div className="col-4">
                        <FromFieldDate
                            label="End Date"
                            placeholder="Select End Date"
                            inputValue={endDate}
                            setInputValue={setEndDate}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <FromFieldText
                            label="Start TAG No"
                            placeholder="Enter Start TAG No"
                            inputValue={startTAGno}
                            setInputValue={setStartTAGno}
                        />
                    </div>
                    <div className="col-4">
                        <FromFieldText
                            label="End TAG No"
                            placeholder="Enter End TAG No"
                            inputValue={endTAGNo}
                            setInputValue={setEndTAGNo}
                        />
                    </div>
                    <div className="col-4 text-center">
                        <button onClick={fetchAssets} className='btn btn-info btn-md'>
                            <i className="bi bi-search"></i> Search
                        </button>
                    </div>
                </div>
            </div>
            <div class="filter-container">
            <div className='row mb-1'>
                <div className='col-2'>
                    <button onClick={printQR} className='btn btn-secondary btn-sm'>
                        <i className="bi bi-printer"></i> Print
                    </button>
                </div>
            </div>
            <div className='row'>
                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <Box>
                        <DataGrid
                            checkboxSelection={checkboxSelection}
                            rows={items}
                            getRowId={(row) => row.sl_no}
                            columns={columns}
                            autoHeight
                            components={{
                                LoadingOverlay: CustomLoader,
                                Toolbar: CustomToolbar,
                                Pagination: () => (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                        <Box sx={{ mr: 4 }}>
                                            Showing {items.length} records out of {totalItems}
                                        </Box>
                                        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                            <InputLabel>Rows per page</InputLabel>
                                            <Select
                                                value={pageSize}
                                                onChange={handlePageSizeChange}
                                                label="Rows per page"
                                            >
                                                {[50, 100, 200, 500].map((size) => (
                                                    <MenuItem key={size} value={size}>
                                                        {size}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Pagination
                                            count={totalPages}
                                            page={page}
                                            onChange={handlePageChange}
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                    </Box>
                                ),
                            }}
                            loading={loading}
                            onSelectionModelChange={onRowsSelectionHandler}
                        />
                    </Box>
                )}
            </div>
            </div>
            <div id='print_qr'></div>
        </div>
    );
}

export default QRGenrate