import React, {useEffect, useState} from 'react';
import './atom.css';
import Box from '@mui/material/Box';
import {DataGrid, GridOverlay, GridToolbarContainer} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from 'react-modal';
import {getCommentListByTag} from '../services/asset.service';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const CommentListPopupBox = ({tag, isOpen, onClose, onConfirm}) => {
    const [loading, setLoading] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [items, setItems] = useState([]);
    const columns = [
        {"field": "sl_no", "hideable": true, "hide": true, "editable": false, "headerName": "Sl No", "width": 50},
        /*{"field": "tag", "editable": false, "hide": false, "headerName": "TAG No", "width": 200},*/
        {
            "field": "tag", "editable": false, "hide": false, "headerName": "TAG", "width": 250,
            renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'tag': params.row.tag, 'back_url':'view-comments'
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (<a href={`/asset-basic-details?tag=${EncryptID}`}>{params.value}</a>);
            }
        },
        {
            "field": "comment",
            "hideable": true,
            "hide": false,
            "editable": false,
            "headerName": "Comment Description",
            "width": 250,
            "wrap": true
        },
        {
            "field": "created_by_name",
            "hideable": true,
            "hide": false,
            "editable": false,
            "headerName": "Comment Added By",
            "width": 180
        },
        {
            "field": "created_date", "editable": true, "hide": false, "headerName": "Date of Addition", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_date)}</p>
            )
        },
        {
            field: 'actions', headerName: 'Actions', width: 80, renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'commentid': params.row.id,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (
                    <span><a href={`/add-comments/?comment_ID=${EncryptID}`}><button type="button" className="btn btn-primary btn-md">
              <i className="bi bi-pencil"></i></button></a></span>
                );
            }
        },
    ];

    useEffect(() => {
        const getTransferInfo = async function (decryptedTAG) {
            setLoading(true);
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'tag': decryptedTAG},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData};
            try {
                const res = await getCommentListByTag(oData1);
                if (res?.data?.data.length) {
                    setItems(res.data.data);
                }
            } catch (error) {
                // Handle error
                console.error("Error fetching transfer info:", error);
            } finally {
                setLoading(false);
            }
        }
        if (tag) {
            getTransferInfo(tag);
        }
    }, [tag]);
    const CustomLoader = () => (
        <GridOverlay>
            <CircularProgress color="primary" size={30}/>
        </GridOverlay>
    );

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
            </GridToolbarContainer>
        );
    }


    return ( 
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Confirm Box"
            style={modalStyles}
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">TAG Comment List - ({tag})</h5>
                        <button type="button" class="btn-close" onClick={onClose}></button>
                    </div>
                    <div class="modal-body" style={modalBody}>
                        <Box sx={{height: 500, width: '100%'}}>
                            <DataGrid
                                rows={items}
                                getRowId={(row) => row.id}
                                columns={columns}
                                pageSize={50}
                                //height={500}
                                autoHeight
                                //stickyHeaders={true}
                                rowsPerPageOptions={[50]}
                                components={{
                                    LoadingOverlay: CustomLoader,
                                    Toolbar: CustomToolbar,
                                }}
                                loading={loading}
                            />
                        </Box>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        width: '550px',
        height: '500px',
        margin: 'auto',
    },
};
const modalBody = {
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default CommentListPopupBox;