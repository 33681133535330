import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import {deletePVTAG} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import FromFieldSelect from "../../../atoms/FromFieldSelect";

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

//const NUMBER_REGEX = /^[0-9]{1,15}$/;

function SingleTagDeletePV() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    //const [allocate, setAllocate] = useState('');
    const [tag, setTag] = useState('');
    const [ErrTag, setErrTag] = useState(false);

    const [pvtableno, setPvTableNo] = useState('');
    const [ErrPvTableNo, setErrPvTableNo] = useState('');

    const resetAssetInfo = function () {
        setTag('');
        setPvTableNo('');
    }

    useEffect(() => {

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (pvtableno === '') {
            setErrPvTableNo('Pv Table is required');
            valid_Name = false;
            //return;
        } else {
            setErrPvTableNo('');
        }

        if (!TEXT_REGEX.test(tag) && tag !== 0) {
            setErrTag('TAG is required');
            valid_Name = false;
            //return;
        } else {
            setErrTag('');
        }

        //alert(valid_Name);
        if (valid_Name) { //alert('test1');
            deletePVTAGData(); //submit form
        } else {
            return false;
        }
    }

    const deletePVTAGData = function () {
        var obj = {
            'tag': tag,
            'pvTableNo': pvtableno,
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            deletePVTAG(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setTag('');
                    setPvTableNo('');
                    resetAssetInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>Physical Verification Single TAG Delete</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldSelect
                        label="PV Table NO"
                        selectedValue={pvtableno} // The current value
                        setSelectedValue={setPvTableNo} // The setter function
                        values={["Table 01", "Table 02", "Table 03"]}
                        setErrorMsg={ErrPvTableNo}
                    />

                </div>
                <div className='col-6'></div>
            </div>
            <div className="row mt-2">
                <div className="col-6">
                    <FromFieldText
                        label="TAG"
                        inputValue={tag}
                        setInputValue={setTag}
                        setErrorMsg={ErrTag}
                    />
                </div>
                <div className='col-6'></div>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row mt-2">
                    <div className="col-3"></div>
                    <div className="col-6">

                        <button className="btn btn-primary btn-md"> Delete</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href={'/single-tag-delete-pv'}>
                            <button className='btn btn-info btn-md'>Reset</button>
                        </a>

                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default SingleTagDeletePV


