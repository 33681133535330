import React, { useState,useEffect,useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer, GridToolbarExport,GridOverlay } from '@mui/x-data-grid';
import { updateFlagAsset,getNonUseableList } from '../../../services/asset.service';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmBox from '../../../atoms/ConfirmBox';
import  * as CryptoJS  from "crypto-js";

function getFormatedDate(dateString) {
  if(dateString!=null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}

function NonUseableList() {
  const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [rejectReason, setRejectReason] = useState('');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  
    const handleConfirmOpen = () => {
      setIsConfirmOpen(true);
    };
  
    const handleConfirmClose = () => {
      setIsConfirmOpen(false);
    };
  
    const handleConfirm = (inputValue) => {
      
      console.log('Confirmed with input:', inputValue);
      //setRejectReason(inputValue);
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data': {'flag_id':'','flag_ids':selectionModel,'flag_status':'2','reject_reason':inputValue},
          'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData = {'token': EncryptData}
      try {
          //setLoading('..'); // Set loading before sending API request
          updateFlagAsset(oData).then(function (res) {
          //setLoading(false); // Stop loading
          setSuccess(res.data.message); //alert('a');
          const updatedData = items.filter((item) => !selectionModel.includes(item.id) );
            setItems(updatedData);
        });
      } catch (err) {
          setSuccess(false);
          setLoading(false); // Stop loading
          if (!err?.data1) {
              console.log(err);
              //setErrMsg('No Server Response');
              //setSuccess("Successfully saved Single Asset");
          } else if (err.data1?.status === 409) {
              //setErrMsg('Record already exist');
          } else {
              setErrMsg('Insert/Update Failed');
          }
          //elementRef.current.focus();
      }
    };
    
    const handleReject = async (flagId) => {
      // Use the S3 getObject method to get the image and initiate the download
        //console.log('flagid'+flagId);
      if(selectionModel.length>0){ setErrMsg('');
      if (window.confirm('Are you sure you want to reject selected items?')){
        handleConfirmOpen();
      }}else{ setErrMsg('Please select atleast one asset.')}
    };
    
    const handleSelectionModelChange = (newSelection) => {
      setSelectionModel(newSelection);
    };

  const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"headerName":"Sl No","width":50},
    {"field":"tag","hide":false ,"headerName":"TAG","width":250},
    {"field":"cat_of_flag","hide":false ,"headerName":"Cat of Flag","width":280},
    {"field":"remarks","hideable":true,"hide":true ,"headerName":"Remarks","width":250},
    {"field":"purpose_of_grouping","hide":false ,"headerName":"Purpose","width":130},
    {"field":"existing_ownership","hide":false ,"headerName":"Existing Ownership","width":130},
    {"field":"new_ownership","hide":false ,"headerName":"New Ownership","width":130},
    
    {"field":"created_at","hide":false ,"headerName":"Created Date","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
      ) },
  ];
  const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}  />
    </GridOverlay>
    );
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      
    </GridToolbarContainer>
  );
}
const [items, setItems] = useState([]);
  useEffect(()=>{
    const fetchData = async () => {
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      'param_data':{'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
      'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token':EncryptData}
    getNonUseableList(oData).then(res=>{
      if(res?.data?.data?.length) setItems(res.data.data);
    });
    setLoading(false);
    }; fetchData();

  },[])
  
  // readAssets();
  if (loading) {
    // Show loading indicator while data is being fetched
    return <CircularProgress />;
  }
  return (
    <div className='container' ><h5> Not Working Asset </h5>
      {success ? (<div className="alert alert-success" role="alert">
        <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
          <div className="alert alert-danger" role="alert">
            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
      )}
      
      <div className='filter-container1 col-12'>
      <div className='col-8'></div><div className='col-2'>
        <button className='btn btn-danger btn-sm text-right' onClick={handleReject}>Reject Process</button>
        </div>
      </div>
      
      
      <DataGrid
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={50}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        //height={500}
        autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        components={{
          LoadingOverlay: CustomLoader,
          Toolbar: CustomToolbar,
        }}
        loading={loading} 
      />
      <ConfirmBox
        isOpen={isConfirmOpen}
        onClose={handleConfirmClose}
        onConfirm={handleConfirm}
      />
    </div>
  )
}

export default NonUseableList
