import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import {getTransportDetails} from '../../../services/transfer.service';
import * as CryptoJS from "crypto-js";


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function BranchTransferList() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);


    const columns = [
        {
            "field": "tin_no", "hideable": true, "hide": false, "editable": false, "headerName": "TIN No", "width": 250,
            renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'tin_no': params.row.tin_no,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (<a href={`/update-transport-details?tin_no=${EncryptID}`}>{params.row.tin_no}</a>)
            }
        },
        {
            "field": "mode_of_transport",
            "editable": false,
            "hide": false,
            "headerName": "Mode of Transport",
            "width": 130
        },
        {"field": "transport_name", "editable": true, "hide": false, "headerName": "Transporter Name", "width": 130},
        {
            "field": "shipping_doc_no",
            "hideable": true,
            "hide": false,
            "editable": false,
            "headerName": "AWB/Shipping Doc No",
            "width": 180
        },
        {
            "field": "shipping_date", "editable": true, "hide": false, "headerName": "Date of Shipping", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.shipping_date)}</p>
            )
        },
        {"field": "contact_person", "editable": true, "hide": false, "headerName": "Contact Person", "width": 130},
        {"field": "contact_number", "editable": true, "hide": false, "headerName": "Contact Number", "width": 130},

        {
            "field": "receiver_of_consignment",
            "editable": true,
            "hide": false,
            "headerName": "Receiver of Consignment",
            "width": 200
        },
        {"field": "receiver_mail", "editable": true, "hide": false, "headerName": "Receiver Mail", "width": 130},
        {"field": "receiver_mobile", "editable": true, "hide": false, "headerName": "Receiver mobile", "width": 130},
        {
            "field": "created_at", "editable": true, "hide": false, "headerName": "Created Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },
    ];
    {/*{"field":"asset_image","editable":false,"hide":false ,"headerName":"Action","width":200,
    renderCell: (params) => (
      <div><a href="#" onClick={() => handleAccept(params.row.id) } >Accept</a> / <a href="#/" onClick={() => handleReject(params.row.id) } >Reject</a></div>
    ) },*/
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
            </GridToolbarContainer>
        );
    }

    const [items, setItems] = useState([]);
    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data': {},
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                let oData = {'token': EncryptData}
                const res = await getTransportDetails(oData);

                if (isMounted && res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        // Cleanup function to handle unmounting
        return () => {
            isMounted = false;
        };
    }, [])

    // readAssets();
    return (
        <div className='container'><div className='col'><h5> Transport List </h5></div>
            {success ? (<div className="alert alert-success" role="alert">
                <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
                    <div className="alert alert-danger" role="alert">
                        <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
            )}


                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    //height={500}
                    autoHeight
                    //stickyHeaders={true}
                    rowsPerPageOptions={[50]}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
        </div>
    )
}

export default BranchTransferList
