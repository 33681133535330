import React, { useState } from "react";
import SidebarItem from "../atoms/SidebarItem.js";
import items from "../data/sidebar.json";

let aAccessModuleId = [];
const sModuleId = sessionStorage.getItem("LoggedInModuleId");

if (sModuleId != null) {
    aAccessModuleId = sModuleId.split(",");
}

export default function Sidebar() {
    const [selectedSub, setSelectedSub] = useState(null); // Track selected submenu
    const [openMenu, setOpenMenu] = useState(null); // Track open main menu

    const handleMenuToggle = (menuId) => {
        setOpenMenu((prev) => (prev === menuId ? null : menuId)); // Toggle main menu
    };

    const handleSubmenuSelect = (subId) => {
        setSelectedSub(subId); // Update selected submenu
    };

    return (
        <div className="sidebar">
            {items.map((item, index) => {
                if (aAccessModuleId.indexOf(item.id) > -1) {
                    return (
                        <SidebarItem
                            key={index}
                            item={item}
                            isOpen={openMenu === item.id}
                            onToggle={() => handleMenuToggle(item.id)}
                            selectedSub={selectedSub}
                            onSelect={handleSubmenuSelect}
                        />
                    );
                }
                return null;
            })}
        </div>
    );
}
