import React, { useState,useRef,useEffect,useCallback } from 'react';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { uploadStfBulkFlagAsset } from '../../../services/asset.service';
import {getDepartmentList} from '../../../services/user.service';
import {getOtherTagDivisionList} from '../../../services/master.service';
import  * as CryptoJS  from "crypto-js";
import './AssetDetails.css';
const templatePath = "/exceltemplates/STF_Flag_Asset_Template.xlsx";

const purpose_columns = [
    {"field":"id","editable":false,"hide":true ,"headerName":"Id ","width":100},
    {"field":"tag_division_name","editable":false,"hide":false ,"headerName":"Purpose Name","width":350},
    {"field":"tag_division_short_name","editable":false,"hide":true ,"headerName":"Purpose Short Name","width":350},
    {"field":"purpose_of_flaging","editable":false,"hide":true ,"headerName":"Purpose of flaging","width":350},
    {"field":"company_name","editable":false,"hide":true ,"headerName":"Company Name","width":350},
    {"field":"created_at","editable":false,"hide":true ,"headerName":"Create Date","width":130},
  ];
  const control_department_columns = [
    {"field":"id","editable":false,"hide":true ,"headerName":"Id ","width":100},
    {"field":"department_name","editable":false,"hide":false ,"headerName":"Control Department Name","width":350},
    {"field":"department_short_name","editable":false,"hide":false ,"headerName":"Control Department Short Name","width":350},
    {"field":"company_name","editable":false,"hide":true ,"headerName":"Company Name","width":350},
    {"field":"created_at","editable":false,"hide":true ,"headerName":"Create Date","width":130},
  ];

function STFBulkFlagAsset() {
  const errRef = useRef();
  const [validName, setValidName] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purposeItems, setPurposeItems] = useState([]);
  const [control_department_items, setControl_Department_Items] = useState([]);
  var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }
  
  useEffect(()=>{
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        //'param_data':obj,
        'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token': EncryptData}
    getOtherTagDivisionList(oData).then(res => {
        if (res?.data?.data?.length) setPurposeItems(res.data.data);
    });
    
    getDepartmentList(oData).then(res => {
        if (res?.data?.data?.length) {
            setControl_Department_Items(res.data.data);
        }
    });

  },[])
  const onChangeFile = async (event) => {
    event.preventDefault(); 
    console.log(event.target.files[0]);
    if (event.target.files[0]?.name.split('.').pop() == 'undefined' || event.target.files[0]?.name.split('.').pop() !== 'xlsx') {
      //alert('Please upload valid format. excel file only allowed.'); 
      setErrMsg("Please upload valid format. excel file only allowed."); 
      return false;
    }else{ //alert('validName');
    //submit form
    try { 
    const data = new FormData() 
    data.append('file', event.target.files[0]);
    data.append('company_id', sessionStorage.getItem('LoggedInCompanyId'));
    data.append('division_id', sessionStorage.getItem('LoggedInDivisionId'));
    data.append('created_by', sessionStorage.getItem('LoggedInUserId'));
    /*let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      'param_data':data,
      'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token':EncryptData}*/
    setLoading('..');
    uploadStfBulkFlagAsset(data).then(res => {
      if((typeof(res.data.errors)!== "undefined") && res.data.errors.errno>0){ 
        setErrMsg(res.data.message);setLoading(false);
        setSuccess(false);
      }else{
      setSuccess(res.data.message);
      setLoading(false);  // Stop loading
      }
      //setSuccess(res.data.message);
    })
    
    } catch (error) {
    setLoading(false); // Stop loading in case of error
    console.error(error);
    setErrMsg(error.data.message);
    }
    } //end else
  };
  // readAssets();
  return (
    <div className='container'>
      <div className='row single__assets'>
                <div className="col-9 col">
                    <h5>Bulk Reclassify</h5>
                </div>
                <div className="col-3"><a href={'/view-allocation'} className="btn btn-link"><i className='bi bi-list'></i> Flag Asset List</a></div>
                {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
      </div>
      <div className="filter-container">    
      <div className="row form-fields mb-5">
        <div className='col-9'>
        <div className="row form-fields">
        <div className="label col-3"> 
            <label>Select Flag Asset Excel </label>
            <span className="star error"> *</span> 
        </div>
            <div className="float-start col-3">
                <Button variant='contained' component='label' className="btn btn-primary btn-md">
                <i className="bi bi-upload"></i>&nbsp;Upload
                    <input hidden type='file' onChange={onChangeFile} />
                </Button>
                {loading ? (<div className="spinner-border text-primary" role="status">
                              <span className="sr-only">{loading}</span>
                            </div>) : ('') }
            </div>
         </div>             
        </div>
        
        <div className='col-3 form-fields'>
                
        <a href={templatePath} className="text-decoration-none btn btn-secondary btn-sm">
        Download Assignment Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
        </svg></a>
        </div>
      </div></div>
      <div className="filter-container"> 
            <div className='col-12'>
            <div className='row'>
            <strong>Instruction for bulk upload:</strong>
            <p>1. For Dates, always use <b>" 'YYYY-MM-DD " </b>.</p>
            <p>2. Not to Leave Blank on any cell, please use <b>"NA"</b> in place of text and use <b>"0"</b> in place of "Numbers" as appropriate.</p>
            <p>3. For company name use <b>"{sessionStorage.getItem("LoggedInCompanyName")}"</b> and For branch name use <b>"{sessionStorage.getItem("LoggedInDivisionName")}"</b>. </p>
            <p>4. For TAG Branch use <b>"{sessionStorage.getItem('LoggedInDivisionShortName')}"</b>. </p>
            </div>
            </div>
        </div>
        
      <div className='row'>
        <div className='col-6'>
          <div className='row'>
          <div className='col-md-12'><h5>For Purpose 2</h5></div>
          </div>
          <DataGrid
            rows={purposeItems}
            getRowId={(row) => row.id}
            columns={purpose_columns}
            pageSize={5}
            autoHeight 
            rowsPerPageOptions={[5]}
            //onCellEditStop={onCellEditStopFn}
          />
        </div>
        <div className='col-6'>
          <div className='row'>
          <div className='col-md-12'><h5>For New Control Department </h5></div>
          </div>
          <DataGrid
            rows={control_department_items}
            getRowId={(row) => row.id}
            columns={control_department_columns}
            pageSize={5}
            autoHeight 
            rowsPerPageOptions={[5]}
            //onCellEditStop={onCellEditStopFn}
          />
        </div>
      </div>
      
    </div>
  )
}

export default STFBulkFlagAsset
