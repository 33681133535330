import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import { getContinentList, getFilteredCountryList, getRegionInfo, saveRegions } from '../../services/user.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;

function AddRegion() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [countryId, setCountryId] = useState('');
    const [ErrCountryId, setErrCountryId] = useState(false);

    const [regionName, setRegionName] = useState('');
    const [ErrRegionName, setErrRegionName] = useState(false);

    const [regionCode, setRegionCode] = useState('');
    const [ErrRegionCode, setErrRegionCode] = useState(false);

    const [countries, setCountries] = useState([]);

    const [continentId, setContinentId] = useState('');
    const [ErrContinentId, setErrContinentId] = useState(false);

    const [continents, setContinents] = useState([]);

    const resetUserInfo = function () {
        setRegionName('');
        setContinentId('');
        setCountryId('');
        setRegionCode('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Region_ID = query.get('region_id');

    useEffect(() => {
        if (Region_ID !== '' && Region_ID != null) {
            const decryptedBytes = CryptoJS.AES.decrypt(Region_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const RegionID = decrypt_data.region_id;
            getRegionData(RegionID);
        }

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData = { 'token': EncryptData };

        getContinentList(oData).then(res => {
            if (res?.data?.data?.length) setContinents(res.data.data);
        });
    }, []);

    const getRegionData = function (RegionID) {
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'region_id': RegionID },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData1 = { 'token': EncryptData };

        getRegionInfo(oData1).then(function (res) {
            console.log(res?.data?.data);
            if (res?.data?.data?.length > 0) {
                const regInfo = res.data.data[0];
                setContinentId(regInfo?.continent_id || '');
                setRegionName(regInfo?.region_name || '');
                setRegionCode(regInfo?.region_code || '');

                // Fetch countries for the selected continent
                if (regInfo?.continent_id) {
                    fetchCountriesForContinent(regInfo.continent_id).then(() => {
                        setCountryId(regInfo?.country_id || ''); // Set country ID only after fetching countries
                    });
                }
            } else {
                setErrMsg("Failed to fetch region details.");
            }
        }).catch(() => {
            setErrMsg("Unable to fetch region details. Please try again.");
        });
    };

    const fetchCountriesForContinent = (continentId) => {
        if (!continentId) return Promise.resolve();

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'continent_id': continentId },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData = { 'token': EncryptData };

        return getFilteredCountryList(oData)
            .then(res => {
                if (res?.data?.data?.length > 0) {
                    setCountries(res.data.data);
                } else {
                    setCountries([]);
                }
            })
            .catch(() => {
                setCountries([]);
            });
    };

    const handleContinentId = (e) => {
        const selectedContinentId = e.target.value;
        setContinentId(selectedContinentId);

        if (!selectedContinentId) {
            setCountries([]);
            setCountryId('');
            return;
        }

        // Fetch the filtered country list
        fetchCountriesForContinent(selectedContinentId);
    };

    const handleCountryId = (e) => {
        setCountryId(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let valid_Name = true;

        if (!TEXT_REGEX.test(continentId)) {
            setErrContinentId('Continent name is required');
            valid_Name = false;
        } else {
            setErrContinentId('');
        }

        if (!TEXT_REGEX.test(countryId)) {
            setErrCountryId('Country name is required');
            valid_Name = false;
        } else {
            setErrCountryId('');
        }

        if (!TEXT_REGEX.test(regionName)) {
            setErrRegionName('Region name is required');
            valid_Name = false;
        } else {
            setErrRegionName('');
        }

        if (!TEXT_REGEX.test(regionCode)) {
            setErrRegionCode('Region code is required');
            valid_Name = false;
        } else {
            setErrRegionCode('');
        }

        if (valid_Name) {
            saveData();
        }
    };

    const saveData = function () {
        try {
            const obj = Region_ID
                ? {
                    'region_id': JSON.parse(CryptoJS.AES.decrypt(Region_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString(CryptoJS.enc.Utf8)).region_id,
                    'region_name': regionName,
                    'region_code': regionCode,
                    'continent_id': continentId,
                    'country_id': countryId,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
                : {
                    'region_name': regionName,
                    'region_code': regionCode,
                    'country_id': countryId,
                    'continent_id': continentId,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                };

            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { 'token': EncryptData };

            saveRegions(oData).then(function (res) {
                if (res?.data?.errors?.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });
        } catch {
            setErrMsg('Insert/Update Failed');
        }
    };

    return (
        <div className="container">

            <div className="row">
                <div className="col-6">
                    <h5>Add Country Region</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>

            </div>

            <form onSubmit={handleSubmit}>
                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Continent</label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select
                            className="form-select form-select-sm mb-3"
                            onChange={handleContinentId}
                            value={continentId} // Bind value to state
                        >
                            <option value="">Select Continent</option>
                            {continents.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.continent_name}
                                </option>
                            ))}
                        </select>
                        <span className="invalid-feedback"> {ErrContinentId} </span>
                    </div>
                </div>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Country</label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select
                            className="form-select form-select-sm mb-3"
                            onChange={handleCountryId}
                            value={countryId} // Bind value to state
                            disabled={!continentId} // Disable if no continent selected
                        >
                            <option value="">Select Country</option>
                            {countries.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.country_name}
                                </option>
                            ))}
                        </select>
                        <span className="invalid-feedback"> {ErrCountryId} </span>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Region name"
                            inputValue={regionName}
                            setInputValue={setRegionName}
                            setErrorMsg={ErrRegionName}
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Region code"
                            inputValue={regionCode}
                            setInputValue={setRegionCode}
                            setErrorMsg={ErrRegionCode}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <button className="btn btn-primary btn-sm" type="submit">Save</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AddRegion;
